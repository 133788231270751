<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/key_features.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-6 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                We believe in holistics<span class="text-light">
                  development of students.</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-4">
      <div class="container">
        <div class="row">
          <div class="col-md-10 text-center mx-auto">
            <h3 class="display-3">KEY FEATURES</h3>
            <p class="lead">
              Some of the key features that make Alpine stand out from other schools. We always believed in providing an environment to foster overall development of our students.
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-4 mr-auto text-left mt-4" v-animate-onscroll="'animated fadeInLeft'">
            <card class="card-blog bg-default" bodyClasses="p-0">
              <template slot="body">
                <img src="img/theme/img-1-1200x1000.jpg" class="card-img-top" />
                <blockquote class="card-blockquote">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="svg-bg"
                  >
                    <polygon
                      points="0,52 583,95 0,95"
                      class="fill-default"
                    ></polygon>
                    <polygon
                      points="0,42 583,95 683,0 0,95"
                      opacity=".2"
                      class="fill-default"
                    ></polygon>
                  </svg>
                  <h4 class="display-4 text-white">Catering to individual learning styles.</h4>
                  <p class="lead text-italic text-white">
                    That’s my skill. I’m not really specifically talented at
                    anything except for the ability to learn.
                  </p>
                </blockquote>
              </template>
            </card>
          </div>
          <div class="col-lg-7 p-sm-0">
            <div class="row">
              <div class="col-md-6" v-animate-onscroll="'animated zoomIn'">
                <div class="info info-hover-warning">
                  <div
                    class="icon icon-shape bg-warning shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-satisfied text-white"></i>
                  </div>
                  <h5 class="info-title">Enabling constructivist approach.</h5>
                  <p class="description opacity-8">
                    It becomes harder for us to give others a hand. We get our
                    heart broken by people we love.
                  </p>
                </div>
              </div>
              <div class="col-md-6" v-animate-onscroll="'animated zoomIn'">
                <div class="info info-hover-info">
                  <div
                    class="icon icon-shape bg-info shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-palette text-white"></i>
                  </div>
                  <h5 class="info-title">Learner & learning centric pedagogy.</h5>
                  <p class="description opacity-8">
                    As we live, our hearts turn colder. Cause pain is what we go
                    through as we become older.
                  </p>
                </div>
              </div>
            </div>
            <div class="row mt-lg-4">
              <div class="col-md-6" v-animate-onscroll="'animated zoomIn'">
                <div class="info info-hover-danger">
                  <div
                    class="icon icon-shape bg-danger shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-user-run text-white"></i>
                  </div>
                  <h5 class="info-title">Encouraging experiential learning.</h5>
                  <p class="description opacity-8">
                    We’re not always in the position that we want to be at. We’re
                    constantly growing.
                  </p>
                </div>
              </div>
              <div class="col-md-6" v-animate-onscroll="'animated zoomIn'">
                <div class="info info-hover-success">
                  <div
                    class="icon icon-shape bg-success shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-glasses-2 text-white"></i>
                  </div>
                  <h5 class="info-title">Super Fresh</h5>
                  <p class="description opacity-8">
                    When we lose family over time. What else could rust the heart
                    more over time? Blackgold.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-6 bg-secondary border-top border-bottom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8">
            <div class="info info-horizontal info-hover-danger ml-0">
              <div
                class="icon icon-shape icon-shape-danger rounded-circle text-white"
              >
                <i class="ni ni-html5 text-danger"></i>
              </div>
              <div class="description pl-4">
                <h5 class="title">Facilitating experimental analysis.</h5>
                <p class="mb-0">
                  The time is now for it to be okay to be great. People in this
                  world shun people for being great. For being a bright color. We’re constantly trying to express ourselves and actualize our dreams.
                </p>
              </div>
            </div>
            <div class="info info-horizontal info-hover-danger ml-0">
              <div
                class="icon icon-shape icon-shape-danger rounded-circle text-white"
              >
                <i class="ni ni-app text-danger"></i>
              </div>
              <div class="description pl-4">
                <h5 class="title">Focus on analysis & discovery approach.</h5>
                <p class="mb-0">
                  There’s nothing I really wanted to do in life that I wasn’t able
                  to get good at. That’s my skill. The time is now to be okay to be the greatest you. Would you believe in what you believe in, if you were the only one who believed it?
                </p>
              </div>
            </div>
            <div class="info info-horizontal info-hover-danger ml-0">
              <div
                class="icon icon-shape icon-shape-danger rounded-circle text-white"
              >
                <i class="ni ni-bell-55 text-danger"></i>
              </div>
              <div class="description pl-4">
                <h5 class="title">Empowering individuals with good citizenship values.</h5>
                <p class="mb-0">
                  That’s what I do. That’s what I’m here for. Don’t be afraid to
                  be wrong because you can’t learn anything from a compliment. 
                </p>
              </div>
            </div>
            <div class="info info-horizontal info-hover-danger ml-0">
              <div
                class="icon icon-shape icon-shape-danger rounded-circle text-white"
              >
                <i class="ni ni-bell-55 text-danger"></i>
              </div>
              <div class="description pl-4">
                <h5 class="title">Strengthening life skill education.</h5>
                <p class="mb-0">
                  That’s what I do. That’s what I’m here for. Don’t be afraid to
                  be wrong because you can’t learn anything from a compliment. 
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-10 mx-md-auto">
            <img class="ml-lg-5 rounded mb-3 shadow-sm" src="img/key_features_2.jpg" width="100%" />
            <img class="ml-lg-5 rounded mb-3 shadow-sm" src="img/key_features_3.jpg" width="100%" />
            <img class="ml-lg-5 rounded mb-3 shadow-sm" src="img/key_features_4.jpg" width="100%" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  bodyClass: "key_features",
  components: {
  }
};
</script>
<style>
.key_features  .info-horizontal {
  max-width: 100% !important;
}
</style>
