<template>
  <div class="wrapper">
    <div
      class="main-slides h-70vh"
      style="background: url(img/key_features.jpg); background-size: cover;"
    >
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div
              class="col-lg-6 col-12"
              v-animate-onscroll="{
                down: 'animated fadeOutDown',
                up: 'animated fadeInDown',
              }"
            >
              <h1 class="display-2 mt-8 text-light">
                Admission procedure<span class="text-light"> and forms.</span>
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- overview -->
    <div class="section features-4">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3 text-alpine">Overview</h3>
            <p class="lead font-weight-500 mt-1 text-dark">
              {{ admissions.overview_heading }}
            </p>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-4 mr-auto text-left mt-4"
            v-animate-onscroll="'animated fadeInLeft'"
          >
            <card class="card-blog bg-default" bodyClasses="p-0">
              <template slot="body">
                <img :src="admissions.overview_image" class="card-img-top" />
                <blockquote class="card-blockquote">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="svg-bg"
                  >
                    <polygon
                      points="0,52 583,95 0,95"
                      class="fill-default"
                    ></polygon>
                    <polygon
                      points="0,42 583,95 683,0 0,95"
                      opacity=".2"
                      class="fill-default"
                    ></polygon>
                  </svg>
                  <h4 class="display-4 text-white">
                    {{ admissions.overview_image_text }}
                  </h4>
                  <!-- <p class="lead text-italic text-white">
                    That’s my skill. I’m not really specifically talented at
                    anything except for the ability to learn.
                  </p> -->
                </blockquote>
              </template>
            </card>
          </div>
          <div class="col-lg-8">
            <div class="row">
              <div class="col-md-12" v-animate-onscroll="'animated zoomIn'">
                <p class="lead font-weight-700 mb-0">
                  Age Criteria:
                </p>
                <p class="mt-1">
                  {{ admissions.age_criteria }}
                </p>
                <p class="lead font-weight-700 mb-0">
                  Academic Criterion:
                </p>
                <p class="mt-1">
                  {{ admissions.academic_criteria }}
                </p>
                <p class="lead font-weight-700 mb-0">
                  Credential Required:
                </p>
                <p class="">
                  <span>Credential Required:</span>
                  <span
                    class="v-html"
                    v-html="admissions.credential_criteria"
                  ></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- prodecure -->
    <div class="section project-2 pt-6 border-top bg-secondary" id="procedure">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3 text-alpine">Procedure</h3>
            <!-- <p class="lead font-weight-500 mt-0 text-dark">
              Currently, the school has classes from Play Group, Pre Nursery,
              Nursery, and I to class XII.
            </p> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <p
              class="mt-1 v-html"
              v-html="admissions.procedure_description"
            ></p>
            <p class="lead font-weight-500 mb-2">
              Domestic Payments
            </p>
            <div class="card border-bottom border-danger border-bottom-lg">
              <div
                class="card-body v-html"
                v-html="admissions.domestic_payments"
              ></div>
            </div>
            <!-- <p class="lead font-weight-500 mb-2">
              Foreign Payments
            </p>
            <div class="card border-bottom border-danger border-bottom-lg">
              <div class="card-body v-html" v-html="admissions.foreign_payments">
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- forms -->
    <div class="section project-2 pt-6 border-top" id="forms">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3 text-alpine">Admission Form</h3>
            <p class="lead font-weight-500 mt-0 text-dark">
              {{ admissions.admission_heading }}
            </p>
          </div>
        </div>
        <div class="row pb-5">
          <div class="col-md-10 mx-auto ">
            <div class="card bg-secondary p-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        >Child's Name<span class="text-danger">*</span></label
                      >
                      <base-input
                        alternative
                        placeholder="Child Name..."
                        addonLeftIcon="ni ni-circle-08"
                        v-model="model.child_name"
                      ></base-input>
                      <div
                        v-if="$v.model.child_name.$error"
                        class="error small text-danger pt-1 pl-1 mt--3"
                      >
                        Field is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        >Admission to Class<span class="text-danger"
                          >*</span
                        ></label
                      >
                      <select
                        class="form-control form-control-alternative"
                        v-model="model.admission_to_class"
                      >
                        <option value="" selected>Select Class</option>
                        <option
                          v-for="classes in classesData"
                          :key="classes.class_id"
                          :value="classes.class_name"
                          >{{ classes.class_name }}</option
                        >
                      </select>
                      <div
                        v-if="$v.model.admission_to_class.$error"
                        class="error small text-danger pt-1 pl-1"
                      >
                        Field is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Parent's Name</label>
                      <base-input
                        alternative
                        placeholder="Parent Name..."
                        addonLeftIcon="ni ni-circle-08"
                        v-model="model.parent_name"
                      ></base-input>
                      <!-- <div
                        v-if="$v.model.parent_name.$error"
                        class="error small text-danger pt-1 pl-1 mt--3"
                         >
                        Field is required.
                      </div> -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Email<span class="text-danger">*</span></label>
                      <base-input
                        alternative
                        type="email"
                        placeholder="Email"
                        addonLeftIcon="ni ni-email-83"
                        v-model="model.email"
                        @keyup="email_validation()"
                      ></base-input>
                      <div
                        v-if="model.eruser_email"
                        class="error small text-danger pt-1 pl-1 mt--3"
                      >
                        Invalid email.
                      </div>
                      <div
                        v-if="$v.model.email.$error"
                        class="error small text-danger pt-1 pl-1 mt--3"
                      >
                        Field is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Phone No.<span class="text-danger">*</span></label>
                      <base-input
                        type="text"
                        oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                        alternative
                        placeholder="Phone No."
                        v-model="model.phone_no"
                        addonLeftIcon="ni ni-mobile-button"
                      ></base-input>
                      <div
                        v-if="
                          model.phone_no.length > 1 &&
                            model.phone_no.length != 10
                        "
                        class="small text-danger pt-1 pl-1 mt--3"
                      >
                        Invalid phone number.
                      </div>
                      <div
                        v-if="$v.model.phone_no.$error"
                        class="error small text-danger pt-1 pl-1 mt--3"
                      >
                        Field is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Telephone No.</label>
                      <base-input
                        type="text"
                        alternative
                        placeholder="Telephone No."
                        addonLeftIcon="ni ni-mobile-button"
                        v-model="model.telephone_no"
                      ></base-input>
                      <!-- <div
                        v-if="model.mobile.length>1 && model.mobile.length!=10"
                        class="small text-danger pt-1 pl-1 mt--3"
                         >
                          Invalid phone number.
                      </div> -->
                      <!-- <div
                        v-if="$v.model.mobile.$error"
                        class="error small text-danger pt-1 pl-1 mt--3"
                         >
                        Field is required.
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Address<span class="text-danger">*</span></label>
                      <textarea
                        name="message"
                        class="form-control form-control-alternative"
                        id="message"
                        rows="6"
                        v-model="model.address"
                        placeholder="Write your address"
                        style="resize: none;"
                      ></textarea>
                      <div
                        v-if="$v.model.address.$error"
                        class="error small text-danger pt-1 pl-1"
                      >
                        Field is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Enter Query</label>
                      <textarea
                        name="message"
                        class="form-control form-control-alternative"
                        id="message"
                        rows="6"
                        v-model="model.query"
                        placeholder="Write your queries"
                        style="resize: none;"
                      ></textarea>
                      <!-- <div
                        v-if="$v.model.query.$error"
                        class="error small text-danger pt-1 pl-1"
                         >
                        Field is required.
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <!-- <span class="small">Admission is open for all Nationalities. Singaporeans, however have to take approval from MOE.</span> -->
                    <span class="d-block small"
                      >For further details send a mail to
                      <a href="mailto:alpinekhurja@gmail.com"
                        >alpinekhurja@gmail.com</a
                      >.</span
                    >
                  </div>
                  <div class="col-md-6 ml-auto">
                    <base-button
                      nativeType="submit"
                      type="warning"
                      class="pull-right"
                      id="send_form_message"
                      @click="
                        $v.model.$touch();
                        submitadmission();
                      "
                      >Send Message</base-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- fee structure -->
    <div class="section project-2 pt-6 border-top" id="feestructure">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3 text-alpine">Fee Structure</h3>
            <p class="lead font-weight-500 mt-0 text-dark">
              Currently, the school has classes from Play Group, Pre Nursery,
              Nursery, and I to class XII.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <img
              :src="admissions.fee_structure.image"
              class="img-fluid my-3 shadow-sm"
            />
          </div>
          <div class="col-md-6 col-lg-6">
            <p class="lead font-weight-700 mb-0">
              {{ admissions.fee_structure.title }}
            </p>
            <p
              class="mt-1 v-html"
              v-html="admissions.fee_structure.description"
            ></p>
          </div>
        </div>
      </div>
    </div>
    <!-- results and TCs -->
    <div class="section project-2 pt-6 border-top bg-secondary" id="feepayment">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-md-6">
            <card class="card-blog card-background" data-animation="zooming">
              <div
                class="full-background"
                style="background-image: url('img/results_bg.jpg')"
              ></div>
              <template slot="body">
                <div class="content-center my-5">
                  <router-link
                    to="/"
                    class="h3 card-category text-white opacity-8"
                  >
                    Fee Payment
                  </router-link>
                </div>
              </template>
            </card>
          </div>
          <div class="col-md-6 col-lg-6">
            <card class="card-blog card-background" data-animation="zooming">
              <div
                class="full-background"
                style="background-image: url('img/tc_bg.jpg')"
              ></div>
              <template slot="body">
                <div class="content-center my-5">
                  <router-link
                    to="/tc"
                    class="h3 card-category text-white opacity-8"
                  >
                    Transfer Certificates
                  </router-link>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!-- FAQs -->
    <div class="cd-section border-top" id="faq">
      <!--     *********     ACCORDION 1      *********      -->
      <div class="container accordion-1">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h2 class="title mb-0 mt-5">Frequently asked question</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <collapse :multiple-active="false" class="mt-4">
              <collapse-item
                name="1"
                v-for="faq in admissions.faq"
                :key="faq._id"
              >
                <h5
                  slot="title"
                  class="mb-0 btn btn-link w-100 text-primary text-left"
                >
                  {{ faq.question }}
                  <i class="ni ni-bold-down float-right pt-1"></i>
                </h5>
                <div>
                  {{ faq.answer }}
                </div>
              </collapse-item>
              <!-- <collapse-item name="2">
                <h5
                  slot="title"
                  class="mb-0 btn btn-link w-100 text-primary text-left"
                >
                  How can I make the payment?
                  <i class="ni ni-bold-down float-right pt-1"></i>
                </h5>
                <div>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                  put a bird on it squid single-origin coffee nulla assumenda
                  shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                  wes anderson cred nesciunt sapiente ea proident. Ad vegan
                  excepteur butcher vice lomo. Leggings occaecat craft beer
                  farm-to-table, raw denim aesthetic synth nesciunt you probably
                  haven't heard of them accusamus labore sustainable VHS.
                </div>
              </collapse-item>
              <collapse-item name="3">
                <h5
                  slot="title"
                  class="mb-0 btn btn-link w-100 text-primary text-left"
                >
                  How much time does it take to receive the order?
                  <i class="ni ni-bold-down float-right pt-1"></i>
                </h5>
                <div>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                  put a bird on it squid single-origin coffee nulla assumenda
                  shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                  wes anderson cred nesciunt sapiente ea proident. Ad vegan
                  excepteur butcher vice lomo. Leggings occaecat craft beer
                  farm-to-table, raw denim aesthetic synth nesciunt you probably
                  haven't heard of them accusamus labore sustainable VHS.
                </div>
              </collapse-item>
              <collapse-item name="4">
                <h5
                  slot="title"
                  class="mb-0 btn btn-link w-100 text-primary text-left"
                >
                  Can I resell the products?
                  <i class="ni ni-bold-down float-right pt-1"></i>
                </h5>
                <div>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                  put a bird on it squid single-origin coffee nulla assumenda
                  shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                  wes anderson cred nesciunt sapiente ea proident. Ad vegan
                  excepteur butcher vice lomo. Leggings occaecat craft beer
                  farm-to-table, raw denim aesthetic synth nesciunt you probably
                  haven't heard of them accusamus labore sustainable VHS.
                </div>
              </collapse-item>
              <collapse-item name="5">
                <h5
                  slot="title"
                  class="mb-0 btn btn-link w-100 text-primary text-left"
                >
                  Where do I find the shipping details?
                  <i class="ni ni-bold-down float-right pt-1"></i>
                </h5>
                <div>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                  put a bird on it squid single-origin coffee nulla assumenda
                  shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                  wes anderson cred nesciunt sapiente ea proident. Ad vegan
                  excepteur butcher vice lomo. Leggings occaecat craft beer
                  farm-to-table, raw denim aesthetic synth nesciunt you probably
                  haven't heard of them accusamus labore sustainable VHS.
                </div>
              </collapse-item> -->
            </collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, sameAs } from "vuelidate/lib/validators";
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";
import axios from "axios";

export default {
  bodyClass: "academics",
  components: {
    Collapse,
    CollapseItem,
  },
  data() {
    return {
      metaData: [],
      classesData: [],
      admissions: [],
      events: [],
      traditional_touch: [],
      beyond_academic: [],
      model: {
        child_name: "",
        admission_to_class: "",
        parent_name: "",
        email: "",
        phone_no: "",
        telephone_no: "",
        address: "",
        query: "",
        status: "approved",
        eruser_email: false,
      },
      items: [
        "img/results_bg.jpg",
        "img/physics_lab.jpg",
        "img/sports4.jpg",
        "img/chem_lab.jpg",
        "img/council.jpg",
        "img/results_bg.jpg",
        "img/physics_lab.jpg",
        "img/sports4.jpg",
        "img/chem_lab.jpg",
        "img/council.jpg",
      ],
      eventitems: [
        "img/results_bg.jpg",
        "img/physics_lab.jpg",
        "img/sports4.jpg",
        "img/chem_lab.jpg",
        "img/council.jpg",
      ],
      index: null,
      auth: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
      authjson: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
    };
  },
  validations: {
    model: {
      child_name: {
        required,
      },
      admission_to_class: {
        required,
      },
      email: {
        required,
      },
      phone_no: {
        required,
      },
      address: {
        required,
      },
    },
  },
  mounted() {
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_admission",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.admissions = response.data.details[0];
        } else {
          this.admissions = [];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_class",
        { details: {} },
        this.auth1
      )
      .then((tc) => {
        console.log(tc);
        if (tc.data.details) {
          this.classesData = tc.data.details;
        }
      });
  },
  methods: {
    submitadmission() {
      console.log(this.$v.model.$error);
      if (
        this.$v.model.$error === false &&
        this.model.phone_no.length === 10 &&
        !this.model.eruser_email
      ) {
        document.getElementById("send_form_message").innerHTML =
          '<i class="fa fa-circle-o-notch fa-spin mr-2"></i>Sending';

        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "create_admission_form",
            this.model,
            this.authjson
          )
          .then((response) => {
            console.log(response);
            let _this = this;
            _this.message = response.data.message;
            document
              .getElementById("send_form_message")
              .classList.remove("btn-warning");
            document
              .getElementById("send_form_message")
              .classList.add("btn-success");
            document.getElementById("send_form_message").innerHTML =
              "Message Sent";
            setTimeout(function() {
              document
                .getElementById("send_form_message")
                .classList.remove("btn-success");
              document
                .getElementById("send_form_message")
                .classList.add("btn-warning");
              document.getElementById("send_form_message").innerHTML = "Saved";
              window.location.reload();
              // _this.model.child_name='';
              // _this.model.admission_to_class='';
              // _this.model.parent_name='';
              // _this.model.email='';
              // _this.model.phone_no='';
              // _this.model.telephone_no='';
              // _this.model.address='';
              // _this.model.query='';
              // _this.model.query='';
            }, 2000);
          });
      }
    },
    email_validation() {
      if (this.model.email.length > 0) {
        var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        if (regexEmail.test(this.model.email)) {
          this.model.eruser_email = false;
        } else {
          this.model.eruser_email = true;
        }
      } else {
        this.model.eruser_email = false;
      }
    },

    // dateformat(val) {
    //   return moment.utc(new Date(val)).format("MMM Do YY");
    //   //console.log(this.blogData.date);
    // }
  },
};
</script>
<style>
.academics .card-background .card-body {
  padding-top: 20px;
  padding-bottom: 10px;
}
.academics .projects-3 .card-background {
  padding: 30px 25px;
}
.blur {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.academics .card-background .card-body {
  min-height: 130px;
}
.academics .card-background.card-blog {
  overflow: hidden;
  height: 180px;
}
.academics .bg-image {
  background-image: url(/img/contact_bg.jpeg);
}
</style>
