<template>
  <div class="wrapper">
    <div class="main-slides h-50vh" style="background: url(img/key_features.jpg); background-size: cover;">
      <div class="page-header h-50vh">
        <div class="container">
          <div class="row align-items-center text-center justify-content-center text-light">
            <div class="col-lg-9 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-6 text-light">
                Download Question Paper<span class="text-light"></span>
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-4 pt-4">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <!-- <h3 class="display-3 text-alpine"></h3> -->
          </div>
        </div>
        <div class="mt-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-9 col-md-10 mx-auto">
                <div class="row mb-4">
                  <div class="col-lg-6">
                    <p class="mb-0 font-weight-400">Download question paper</p>
                  </div>
                  <div class="col-lg-6 text-right">
                    <a href="/submit-exam" class="btn btn-outline-danger">Submit Exam</a>
                  </div>
                </div>
                <div class="card card-raised card-form-horizontal">
                  <div class="card-body">
                    <!-- <form method="" action=""> -->
                      <div class="row">
                        <div class="col-sm-4">
                          <base-input
                            icon="ni ni-badge"
                            placeholder="Admission number"
                            v-model="userdetails.admission_no"
                          ></base-input>
                          <div
                            v-if="$v.userdetails.admission_no.$error"
                            class="error small text-danger pt-1 pl-1 mt--3"
                             >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <base-input
                            icon="ni ni-email-83"
                            placeholder="Mobile number"
                            v-model="userdetails.user_phone"
                          ></base-input>
                          <div
                            v-if="$v.userdetails.user_phone.$error"
                            class="error small text-danger pt-1 pl-1 mt--3"
                             >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <base-button type="alpine" id="viewtcbtn" @click="$v.userdetails.$touch();search_exam()" block>Download Paper</base-button>
                        </div>
                      </div>
                    <!-- </form> -->
                    <div class="table-responsive">
                      <el-table
                        class="table table-responsive table-flush align-items-center"
                        header-row-class-name="thead-light"
                        :data="questionspapper"
                        >
                        <el-table-column
                          label="Class"
                          prop="name"
                          sortable
                          scope="row"
                        >
                          <template v-slot="{ row }">
                            <div class="align-items-center">
                              <div class="media-body">
                                <!-- <img alt="Image placeholder" class="avatar-sm avatar mr-3" src="img/faces/team-1.jpg" /> -->
                                <span class="name mb-0 text-sm">{{ row.class_oid.class_name }}</span>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column label="Subject" prop="subject_oid.subject_name" sortable>
                        </el-table-column>
                        <el-table-column
                          label="Download Question Paper"
                          prop="percentage"
                          sortable
                          header-align="right"
                          headerClasses="text-right"
                          >
                          <template v-slot="{ row }">
                            <div class="align-items-center justify-content-end">
                              <a :href="row.question_paper_link" type="success" download><i class="fa fa-download mr-3"></i>Download Question Paper</a>
                              <span class="description d-block mt-3" style="white-space: normal;">Please clear your dues to download your transfer certificate.</span>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  // DropdownMenu,
} from "element-ui";
import axios from "axios";
import { required,sameAs } from 'vuelidate/lib/validators';


export default {
  bodyClass: "academics",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      pricing5Tab: "10th",
      questionspapper:[],
      projects: [
        {
          title: "10th",
          budget: "Sec A",
          admission_no: "Physics",
          statusType: "warning",
          percentage: 60
        },
      ],
      currentPage: 1,
      userdetails:{
        admission_no:'',
        user_phone:'',
      },
      auth : {
        headers: { 'Content-Type':'application/x-www-form-urlencoded',
        'Authorization':'alpine#' + localStorage.getItem('accessToken'),
        '_id':localStorage.getItem('_id')}
      },
      auth1 : { headers: { 'Content-Type':'application/json',
        'Authorization':'alpine#' + localStorage.getItem('accessToken'),
        '_id':localStorage.getItem('_id')}
      },
    };
  },
  validations: {
      userdetails: {
        admission_no: {
          required
        },
        user_phone: {
          required
        },
      },
  },
  methods: {
      search_exam(){
         if(this.$v.userdetails.$error===false){
        const querystring = require('querystring');
        axios.post(process.env.VUE_APP_API_ENDPOINT+'read_student',{"details":{"admission_no":this.admission_no,"user_phone":this.user_phone}},this.auth1)
          .then(response => {
            if(response.data.details){
              let date=new Date().toISOString();
           axios.post(process.env.VUE_APP_API_ENDPOINT+'read_question_paper',{"details":{"class_oid":response.data.details[0].user_class._id,"download_to":{$gte:date}}},this.auth1)
          .then(response1 => {
            console.log(response1); 
            if(response1.data.details){
              this.questionspapper=response1.data.details;
            }else{
              this.questionspapper=[];
            }
            this.message=response.data.message;
            document.getElementById("viewtcbtn").classList.remove('btn-primary');
            document.getElementById("viewtcbtn").classList.add('btn-success');
            document.getElementById("viewtcbtn").innerHTML='Searched';
            setTimeout(function(){
            document.getElementById("viewtcbtn").classList.remove('btn-success');
            document.getElementById("viewtcbtn").classList.add('btn-primary');
            document.getElementById("viewtcbtn").innerHTML="Search";
           }, 2000);

           })
          }
         }) 
        }
      },
    },
};
</script>
<style>
.academics .card-background .card-body {
    padding-top: 20px;
    padding-bottom: 10px;
}
.academics .projects-3 .card-background {
    padding: 30px 25px;
}
.blur {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.academics .card-background .card-body {
  min-height: 130px;
}
.academics .card-background.card-blog {
    overflow: hidden;
    height: 180px;
}
.academics .bg-image {
  background-image: url(/img/contact_bg.jpeg);
}
</style>
