<template>
  <div class="wrapper">
    <div class="main-slides h-50vh" style="background: url(img/key_features.jpg); background-size: cover;">
      <div class="page-header h-50vh">
        <div class="container">
          <div class="row align-items-center text-center justify-content-center text-light">
            <div class="col-lg-9 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-6 text-light">
                Upload Answer Sheet<span class="text-light"></span>
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-4 pt-4">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <!-- <h3 class="display-3 text-alpine"></h3> -->
          </div>
        </div>
        <div class="mt-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-9 col-md-10 mx-auto">
                <!-- <div class="row mb-4">
                  <div class="col-lg-6">
                    <p class="mb-0 font-weight-400">Download question paper</p>
                  </div>
                  <div class="col-lg-6 text-right">
                    <a href="/submit-exam" class="btn btn-outline-danger">Submit Exam</a>
                  </div>
                </div> -->
                <div class="card shadow-none card-form-horizontal">
                  <div class="card-body">
                    <form method="" action="">
                      <div class="row justify-content-center">
                        <div class="col-sm-6">
                          <label class="form-control-label">Admission number<span class="text-danger">*</span></label>
                          <base-input
                            icon="ni ni-badge"
                            placeholder="Admission number"
                            v-model="modeldetails.admission_no"
                          ></base-input>
                          <div
                            v-if="$v.modeldetails.admission_no.$error"
                            class="error small text-danger pt-1 pl-1 mt--3"
                             >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <label class="form-control-label">Mobile number<span class="text-danger">*</span></label>
                          <base-input
                            icon="ni ni-email-83"
                            placeholder="Mobile number"
                            v-model="modeldetails.user_phone"
                          ></base-input>
                          <div
                            v-if="$v.modeldetails.user_phone.$error"
                            class="error small text-danger pt-1 pl-1 mt--3"
                             >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <label class="form-control-label">DOB<span class="text-danger">*</span></label>
                          <input type="date" v-model="modeldetails.user_dob" name="dob" class="form-control">
                          <div
                            v-if="$v.modeldetails.user_dob.$error"
                            class="error small text-danger pt-1 pl-1"
                             >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <label class="form-control-label">Class<span class="text-danger">*</span></label>
                          <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="modeldetails.class_oid" @change="getsubjectbyclass()">
                            <option value="">Select class</option>
                            <option v-for="classes in classData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
                          </select>
                          <div
                            v-if="$v.modeldetails.class_oid.$error"
                            class="error small text-danger pt-1 pl-1"
                             >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <label class="form-control-label">Subject<span class="text-danger">*</span></label>
                          <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="modeldetails.subject_oid" @change="getquestionpaper()">
                            <option value="">Select subject</option>
                            <option v-for="subject in subjectData" :key="subject.subject_id" :value="subject._id">{{subject.subject_name}}</option>
                          </select>
                          <div
                            v-if="$v.modeldetails.subject_oid.$error"
                            class="error small text-danger pt-1 pl-1"
                             >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <label class="form-control-label">Exam<span class="text-danger">*</span></label>
                          <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="modeldetails.exam_oid" @change="getquestionpaper()">
                            <option value="">Select exam</option>
                            <option v-for="exam in examData" :key="exam.exam_id" :value="exam._id">{{exam.exam_name}}</option>
                          </select>
                          <div
                            v-if="$v.modeldetails.exam_oid.$error"
                            class="error small text-danger pt-1 pl-1"
                             >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <label class="form-control-label">Question paper<span class="text-danger">*</span></label>
                          <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="modeldetails.questionpaper_oid">
                            <option value="">Select question paper</option>
                            <option v-for="questionpaper in questionpaperData" :key="questionpaper.questionpaper_id" :value="questionpaper._id">{{questionpaper.question_paper_title}}</option>
                          </select>
                          <div
                            v-if="$v.modeldetails.questionpaper_oid.$error"
                            class="error small text-danger pt-1 pl-1"
                             >
                            Field is required.
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <label class="form-control-label">Upload answer sheet<span class="text-danger">*</span></label>

                            <input
                              type="file"
                              ref="file"
                              accept="image/*" 
                              placeholder="image"
                              class="form-control form-control-alternative"
                              multiple="multiple"
                              @change="handlemultipleimage($event,'add','file')"
                            />
                            <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
                            <div class="row">
                              <div class="col-6 position-relative mt-3" v-for="(image,index) in model.response_body" :key="image">
                                <base-button type="danger" size="sm" class="position-absolute right-0 mr-1 mt--2 btn-round" @click="deletefile(index,'add')"><i class="fa fa-trash"></i></base-button>
                                <img :src="image" class="img-fluid rounded shadow p-1 border">
                              </div>
                            </div>
                        </div>
                        <div class="col-sm-6 mt-3">
                          <base-button type="alpine"  @click="$v.modeldetails.$touch();submitresponse()" block>Upload Answer Sheets</base-button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  // DropdownMenu,
} from "element-ui";
import axios from "axios";
import { required,sameAs } from 'vuelidate/lib/validators';


export default {
  bodyClass: "academics",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      pricing5Tab: "10th",
      admission_no:'',
      user_phone:'',
      user_dob :'',
      class_oid:'',
      subject_oid:'',
      exam_oid:'',
      questionpaper_oid:'',
      classData:[],
      subjectData:[],
      examData:[],
      questionpaperData:[],
      multipleuploadPercentage:0,
      model:{
        response_body:[],
        student_oid:'',
        class_oid:'',
      },
      modeldetails:{
        admission_no:'',
        user_phone:'',
        user_dob :'',
        class_oid:'',
        subject_oid:'',
        exam_oid:'',
        questionpaper_oid:'',
      },
      projects: [
        {
          title: "10th",
          budget: "Sec A",
          admission_no: "Physics",
          statusType: "warning",
          percentage: 60
        },
      ],
      auth : {
        headers: { 'Content-Type':'application/x-www-form-urlencoded',
        'Authorization':'alpine#' + localStorage.getItem('accessToken'),
        '_id':localStorage.getItem('_id')}
      },
      auth1 : { headers: { 'Content-Type':'application/json',
        'Authorization':'alpine#' + localStorage.getItem('accessToken'),
        '_id':localStorage.getItem('_id')}
      },
      currentPage: 1,
    };
  },
  validations: {
      modeldetails: {
        admission_no: {
          required
        },
        user_phone: {
          required
        },
        user_dob:{
          required
        },
        class_oid:{
          required
        },
        subject_oid:{
          required
        },
        exam_oid:{
          required
        },
        questionpaper_oid:{
          required
        },
      },
  },
  mounted() {
    axios
    .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{}},this.auth1)
    .then(response => {
      console.log(response);
      if(response.data.details){
      this.classData=response.data.details;
      }
          
    })
    axios
    .post(process.env.VUE_APP_API_ENDPOINT+'read_exam',{ "details" :{exam_status:'approved'}},this.auth1)
    .then(response => {
      console.log(response);
      if(response.data.details){
        this.examData=response.data.details;
      }
          
    })
    
  },
  methods: {
      getsubjectbyclass(){

        let details={ "details" :{}};
        details.details.class_oid=this.modeldetails.class_oid
        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_subject',details,this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.subjectData=response.data.details;
            }
                
          })

      },
      getquestionpaper(){

        let details={ "details" :{}};

        if(this.modeldetails.class_oid){
          details.details.class_oid=this.modeldetails.class_oid;
        }
        if(this.modeldetails.subject_oid){
          details.details.subject_oid=this.modeldetails.subject_oid;
        }
        if(this.modeldetails.exam_oid){
          details.details.exam_oid=this.modeldetails.exam_oid;
        }
        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_question_paper',details,this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.questionpaperData=response.data.details;
            }
                
          })

      },

      

      async handlemultipleimage(event,type,uploatype) {
            // Reference to the DOM input element

              // document.getElementById("assignmentbtn").classList.add('d-none');
              // document.getElementById("addassignmentinvalid").classList.remove('d-none');
              // console.log(event);
              this.multipleuploadPercentage=0;
              if(uploatype=='file'){
                for( var i = 0; i < this.$refs.file.files.length; i++ ){
                    let file = this.$refs.file.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }
              }

      },
      upload(file,type){
        // console.log(file);
        let fd = new FormData();
        fd.append('image',file,file.name);
        axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.multipleuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.multipleuploadPercentage)}.bind(this)}).then(response => {
          // console.log(response);
          if(type=='add'){
            this.model.response_body.push(response.data.imageUrl);
          }
          // console.log(this.imagearr);
          
       })

      },
      deletefile(index,type){
          if(type=='add'){
            this.model.response_body.splice(index,1);
          }
      },
      submitresponse(){
         if(this.$v.modeldetails.$error===false){
        const querystring = require('querystring');
        axios.post(process.env.VUE_APP_API_ENDPOINT+'read_student',{"details":{"admission_no":this.modeldetails.admission_no,"user_phone":this.modeldetails.user_phone,"user_dob":this.modeldetails.user_dob}},this.auth1)
          .then(response => {
            if(response.data.details){
              let userdetails=response.data.details[0];
              this.model.student_oid=userdetails._id;
              if(this.modeldetails.class_oid){
                this.model.class_oid=this.modeldetails.class_oid;
              }
              if(this.modeldetails.subject_oid){
                this.model.subject_oid=this.modeldetails.subject_oid;
              }
              if(this.modeldetails.exam_oid){
                this.model.exam_oid=this.modeldetails.exam_oid;
              }
              if(this.modeldetails.questionpaper_oid){
                this.model.question_paper_oid=this.modeldetails.questionpaper_oid;
              }
              
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_response',this.model,this.auth1)
          .then(response1 => {
            console.log(response1); 
            if(response1.data.details){
              console.log(response1.data.details);
            }
            this.message=response.data.message;
            document.getElementById("viewtcbtn").classList.remove('btn-primary');
            document.getElementById("viewtcbtn").classList.add('btn-success');
            document.getElementById("viewtcbtn").innerHTML='Answer Sheets Uploaded successfully';
            setTimeout(function(){
            document.getElementById("viewtcbtn").classList.remove('btn-success');
            document.getElementById("viewtcbtn").classList.add('btn-primary');
            document.getElementById("viewtcbtn").innerHTML="Search";
           }, 2000);

           })
          }
        }) 
       }
      },
    },
};
</script>
<style>
.academics .card-background .card-body {
    padding-top: 20px;
    padding-bottom: 10px;
}
.academics .projects-3 .card-background {
    padding: 30px 25px;
}
.blur {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.academics .card-background .card-body {
  min-height: 130px;
}
.academics .card-background.card-blog {
    overflow: hidden;
    height: 180px;
}
.academics .bg-image {
  background-image: url(/img/contact_bg.jpeg);
}
</style>
