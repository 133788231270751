import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import VAnimateCss from 'v-animate-css';
import VueAnimateOnScroll from 'vue-animate-onscroll'
import Vuelidate from 'vuelidate';


Vue.use(VueAnimateOnScroll)
Vue.use(VAnimateCss);
Vue.config.productionTip = false;
Vue.use(Argon);
Vue.use(Vuelidate);


new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

var filter = function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);
// example: {{data.content | truncate(300, '...')}}