<template>
  <div class="wrapper">
    <div
      class="main-slides h-100vh"
      style="background: url(img/about_bg.jpg); background-size: cover;"
    >
      <div class="page-header">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div
              class="col-lg-6 col-12"
              v-animate-onscroll="{
                down: 'animated fadeOutDown',
                up: 'animated fadeInDown',
              }"
            >
              <h1 class="display-2 text-light">
                Alpine Public School,<span class="text-light">
                  Eliminating Limits.</span
                >
              </h1>
              <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims
                towards the all-round development of every child. We are aware
                of the many challenges children face today and therefore we try
                to impart a 'value based' education that will help our children
                to face life with confidence.
              </p>
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- about us content -->
    <div class="section features-1" id="about">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <span class="badge badge-alpine badge-pill mb-3">About Us</span>
            <h3 class="display-3 text-alpine">
              {{ aboutdata.about_title }}
            </h3>
            <p class="lead font-weight-500 text-dark">
              {{ aboutdata.about_short_description }}
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-5">
        <div class="row align-items-center text-left justify-content-center">
          <div class="col-lg-10 col-12">
            <img
              :src="aboutdata.about_image"
              class="img-fluid float-left p-1 mb-2 mr-4 shadow rounded"
            />
            <p class="pb-2 mt-0">
              {{ aboutdata.about_description }}
            </p>
            <!-- <p class="pb-2 mt-0">
              The school shall endeavor to maximize the potential of every child
              through an active partnership between children, teachers, parents
              and management. The school aims to achieve this by providing a
              positive, secure and caring environment. The powers of thought,
              feelings and action in the child, when this integration of head,
              heart and hand has been brought about, the basis has also been
              laid for mental adaptability, initiative and moral strength in
              adult’s life.
            </p>
            <p class="pb-2 mt-0">
              We are a school with a vision, aimed at creating citizens of a
              global civilization. The emphasis is on a stimulating, happy
              learning environment where a child’s talents can be nurtured to
              fulfillment. Child-Parent-Teacher interaction is an important part
              of the School.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <!-- why us -->
    <div
      class="section bg-alpine-dark"
      id="whyus"
      style="background-image: url('img/ill/p31.svg')"
    >
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-lg-10">
            <h3 class="display-3 text-white">
              <span class="d-inline">WHY</span> ALPINE?
            </h3>
            <p class="lead text-white font-weight-500">
              {{ aboutdata.why_description }}
              <!-- The school believes in toughening the students for ravages of life
              and helping them to upheavals and challenges rather than
              comforting and cushioning them during the school hours The
              activities are designed and monitored so that the students have to
              necessarily strive, struggle and sweat at each stage to be able to
              achieve and enjoy success. -->
            </p>
          </div>
        </div>
        <div class="row row-grid mt-5">
          <div
            class="col-lg-6 col-12 col-md-6"
            v-animate-onscroll="'animated fadeInLeft'"
          >
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-info rounded-circle text-white"
              >
                <i class="ni ni-hat-3 text-info"></i>
              </div>
              <div class="description pl-4" v-if="aboutdata.whyUs_box">
                <h5 class="title text-info">
                  {{ aboutdata.whyUs_box[0].title }}
                  <!-- Enabling constructivist approach -->
                </h5>
                <p>
                  {{ aboutdata.whyUs_box[0].description }}
                  <!-- We strive to – Provide high quality teaching facilities so as
                  to “Create human beings who are able to develop in themselves
                  purpose and direction to their lives.” -->
                </p>
                <!-- <a href="javascript:void(0)" class="text-info">Learn more</a> -->
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 col-12 col-md-6"
            v-animate-onscroll="'animated fadeInRight'"
          >
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-warning rounded-circle text-white"
              >
                <i class="ni ni-istanbul text-warning"></i>
              </div>
              <div class="description pl-4" v-if="aboutdata.whyUs_box">
                <h5 class="title text-warning">
                  {{ aboutdata.whyUs_box[1].title }}
                  <!-- Learner & learning centric pedagogy -->
                </h5>
                <p>
                  {{ aboutdata.whyUs_box[1].description }}
                  <!-- We develop your child with moral values and spirit of
                  responsibility. We help children build a good vision. We
                  educate beyond the books. -->
                </p>
                <!-- <a href="javascript:void(0)" class="text-warning">Learn more</a> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row row-grid">
          <div
            class="col-lg-6 col-12 col-md-6"
            v-animate-onscroll="'animated fadeInLeft'"
          >
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-danger rounded-circle text-white"
              >
                <i class="ni ni-trophy text-danger"></i>
              </div>
              <div class="description pl-4" v-if="aboutdata.whyUs_box">
                <h5 class="title text-danger">
                  {{ aboutdata.whyUs_box[2].title }}
                  <!-- Encouraging experiential learning -->
                </h5>
                <p>
                  {{ aboutdata.whyUs_box[2].description }}
                  <!-- We guide and inspire your child to utmost. Your child can
                  explore and find his/her purpose on their own. -->
                </p>
                <!-- <a href="javascript:void(0)" class="text-danger">Learn more</a> -->
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 col-12 col-md-6"
            v-animate-onscroll="'animated fadeInRight'"
          >
            <div class="info info-horizontal bg-white">
              <div
                class="icon icon-shape icon-shape-success rounded-circle text-white"
              >
                <i class="ni ni-circle-08 text-success"></i>
              </div>
              <div class="description pl-4" v-if="aboutdata.whyUs_box">
                <h5 class="title text-success">
                  {{ aboutdata.whyUs_box[3].title }}
                  <!-- Providing individual attention -->
                </h5>
                <p>
                  {{ aboutdata.whyUs_box[3].description }}
                  <!-- The school shall endeavor to maximize the potential of every
                  child through an active partnership all stakeholders -->
                </p>
                <!-- <a href="javascript:void(0)" class="text-success">Learn more</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- history timeline -->
    <div class="section" id="history">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mb-5 mx-auto text-center">
            <span class="badge badge-alpine badge-pill mb-1">Timeline</span>
            <h3 class="display-3 text-alpine">History of Alpine</h3>
          </div>
          <div class="col-lg-12">
            <div class="timeline timeline-two-side">
              <div class="timeline-block" v-if="aboutdata.history">
                <span class="timeline-step badge-success"
                  ><i class="ni ni-bell-55"></i
                ></span>
                <div
                  class="timeline-content text-left text-lg-right"
                  v-animate-onscroll="'animated fadeInLeft'"
                >
                  <small class="text-muted font-weight-bold">{{
                    aboutdata.history[0].year
                  }}</small>
                  <h5 class="mt-3 mb-0">{{ aboutdata.history[0].title }}</h5>
                  <p class="text-sm mt-1 mb-0">
                    {{ aboutdata.history[0].description }}
                    <!-- Nullam id dolor id nibh ultricies vehicula ut id elit. Cum
                    sociis natoque penatibus et magnis dis parturient montes,
                    nascetur ridiculus mus. -->
                  </p>
                  <div class="mt-3">
                    <span class="badge badge-success badge-pill">design</span
                    >&nbsp;
                    <span class="badge badge-success badge-pill">system</span
                    >&nbsp;
                    <span class="badge badge-success badge-pill">creative</span>
                  </div>
                </div>
              </div>
              <div class="timeline-block" v-if="aboutdata.history">
                <span class="timeline-step badge-danger"
                  ><i class="ni ni-html5"></i
                ></span>
                <div
                  class="timeline-content"
                  v-animate-onscroll="'animated fadeInRight'"
                >
                  <small class="text-muted font-weight-bold">{{
                    aboutdata.history[1].year
                  }}</small>
                  <h5 class="mt-3 mb-0">{{ aboutdata.history[1].title }}</h5>
                  <p class="text-sm mt-1 mb-0">
                    {{ aboutdata.history[1].description }}
                  </p>
                  <div class="mt-3">
                    <span class="badge badge-danger badge-pill">design</span
                    >&nbsp;
                    <span class="badge badge-danger badge-pill">system</span
                    >&nbsp;
                    <span class="badge badge-danger badge-pill">creative</span>
                  </div>
                </div>
              </div>
              <div class="timeline-block" v-if="aboutdata.history">
                <span class="timeline-step badge-info"
                  ><i class="ni ni-like-2"></i
                ></span>
                <div
                  class="timeline-content text-left text-lg-right"
                  v-animate-onscroll="'animated fadeInLeft'"
                >
                  <small class="text-muted font-weight-bold">{{
                    aboutdata.history[2].year
                  }}</small>
                  <h5 class="mt-3 mb-0">{{ aboutdata.history[2].title }}</h5>
                  <p class="text-sm mt-1 mb-0">
                    {{ aboutdata.history[2].description }}
                  </p>
                  <div class="mt-3">
                    <span class="badge badge-info badge-pill">design</span
                    >&nbsp;
                    <span class="badge badge-info badge-pill">system</span
                    >&nbsp;
                    <span class="badge badge-info badge-pill">creative</span>
                  </div>
                </div>
              </div>
              <div class="timeline-block" v-if="aboutdata.history">
                <span class="timeline-step badge-success"
                  ><i class="ni ni-bell-55"></i
                ></span>
                <div
                  class="timeline-content"
                  v-animate-onscroll="'animated fadeInRight'"
                >
                  <small class="text-muted font-weight-bold">{{
                    aboutdata.history[3].year
                  }}</small>
                  <h5 class="mt-3 mb-0">{{ aboutdata.history[3].title }}</h5>
                  <p class="text-sm mt-1 mb-0">
                    {{ aboutdata.history[3].description }}
                  </p>
                  <div class="mt-3">
                    <span class="badge badge-success badge-pill">design</span
                    >&nbsp;
                    <span class="badge badge-success badge-pill">system</span
                    >&nbsp;
                    <span class="badge badge-success badge-pill">creative</span>
                  </div>
                </div>
              </div>
              <div class="timeline-block" v-if="aboutdata.history">
                <span class="timeline-step badge-danger"
                  ><i class="ni ni-html5"></i
                ></span>
                <div
                  class="timeline-content text-left text-lg-right"
                  v-animate-onscroll="'animated fadeInLeft'"
                >
                  <small class="text-muted font-weight-bold">{{
                    aboutdata.history[4].year
                  }}</small>
                  <h5 class="mt-3 mb-0">{{ aboutdata.history[4].title }}</h5>
                  <p class="text-sm mt-1 mb-0">
                    {{ aboutdata.history[4].description }}
                  </p>
                  <div class="mt-3">
                    <span class="badge badge-danger badge-pill">design</span
                    >&nbsp;
                    <span class="badge badge-danger badge-pill">system</span
                    >&nbsp;
                    <span class="badge badge-danger badge-pill">creative</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mission vision -->
    <div
      class="section bg-image text-white features-1 mission_vision pt-5 pt-md-6"
      id="missionvision"
    >
      <div class="container mt-1">
        <div class="row text-left justify-content-center">
          <div class="col-lg-5 col-12 col-md-6 text-right pr-md-4">
            <img src="img/vision.png" class="img-fluid mb-4" />
            <h3 class="display-3 pb-2 text-white">Vision</h3>
            <p class="lead">
              {{ aboutdata.vision.title }}
            </p>
            <p class="pb-2 mt-0">
              {{ aboutdata.vision.description }}
            </p>
            <!-- <p class="pb-2 mt-0">
              Much has changed in past few years as we have progressed and
              bounds. Our Students are making a mark in all fields. Still great
              accolades are to be won in the years to come, not only in
              academics but in all fields. We are emphatic that to attain this
              we have to maintain very high standard of ethical, moral and
              spiritual values for ‘expedients are for the hour, best principles
              are for the ages’.
            </p> -->
          </div>
          <div class="col-lg-5 col-12 col-md-6 pl-md-4 pt-3 pt-md-0">
            <img src="img/mission.png" class="img-fluid mb-4" />
            <h3 class="display-3 pb-2 text-white">Mission</h3>
            <p class="lead">
              {{ aboutdata.mission.title }}
            </p>
            <p class="pb-2 mt-0">
              {{ aboutdata.mission.description }}
            </p>
            <!-- <p class="pb-2 mt-0">
              Our mission is “To attract and develop exceptional students from
              all across globe and to offer them quality education and prepare
              children to face all future challenges with dignity and honour to
              serve the society in a selfless manner and in turn help enable
              them in becoming a global and ethical citizen.” We further promise
              to keep our Motto intact which entails “learning to live together
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <!-- director and principal's message -->
    <!-- results and TCs -->
    <div class="section project-2 pt-3 pt-md-6" id="message">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-6 pr-3 pr-lg-5">
            <card class="card-blog card-background" data-animation="zooming">
              <div
                class="full-background"
                style="background-image: url('img/results_bg.jpg')"
              ></div>
              <template slot="body">
                <div class="content-center my-5">
                  <router-link
                    to="/directormessage"
                    class="h3 card-category text-white opacity-8"
                  >
                    Director's Message
                  </router-link>
                </div>
              </template>
            </card>
          </div>
          <div class="col-md-6 col-lg-6 pl-3 pl-lg-5">
            <card class="card-blog card-background" data-animation="zooming">
              <div
                class="full-background"
                style="background-image: url('img/tc_bg.jpg')"
              ></div>
              <template slot="body">
                <div class="content-center my-5">
                  <router-link
                    to="/principalmessage"
                    class="h3 card-category text-white opacity-8"
                  >
                    Principal's Message
                  </router-link>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section bg-secondary border-bottom" id="director">
      <div class="container">
        <div class="card card-profile shadow">
          <div class="px-4">
            <div class="row mt-3">
              <div class="col-md-12">
                <h4 class="border-bottom text-center pb-3 pt-1"><i class="fa fa-pencil mr-2"></i> Director's Message</h4>
              </div>
            </div>
            <div class="row justify-content-center mt-5">
              <div class="col-lg-2">
                <div class="card-profile-image">
                  <a href="javascript:;">
                    <img src="img/director.jpg" class="rounded-circle img-fluid" />
                  </a>
                </div>
              </div>
              <div
                class="col-lg-8 text-lg-right align-self-lg-center"
              >
                <div class="card-profile-actions py-4 mt-lg-0">
                  <div class="text-center">
                    <h4 class="blockquote font-weight-700 font-italic w-70 text-center mx-auto text-danger">"WHATEVER YOU CAN DO OR DREAM YOU CAN, BEGIN IT NOW. BOLDNESS HAS GENIUS, POWER AND MAGIC IN IT."</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-5 mt-2 py-4 border-top text-left">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <p>
                    A very warm welcome to Alpine Public School. I am delighted that you have found your way to our website, the online home of our School.
                  </p>
                  <p>
                    Here at Alpine, we offer a traditional approach to education with a strong emphasis on the core skills of literacy and accuracy. Although we believe in a traditional approach, our lessons reflect modern thinking on how children learn most effectively and our classes and quality staff allow for a very personal approach to learning.
                  </p>
                  <p>
                    I hope that Alpine Public School has stimulated your imagination. Our website can only give you a small taste of what we do and our hopes and aims for the future.
                  </p>
                  <p>
                    In the fast pacing modern era, academic institution plays a pivotal role in human life. It is the institution that sharps the faculty of thinking, determines the social and cultural outlook of their children, provides emotional stability with the world, stables the augmentation of emotional and national integration. An academic institution determines the future of entire human race. Alpine Public School, Khurja promotes the best interests of the students, bestows them a power of insight and understanding. Situated in suburban area, this academy liberates the children from the darkness of ignorance and guide them to the light of true understanding. It ensures the real goal of education. The most vital duty of this school is to make children trained, grow and develop so that they may emerge as a fruitful citizen of tomorrow.
                  </p>
                  <p>
                    Now a days, the happy and joyful spirit of a child is going to be gloomy and dismal. They are not able to cope up with troubles, hurdles, tumults and difficulties. Here we grant them strength to endure the odd circumstances of life. We illumine their beings by the light of knowledge. All children are nurtured within the shell of institution.
                  </p>
                  <div class="h5 font-weight-500 mt-3">
                    Mr. Sandeep Mittal
                  </div>
                  <div class="h6 mt-2">
                    Director
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- principal's message -->
    <!-- <div class="section bg-secondary border-top" id="principal">
      <div class="container">
        <div class="card card-profile shadow">
          <div class="px-4">
            <div class="row mt-3">
              <div class="col-md-12">
                <h4 class="border-bottom text-center pb-3 pt-1"><i class="fa fa-pencil mr-2"></i> Principal's Message</h4>
              </div>
            </div>
            <div class="row justify-content-center mt-5">
              <div class="col-lg-2">
                <div class="card-profile-image">
                  <a href="javascript:;">
                    <img src="img/principal.jpeg" class="rounded-circle img-fluid" />
                  </a>
                </div>
              </div>
              <div
                class="col-lg-8 text-lg-right align-self-lg-center"
              >
                <div class="card-profile-actions py-4 mt-lg-0">
                  <div class="text-center">
                    <h4 class="blockquote font-weight-700 font-italic w-70 text-center mx-auto text-danger">The wood are lovely dark and deep.<br>
                    But I have promises to keep.<br>
                    And miles to go before I sleep.<br>
                    And miles to go before I sleep.</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-5 mt-2 py-4 border-top text-left">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <h4>DEAR PARENTS AND STUDENTS, GREETINGS!</h4>
                  <p>
                    Welcome to Alpine Public School. We greet students and parents as member of the Alpine Public School fraternity in the fascinating process of eduction. This website embodies a brief summery of the values and policies which direct the day to day operation of Alpine Public School
                  </p>
                  <p>
                    It is wonderful being associated with the Alpine Public School. It brings with it, immense responsibilities. Students are our priority and knowing the dedicated staff that we have, we will work diligently to teach our students each area of curriculum using a variety of teaching strategies to spark their interest in learning building a positive attitude will be our constant endeavor.
                  </p>
                  <p>
                    I am aware that our society is becoming more complex with tremendous expectations, hence a need to have knowledge with skills. Aiming the child with skills and co-curricular activities. The school will help achieve life's expectations.
                  </p>
                  <p>
                    Keeping all this in mind, we have structured curriculum, making it more creative by incorporating new ideas like co-operative learning, encouraging students participation, classroom presentation and group activities.
                  </p>
                  <p>
                    The school aims to provide the experience for physical, intellectual, aesthetic, emotional, spiritual and social development of child through creative and modern education in an attractive and caring environment. I invite the parents and children to come and join hands with us in making our institution a success story.
                  </p>
                  <div class="h5 font-weight-500 mt-3">
                    Miss. Nidhi Gulathi
                  </div>
                  <div class="h6 mt-2">
                    Principal
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- board of governers -->
    <div class="section team-2" id="board">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center mb-5">
            <h3 class="display-3 text-alpine">Board of Governors</h3>
            <!-- <h4 class="lead font-weight-500 mt-1 text-dark">
              This is the paragraph where you can write more details about your
              team. Keep you user engaged by providing meaningful information.
            </h4> -->
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6"
            v-for="board_of_governors in board_of_governor"
            :key="board_of_governors.board_of_governor_id"
          >
            <card class="card-profile" data-image="profile-image">
              <template slot="header">
                <div class="card-image">
                  <router-link to="/boardmembers">
                    <img class="img rounded" :src="board_of_governors.image" />
                  </router-link>
                </div>
              </template>
              <template slot="body">
                <router-link to="/boardmembers">
                  <h4 class="display-4 mb-0">{{ board_of_governors.name }}</h4>
                </router-link>
                <p class="lead">{{ board_of_governors.designation }}</p>
                <!-- <div class="table-responsive">
                  <p>This is the paragraph where you can write more details about your team. Keep you user engaged by providing meaningful information.</p>
                </div> -->
              </template>
            </card>
          </div>
          <!-- <div class="col-lg-4 col-md-6">
            <card class="card-profile" data-image="profile-image">
              <template slot="header">
                <div class="card-image">
                  <router-link to="/boardmembers">
                    <img class="img rounded" src="img/faces/team-5.jpg" />
                  </router-link>
                </div>
              </template>
              <template slot="body">
                <router-link to="/boardmembers">
                  <h4 class="display-4 mb-0">Farhan Qurashi</h4>
                </router-link>
                <p class="lead">Team Lead</p>
                
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card class="card-profile" data-image="profile-image">
              <template slot="header">
                <div class="card-image">
                  <router-link to="/boardmembers">
                    <img class="img rounded" src="img/faces/atikh.jpg" />
                  </router-link>
                </div>
              </template>
              <template slot="body">
                <router-link to="/boardmembers">
                  <h4 class="display-4 mb-0">Pia Sharma</h4>
                </router-link>
                <p class="lead">Content Creator</p>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card class="card-profile" data-image="profile-image">
              <template slot="header">
                <div class="card-image">
                  <router-link to="/boardmembers">
                    <img class="img rounded" src="img/faces/team-5.jpg" />
                  </router-link>
                </div>
              </template>
              <template slot="body">
                <router-link to="/boardmembers">
                  <h4 class="display-4 mb-0">Farhan Qurashi</h4>
                </router-link>
                <p class="lead">Team Lead</p>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card class="card-profile" data-image="profile-image">
              <template slot="header">
                <div class="card-image">
                  <router-link to="/boardmembers">
                    <img class="img rounded" src="img/faces/atikh.jpg" />
                  </router-link>
                </div>
              </template>
              <template slot="body">
                <router-link to="/boardmembers">
                  <h4 class="display-4 mb-0">Pia Sharma</h4>
                </router-link>
                <p class="lead">Content Creator</p>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card class="card-profile" data-image="profile-image">
              <template slot="header">
                <div class="card-image">
                  <router-link to="/boardmembers">
                    <img class="img rounded" src="img/faces/team-1.jpg" />
                  </router-link>
                </div>
              </template>
              <template slot="body">
                <router-link to="/boardmembers">
                  <h4 class="display-4 mb-0">Raju Rastogi</h4>
                </router-link>
                <p class="lead">UX Designer</p>
              </template>
            </card>
          </div> -->
        </div>
      </div>
    </div>
    <!-- affiliations -->
    <div
      class="section features-7 bg-alpine-dark"
      id="affiliations"
      style="background-image: url('img/ill/p31.svg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 text-center mx-auto">
            <h3 class="display-3 text-white">Affiliations</h3>
          </div>
        </div>
        <div class="row justify-content-center mt-3 mt-md-5">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div
                class="col-lg-6 col-12 mt-0"
                v-animate-onscroll="'animated zoomIn'"
              >
                <card class="shadow border-0" bodyClasses="d-flex">
                  <template slot="body">
                    <img
                      src="img/cbse.png"
                      class="img-fluid pr-4"
                      style="height: fit-content;"
                    />
                    <div>
                      <h5>AFFLIATD TO CBSE BOARD</h5>
                      <p class="font-weight-500 mb-0">
                        Affiliation No – (2130590)
                      </p>
                      <p class="font-weight-500 mb-1">School Code – (60262)</p>
                      <p>
                        The Alpine Public School Khurja is affiliated with CBSE
                        vide affiliation. And School follow the NCERT
                        curriculum.
                      </p>
                    </div>
                  </template>
                </card>
              </div>
              <div
                class="col-lg-3 col-12 mt-0"
                v-animate-onscroll="'animated zoomIn'"
                v-for="affiliation in affiliations"
                :key="affiliation._id"
              >
                <card class="shadow border-0">
                  <template slot="body" class="py-5">
                    <img
                      :src="affiliation.logo"
                      class="img-fluid d-block mx-auto"
                    />
                  </template>
                </card>
              </div>
              <!-- <div class="col-lg-3 col-6 mt-0" v-animate-onscroll="'animated zoomIn'">
                <card class="shadow border-0">
                  <template slot="body" class="py-5">
                    <img src="img/tata_class.png" class="img-fluid">
                  </template>
                </card>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- social responsibility -->
    <div class="section team-2" id="socialresponsibility">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center mb-2 mb-md-5">
            <h3 class="display-3 text-alpine">
              🤝 Social Responsibility 🤝<span
                >({{ social_responsibility.title }})</span
              >
            </h3>
            <!-- <h4 class="lead">
              This is the paragraph where you can write more details about your
              team. Keep you user engaged by providing meaningful information.
            </h4> -->
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row text-left justify-content-center">
          <div class="col-lg-7 col-12">
            <p
              class="pb-2 mt-0 text-justify v-html"
              v-html="social_responsibility.long_description"
            ></p>
            <router-link to="/gyaanjyoti" class="btn btn-alpine btn-lg"
              >Read More...</router-link
            >
          </div>
          <div class="col-lg-5 col-12 mb-3">
            <template>
              <div id="app">
                <CoolLightBox
                  :items="items"
                  :index="index"
                  @close="index = null"
                >
                </CoolLightBox>

                <div class="images-wrapper row">
                  <div
                    class="image-wrapper col-md-6"
                    v-for="(image, imageIndex) in social_responsibility.images"
                    :key="imageIndex"
                  >
                    <!-- <div
                      class="gallery_image"
                      @click="index = imageIndex"
                      :style="{ backgroundImage: 'url(' + image + ')' }"
                    ></div> -->
                    <img
                      :src="image"
                      @click="index = imageIndex"
                      class="gallery_image img-fluid shadow rounded p-1 border"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import axios from "axios";
export default {
  bodyClass: "about-us",
  components: {
    CoolLightBox,
  },
  data() {
    return {
      aboutdata: {},
      board_of_governor: [],
      affiliations: [],
      social_responsibility: [],
      items: [
        "img/results_bg.jpg",
        "img/physics_lab.jpg",
        "img/sports4.jpg",
        "img/school_bul.jpeg",
      ],
      index: null,
      auth: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
      authjson: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
    };
  },
  mounted() {
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_about",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.aboutdata = response.data.details[0];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_board_of_governor",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.board_of_governor = response.data.details;
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_affiliation",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.affiliations = response.data.details;
        } else {
          this.affiliations = [];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_social_responsibility",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.social_responsibility = response.data.details[0];
        } else {
          this.social_responsibility = [];
        }
      });
    // read meta api call
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_metadata",
        { details: { page_url: "/" }, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.metaData = response.data.details[0];
        } else {
          this.metaData = {};
        }
      });
  },
};
</script>
<style>
.timeline {
  position: relative;
}
.timeline-one-side .timeline-step,
.timeline-one-side:before {
  left: 1rem;
}
.timeline:before {
  content: "";
  position: absolute;
  top: 0;
  left: 1rem;
  height: 100%;
  border-right: 2px solid #e9ecef;
}
.timeline-block {
  position: relative;
  margin: 2em 0;
}
.timeline-block:first-child {
  margin-top: 0;
}
.timeline-step {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  text-align: center;
  transform: translateX(-50%);
  font-size: 1rem;
  font-weight: 600;
  z-index: 1;
}
.timeline-one-side .timeline-content {
  width: auto;
}
.timeline-content {
  margin-left: 60px;
  padding-top: 0.5rem;
  position: relative;
  top: -6px;
}
.timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.about-us .card-profile[data-image="profile-image"] .card-header::after {
  top: 24%;
  left: 36%;
}
.about-us .card-background .card-body {
  min-height: 130px;
}
.about-us .card-background.card-blog {
  overflow: hidden;
  height: 180px;
}
.about-us .card-background .card-body {
  padding-top: 20px;
  padding-bottom: 10px;
}
.about-us .bg-image {
  background-image: url(/img/contact_bg.jpeg);
}
.about-us .page-header {
  min-height: 100vh;
}
.about-us .images-wrapper .image-wrapper {
  margin-bottom: 10px;
  width: calc(50% - 10px);
  margin-right: 0px;
}
@media (min-width: 992px) {
  .timeline:before {
    left: 50%;
    margin-left: -2px;
  }
  .timeline-step {
    left: 50%;
  }
  .timeline-one-side .timeline-content {
    max-width: 30rem;
  }
  .timeline-content {
    width: 38%;
  }
  .timeline-block:nth-child(2n) .timeline-content {
    float: right;
  }
}
@media (max-width: 992px) {
  .timeline-step {
    left: 17px;
  }
}
</style>
