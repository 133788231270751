<template>
  <footer class="footer position-relative pt-0">
    <!-- contact form -->
    <div class="section contactus-1 bg-dark mb-4 pb-5">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-5 col-md-5 d-flex justify-content-center flex-column"
          >
            <h2 class="title text-white">Get in Touch</h2>
            <!-- <h4 class="description text-white">
              You need more information? Check what other persons are saying about
              our product. They are very happy with their purchase.
            </h4> -->
            <div class="info info-horizontal">
              <div
                class="icon icon-shape icon-shape-danger shadow rounded-circle text-white"
              >
                <i class="ni ni-square-pin"></i>
              </div>
              <div class="description">
                <a href="https://goo.gl/maps/8uTuyzWUCyPaE9C37" target="_blank">
                  <h4 class="info-title text-white">Find us at the office</h4>
                  <p class="description ml-3 text-white">
                    <!-- {{contactusData.person}}<br /> -->
                    {{contactusData.address}}
                  </p>
                </a>
              </div>
            </div>
            <div class="info info-horizontal">
              <div
                class="icon icon-shape icon-shape-danger shadow rounded-circle text-white"
              >
                <i class="ni ni-mobile-button"></i>
              </div>
              <div class="description">
                <h4 class="info-title text-white">Reach out to us</h4>
                <p class="description ml-3 text-white">
                  {{contactusData.phone}}<br />
                  {{contactusData.email}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-7 ml-auto mr-auto">
            <card headerClasses="text-center">
              <template slot="header" class="text-center">
                <h4 class="card-title">Contact Us</h4>
              </template>
              <template slot="body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Full name</label>
                      <base-input
                        placeholder="Full name"
                        addonLeftIcon="ni ni-circle-08"
                        v-model="model.name"
                      ></base-input>
                      <div
                        v-if="$v.model.name.$error"
                        class="error small text-danger pt-1 pl-1 mt--3"
                         >
                        Field is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 pl-2">
                    <div class="form-group">
                      <label>Phone number</label>
                      <base-input
                        placeholder="999999999"
                        v-model="model.mobile"
                        addonLeftIcon="ni ni-mobile-button"
                      ></base-input>
                      <div
                        v-if="model.mobile.length>1 && model.mobile.length!=10"
                        class="small text-danger pt-1 pl-1 mt--3"
                         >
                          Invalid phone number.
                      </div>
                      <div
                        v-if="$v.model.mobile.$error"
                        class="error small text-danger pt-1 pl-1 mt--3"
                         >
                        Field is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Email address</label>
                  <base-input
                    placeholder="Email here..."
                    v-model="model.email"
                    addonLeftIcon="ni ni-email-83"
                  ></base-input>
                  <div
                    v-if="model.eruser_email"
                    class="error small text-danger pt-1 pl-1 mt--3"
                     >
                      Invalid email.
                  </div>
                  <div
                    v-if="$v.model.email.$error"
                    class="error small text-danger pt-1 pl-1 mt--3"
                     >
                    Field is required.
                  </div>
                </div>
                <div class="form-group">
                  <label>Your message</label>
                  <textarea
                    name="message"
                    v-model="model.query"
                    class="form-control"
                    id="message"
                    rows="6"
                  ></textarea>
                  <div
                    v-if="$v.model.query.$error"
                    class="error small text-danger pt-1 pl-1"
                     >
                    Field is required.
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-checkbox v-model="terms" @change="$v.terms.$touch()">I'm not a robot</base-checkbox>
                    <div
                        v-if="$v.terms.$invalid"
                        class="error small text-danger pt-1 pl-1 mt-0"
                         >
                        Field is required.
                    </div>
                  </div>
                  <div class="col-md-6">
                    <base-button
                      nativeType="submit"
                      type="alpine"
                      id="send_message"
                      @click="$v.model.$touch();submitquery()"
                      class="pull-right"
                      >Send Message</base-button
                    >
                  </div>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="container">
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-6 text-md-left text-center">
          <div class="copyright">
            &copy; {{ year }}
            <a href="javascript:void();" target="_blank">Alpine Public School</a>
          </div>
        </div>
        <div class="col-lg-6 text-md-right text-center btn-wrapper mt-2 mt-md-0">
          <a
            target="_blank"
            href="https://twitter.com/alpinekhurja"
            rel="nofollow"
            class="btn btn-twitter btn-social rounded-circle text-white p-2"
            data-toggle="tooltip"
            data-original-title="Follow us"
            type=""
          >
            <span class="btn-inner--icon"><i class="fa fa-twitter pt-1"></i></span>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/APS.Kh2002/"
            rel="nofollow"
            class="btn btn-social rounded-circle btn-facebook text-white p-2"
            data-toggle="tooltip"
            data-original-title="Like us"
          >
            <span class="btn-inner--icon"><i class="fa fa-facebook pt-1"></i></span>
          </a>
          <a
            target="_blank"
            href="https://instagram.com/apskhurja"
            rel="nofollow"
            class="btn btn-social rounded-circle btn-instagram text-white p-2"
            data-toggle="tooltip"
            data-original-title="Follow us"
          >
            <span class="btn-inner--icon"><i class="fa fa-instagram pt-1"></i></span>
          </a>
          <!-- <a
            target="_blank"
            href="https://github.com/creativetimofficial"
            rel="nofollow"
            class="btn btn-social rounded-circle btn-linkedin text-white p-2"
            data-toggle="tooltip"
            data-original-title="Star on Github"
          >
            <span class="btn-inner--icon"><i class="fa fa-linkedin pt-1"></i></span>
          </a> -->
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { required,sameAs } from 'vuelidate/lib/validators';
import axios from "axios";
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
      contactusData:[],
      model:{
        name:'',
        mobile: '',
        email: '',
        query: '',
        query_type: '',
        status:'approved',
        eruser_email:false,
       },
       terms:false,
       auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        authjson : { headers: { 'Content-Type':'application/json',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
    };
  },
  validations: {
      model: {
        name: {
          required
        },
        mobile: {
          required
        },
        email:{
          required
        },
        query:{
          required
        },
      },
      terms: {
        sameAs: sameAs( () => true )
      },
    },
    mounted () {

        axios
        .post(process.env.VUE_APP_API_ENDPOINT+'read_contactus',{ "details" :{},"offset":"0"},this.authjson)
        .then(response => {
            console.log(response);
            if(response.data.details){
            this.contactusData=response.data.details[0];
            }
        })
        
  },
  methods: {

    submitquery(){
      console.log(this.$v.model.$error);
      if(this.$v.model.$error===false && this.$v.terms.$invalid===false && this.model.mobile.length===10 && !this.model.eruser_email){
     document.getElementById("send_message").innerHTML='<i class="fa fa-circle-o-notch fa-spin mr-2"></i>Sending';
      
      axios.post(process.env.VUE_APP_API_ENDPOINT+'create_contact',{name : this.model.name,mobile:this.model.mobile,email:this.model.email,query:this.model.query,query_type:this.model.query_type,status:this.model.status},this.authjson).then(response => {
        console.log(response);
        this.message=response.data.message; 
        document.getElementById("send_message").classList.remove('btn-warning');
        document.getElementById("send_message").classList.add('btn-success');
        document.getElementById("send_message").innerHTML='Message Sent';
        setTimeout(function(){
        document.getElementById("send_message").classList.remove('btn-success');
        document.getElementById("send_message").classList.add('btn-warning');
        document.getElementById("send_message").innerHTML="Saved";
        window.location.reload()
        }, 2000);
      })
     }
    },
    email_validation(){
        if(this.model.email.length>0){
         var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
          if (regexEmail.test(this.model.email)) {
              this.model.eruser_email=false;
          } else {
              this.model.eruser_email=true;

          }
        }else{
          this.model.eruser_email=false;
        }
    },
    
    // dateformat(val) {
    //   return moment.utc(new Date(val)).format("MMM Do YY");
    //   //console.log(this.blogData.date);
    // }
  },
};
</script>
<style>
.footer .contactus-1:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #3e211a;
  opacity: .85;
}
.footer .contactus-1 {
  background-image: url(/img/contact_bg.jpeg);
  background-size: cover;   
}
.seo_links a {
  color: #75818e;
  font-size: 0.85rem;
}
.btn-social {
  width: 2.7rem;
  height: 2.7rem;
}
.btn-social .fa {
  font-size: 1.2rem;
}
</style>
