<template>
  <div class="wrapper">
    <div class="page-header header-filter">
      <div
        class="page-header-image"
        :style="'background-image: url(\''+postData.blog_img+'\');'"
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-10 mx-auto text-center py-7">
            <h2 class="display-2 text-white">{{postData.blog_title}}</h2>
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h6 class="category">{{format_datetime(postData.created_at)}}</h6>
            <h3 class="title">{{postData.blog_title}}</h3>
            <p v-html="postData.blog_body"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import moment from 'moment';
export default {
  bodyClass: "blog-post",
  components: {
  },
  data() {
    return {
       animationObject:{
        classes: 'fadeInLeft',
        delay: 500,
        duration: 1000
       },
       postData:[],
       datanotfound:false,
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        authjson : { headers: { 'Content-Type':'application/json',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
    }
  },
  mounted () {
      // read blog api call
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_blog_by_id',{"blog_id" :this.$route.params.id,"status" :"approved"})
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.postData=response.data.details[0];
        }
      })
  },
  methods: {
    format_datetime(value){
      if(value) {
        return moment.utc(new Date(value)).format("LLL")
      }
    },
  }
};
</script>
<style></style>
