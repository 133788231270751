<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/lab_bg.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-6 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                Cutting-edge labs<span class="text-light">
                  for every department</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project-2 my-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mx-auto text-center my-5">
            <h3 class="display-3">LABORATORIES</h3>
            <p class="lead text-dark font-weight-500">
              The school maintains well planned, spacious and well equipped laboratories. These laboratories cater copiously to the students of junior, middle and senior school and aid in preparing the senior students for the board exams through experiments and projects carried out by them, under the supervision of qualified staff.
            </p>
          </div>
        </div>
        <div class="row team-4">
          <div class="col-lg-12 ml-auto mr-auto mb-3" id="computer_lab" v-animate-onscroll="'animated fadeInRight'">
            <div class="card card-profile card-horizontal">
              <div class="row">
                <div class="col-xl-6">
                  <div class="card-body pt-2 pr-0 pb-0">
                    <div class="card-profile-stats">
                      <div class="text-left p-0 d-flex align-items-center">
                        <div class="badge badge-circle badge-success badge-lg mr-3"><i class="fa fa-laptop"></i></div>
                        <span class="heading">Computer Lab</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer pr-0 pt-2">
                    <p class="card-description mt-0">
                      The school have three well - equipped Senior, Junior and Primary computer Labs. This set up is completely managed by highly qualify faculty. The school provides computer science education to students right from class II onwards with thorough professional zeal.
                    </p> 
                    <p class="card-description mt-0">
                      Computer labs for both the junior and the senior students are connected through Local Area Network (LAN) and Internet. It provides a true atmosphere to the students to grow with a keen understanding of technology. Multimedia presentation makes the learning easy and interesting.
                    </p>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="card-image no-mask h-100">
                    <a href="javascript:;">
                      <img class="img h-100" src="img/computer_lab.jpeg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 ml-auto mr-auto mb-3" id="atal" v-animate-onscroll="'animated fadeInLeft'">
            <div class="card card-profile card-horizontal">
              <div class="row">
                <div class="col-xl-6">
                  <div class="card-image no-mask h-100">
                    <a href="javascript:;">
                      <img class="img h-100" src="img/laboratories.jpeg" />
                    </a>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="card-body pt-2 px-0 pb-0">
                    <div class="card-profile-stats">
                      <div class="text-left p-0 d-flex align-items-center">
                        <div class="badge badge-circle badge-danger badge-lg mr-3"><i class="fa fa-life-ring"></i></div>
                        <span class="heading">Atal Tinkering Lab</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer pl-0 pt-2">
                    <p class="card-description mt-0">
                      Alpine Public School has been selected to establish the prestigious Atal Tinkering Laboratory in the school premises. The programme is being spearheaded by NITI Aayog with a vision to ‘Cultivate one Million children in India as Neoteric Innovators’. The main aim of ATL is to let young minds begin tinkering and innovating in order to solve problems they feel can be addressed through adequate use of technology.</p> 
                    <p class="card-description mt-0">
                      ATL fosters curiosity, creativity and imagination in young minds; and inculcate skills such as design mind set, computational thinking, adaptive learning, physical computing etc. The lab activities are designed to spur the spark of creativity, and go beyond regular curriculum and text book learning. The labs let students explore skills of future such as 3 D design and computational thinking, adaptive learning and artificial intelligence.
                    </p>
                    <p class="card-description mt-0">
                      Students of neighbouring schools also have access to the lab. Atal lab activities have made Alpine Public School to be a harbinger of change in the society that will transform India to a technologically driven developed nation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 ml-auto mr-auto mb-3" id="physics_lab" v-animate-onscroll="'animated fadeInLeft'">
            <div class="card card-profile card-horizontal">
              <div class="row">
                <div class="col-xl-6">
                  <div class="card-image no-mask h-100">
                    <a href="javascript:;">
                      <img class="img h-100" src="img/physics_lab.jpg" />
                    </a>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="card-body pt-2 px-0 pb-0">
                    <div class="card-profile-stats">
                      <div class="text-left p-0 d-flex align-items-center">
                        <div class="badge badge-circle badge-info badge-lg mr-3"><i class="fa fa-cog"></i></div>
                        <span class="heading">Physics Lab</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer pl-0 pt-2">
                    <p class="card-description mt-0">
                      The school has a spacious properly ventilated physics lab with especially large models in size so as to provide the children clarity of concepts. The latest state-of-the art instruments equip our students to have hands on experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 ml-auto mr-auto mb-3" id="chem_lab" v-animate-onscroll="'animated fadeInRight'">
            <div class="card card-profile card-horizontal">
              <div class="row">
                <div class="col-xl-6">
                  <div class="card-body pt-2 pr-0 pb-0">
                    <div class="card-profile-stats">
                      <div class="text-left p-0 d-flex align-items-center">
                        <div class="badge badge-circle badge-primary badge-lg mr-3"><i class="fa fa-flask"></i></div>
                        <span class="heading">Chemistry Lab</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer pr-0 pt-2">
                    <p class="card-description mt-0">
                      Chemistry lab of the school is equipped with the best available material for experiments and the emphasis is laid on teaching the concepts by making individual efforts.
                    </p> 
                    <p class="card-description mt-0">
                      The purpose is to impart practical knowledge and techniques among the students and nurture the budding scientist within them. Here students learn to analyse various skills of chemistry Practical.
                    </p>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="card-image no-mask h-100">
                    <a href="javascript:;">
                      <img class="img h-100" src="img/chem_lab.jpg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 ml-auto mr-auto mb-3" id="biology_lab" v-animate-onscroll="'animated fadeInLeft'">
            <div class="card card-profile card-horizontal">
              <div class="row">
                <div class="col-xl-6">
                  <div class="card-image no-mask h-100">
                    <a href="javascript:;">
                      <img class="img h-100" src="img/biology_lab.jpg" />
                    </a>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="card-body pt-2 px-0 pb-0">
                    <div class="card-profile-stats">
                      <div class="text-left p-0 d-flex align-items-center">
                        <div class="badge badge-circle badge-success badge-lg mr-3"><i class="fa fa-leaf"></i></div>
                        <span class="heading">Biology Lab</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer pl-0 pt-2">
                    <p class="card-description mt-0">
                      3D pictures, detachable models and the variety of colored charts available in the lab provides ample opportunity to each individual to learn by experimenting, visual aids further assist the children to enhance their knowledge. 
                    </p>
                    <p class="card-description mt-0">
                      Home science lab in the school is specially used for activity classes. Under the guidance of the teacher, the students learn cooking, stitching, embroidery and many more things related to housekeeping
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 ml-auto mr-auto mb-3" id="math_lab" v-animate-onscroll="'animated fadeInRight'">
            <div class="card card-profile card-horizontal">
              <div class="row">
                <div class="col-xl-6">
                  <div class="card-body pt-2 pr-0 pb-0">
                    <div class="card-profile-stats">
                      <div class="text-left p-0 d-flex align-items-center">
                        <div class="badge badge-circle badge-primary badge-lg mr-3"><i class="fa fa-plus"></i></div>
                        <span class="heading">Math Lab and Social Science Lab</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer pr-0 pt-2">
                    <p class="card-description mt-0">
                      Besides these, the school has a mathematics lab to familiarize the children to the concept of mathematics. With the help of models and charts, the fear for the subjects gets removed. 
                    </p> 
                    <p class="card-description mt-0">
                      The very sight of the laboratory brings about a change in the students especially in the juniors and they crave to learn mathematics in a play way method. They school in planning to start shortly a social science lab where the students would get the models and charts depicting the glory of the past as well as the geographical conditions of India.
                    </p>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="card-image no-mask h-100">
                    <a href="javascript:;">
                      <img class="img h-100" src="img/math_lab.jpg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-lg-4 col-md-6" v-animate-onscroll="'animated zoomIn'">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-favourite-28"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Science Lab</h4>
                <p class="card-description">
                  If everything I did failed - which it doesn't, it actually
                  succeeds - just the fact that I'm willing to fail is an
                  inspiration. People are so scared to lose that they don't even
                  try.
                </p>
                <div class="card-footer">
                  <button type="button" class="btn btn-link text-primary">
                    <i class="ni ni-glasses-2"></i> Check more
                  </button>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6" v-animate-onscroll="'animated zoomIn'">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-books"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Computer Lab</h4>
                <p class="card-description">
                  You have the opportunity to play this game of life you need to
                  appreciate every moment. A lot of people don’t appreciate the
                  moment until it’s motivating the doers.
                </p>
                <div class="card-footer">
                  <button type="button" class="btn btn-link text-success">
                    <i class="ni ni-key-25"></i> Find a opportunity
                  </button>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6" v-animate-onscroll="'animated zoomIn'">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-trophy"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Physics Lab</h4>
                <p class="card-description">
                  For standing out. But the time is now to be okay to be the
                  greatest you. Would you believe in what you believe in, if you
                  were the only one who believed it?
                </p>
                <div class="card-footer">
                  <button type="button" class="btn btn-link text-warning">
                    <i class="ni ni-notification-70"></i> Check more
                  </button>
                </div>
              </template>
            </card>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-4 col-md-6" v-animate-onscroll="'animated zoomIn'">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-secondary shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-favourite-28"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Chemistry Lab</h4>
                <p class="card-description">
                  Society has put up so many boundaries, so many limitations on
                  what’s right and wrong that it’s almost impossible to get a pure
                  thought out. It’s like a little kid, a little boy.
                </p>
                <div class="card-footer">
                  <button type="button" class="btn btn-link text-secondary">
                    <i class="ni ni-glasses-2"></i> Check out now
                  </button>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6" v-animate-onscroll="'animated zoomIn'">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-books"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Biology Lab</h4>
                <p class="card-description">
                  Pink is obviously a better color. Everyone’s born confident, and
                  everything’s taken away from you matters is the people who are
                  sparked by it follow their dreams, too.
                </p>
                <div class="card-footer">
                  <button type="button" class="btn btn-link text-danger">
                    <i class="ni ni-key-25"></i> Find a opportunity
                  </button>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6" v-animate-onscroll="'animated zoomIn'">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mx-auto"
                >
                  <i class="ni ni-books"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Math Lab and Social Science Lab</h4>
                <p class="card-description">
                  Constantly growing. We’re constantly making mistakes. We’re
                  constantly trying to express ourselves and actualize our dreams
                  the position that we want to be.
                </p>
                <div class="card-footer">
                  <button type="button" class="btn btn-link text-info">
                    <i class="ni ni-notification-70"></i> Check more
                  </button>
                </div>
              </template>
            </card>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>


export default {
  bodyClass: "key_features",
  components: {
  }
};
</script>
<style>
.key_features  .info-horizontal {
  max-width: 100% !important;
}
</style>
