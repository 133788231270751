<template>
  <div class="wrapper">
    <section class="section-profile-cover section-shaped my-0">
      <!-- Circles background -->
      <img class="bg-image" src="img/director_bg.jpg" style="width: 100%;" />
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-secondary"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </section>
    <section class="section bg-secondary">
      <div class="container">
        <div class="card card-profile shadow mt--300">
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 mt--4 order-lg-2">
                <div class="card-profile-image">
                  <a href="javascript:;">
                    <img :src="authorityData[0].authority_image" class="img-fluid rounded-circle" />
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center"
              >
                <div class="card-profile-actions py-4 mt-lg-0">
                  
                </div>
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">
                  
                </div>
              </div>
            </div>
            <div class="mt-5 py-5 border-top text-left">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <p v-html="authorityData[0].authority_long_message"></p>
                  <div class="h5 font-weight-500 mt-3">
                    <i class="ni location_pin mr-2"></i>{{authorityData[0].authority_name}}
                  </div>
                  <div class="h6 mt-2">
                    <i class="ni business_briefcase-24 mr-2"></i>{{authorityData[0].designation}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  bodyClass: "director",
  components: {
  },
  data() {
    return {
       animationObject:{
        classes: 'fadeInLeft',
        delay: 500,
        duration: 1000
       },
       authorityData:[],
       datanotfound:false,
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        authjson : { headers: { 'Content-Type':'application/json',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
    }
  },
  mounted () {
    axios
    .post(process.env.VUE_APP_API_ENDPOINT+'read_authority',{ "details" :{},"offset":"0"},this.authjson)
    .then(response => {
        console.log(response);
        if(response.data.details){
        this.authorityData=response.data.details;
        }
    })
  },
}
</script>
<style>
.director blockquote {
  font-size: 1.5rem;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 0px;
}
</style>
