<template>
  <div class="wrapper">
    <div class="main-slides h-50vh" style="background: url(img/contact_bg.jpg); background-size: cover;">
      <div class="page-header text-light">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center mb-5">
              <h1 class="display-1 mt-8 text-light">Got a question?</h1>
              <h3 class="lead text-light">We'd like to talk more about what you need</h3>
              <base-button type="danger" class="mt-3"
                >Contact us</base-button
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-6 col-6">
              <div class="info info-hover border" v-animate-onscroll="'animated zoomIn'">
                <div
                  class="icon icon-shape icon-shape-danger icon-lg shadow rounded-circle text-light"
                >
                  <i class="ni ni-square-pin"></i>
                </div>
                <h4 class="info-title text-light">Address</h4>
                <p class="description px-0">{{contactus.address}}</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="info info-hover border" v-animate-onscroll="'animated zoomIn'">
                <div
                  class="icon icon-shape icon-shape-danger icon-lg shadow rounded-circle text-light"
                >
                  <i class="ni ni-email-83"></i>
                </div>
                <h4 class="info-title text-light">Email</h4>
                <p class="description px-0">{{contactus.email}}</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="info info-hover border" v-animate-onscroll="'animated zoomIn'">
                <div
                  class="icon icon-shape icon-shape-danger icon-lg shadow rounded-circle text-light"
                >
                  <i class="ni ni-mobile-button"></i>
                </div>
                <h4 class="info-title text-light">Phone</h4>
                <p class="description px-0">{{contactus.phone}}</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6">
              <div class="info info-hover border" v-animate-onscroll="'animated zoomIn'">
                <div
                  class="icon icon-shape icon-shape-danger icon-lg shadow rounded-circle text-light"
                >
                  <i class="ni ni-circle-08"></i>
                </div>
                <h4 class="info-title text-light">Contact</h4>
                <p class="description px-0">{{contactus.person}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="container-fluid">
        <div class="row mt-5 mb-4 pt-5">
          <div class="col-md-8 ml-auto mr-auto text-center mt-5">
            <badge type="info">Leave a message</badge>
            <h1 class="title">Tell us more about <b>yourself</b></h1>
            <h4 class="desc">
              Whether you have questions or you would just like to say hello,
              contact us.
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mx-auto ">
            <!-- <form
              role="form"
              class="card bg-secondary p-3"
              id="contact-form"
              method="post"
            > -->
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>First name</label>
                      <base-input
                        placeholder="First Name..."
                        v-model="model.name"
                        addonLeftIcon="ni ni-circle-08"
                      ></base-input>
                      <div
                        v-if="$v.model.name.$error"
                        class="error small text-danger pt-1 pl-1 mt--3"
                         >
                        Field is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Phone number</label>
                      <base-input
                        placeholder="999999999"
                        v-model="model.mobile"
                        addonLeftIcon="ni ni-mobile-button"
                      ></base-input>
                      <div
                        v-if="model.mobile.length>1 && model.mobile.length!=10"
                        class="small text-danger pt-1 pl-1 mt--3"
                         >
                          Invalid phone number.
                      </div>
                      <div
                        v-if="$v.model.mobile.$error"
                        class="error small text-danger pt-1 pl-1 mt--3"
                         >
                        Field is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Email address</label>
                  <base-input
                    placeholder="Email here..."
                    v-model="model.email"
                    addonLeftIcon="ni ni-email-83"
                  ></base-input>
                  <div
                    v-if="model.eruser_email"
                    class="error small text-danger pt-1 pl-1 mt--3"
                     >
                      Invalid email.
                  </div>
                  <div
                    v-if="$v.model.email.$error"
                    class="error small text-danger pt-1 pl-1 mt--3"
                     >
                    Field is required.
                  </div>
                </div>
                <div class="form-group">
                  <label>Your message</label>
                  <textarea
                    name="message"
                    v-model="model.query"
                    class="form-control"
                    id="message"
                    rows="6"
                  ></textarea>
                  <div
                    v-if="$v.model.query.$error"
                    class="error small text-danger pt-1 pl-1"
                     >
                    Field is required.
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 ml-auto">
                    <base-button
                      nativeType="submit"
                      type="primary"
                      class="pull-right"
                      id="send_message"
                      @click="$v.model.$touch();submitquery()"
                      >Send Message</base-button>
                  </div>
                </div>
              </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <gmap-map
      id="map-contactus-3"
      class="map skew-separator skew-top"
      style="height: 500px; position: relative; overflow: hidden;"
      :center="center"
      :zoom="13"
      :options="options"
      map-type-id="terrain"
    >
      <gmap-marker :position="center"> </gmap-marker>
    </gmap-map> -->
  </div>
</template>
<script>
import { API_KEY } from "@/constants";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import axios from "axios";
import { required } from 'vuelidate/lib/validators';


Vue.use(VueGoogleMaps, {
  load: {
    key: API_KEY
  }
});

export default {
  bodyClass: "contact-page",
  components: {
  },
  data() {
    return {
      contactus:[],
      admissions :[],
      events :[],
      traditional_touch:[],
      beyond_academic:[],
      model:{
        name:'',
        mobile: '',
        email: '',
        query: '',
        query_type: '',
        status:'approved',
        eruser_email:false,
       },
       terms:false,
       index: null,
       auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        authjson : { headers: { 'Content-Type':'application/json',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
    }
  },
  validations: {
      model: {
        name: {
          required
        },
        mobile: {
          required
        },
        email:{
          required
        },
        query:{
          required
        },
      },
    },
  mounted () {          
        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_contactus',{ "details" :{},"offset":"0"},this.authjson)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.contactus=response.data.details[0]; 
            }else{
              this.contactus=[];
            }
          })
        // read meta api call
  },
  methods: {

    submitquery(){
      console.log(this.$v.model.$error);
      if(this.$v.model.$error===false && this.model.mobile.length===10 && !this.model.eruser_email){
     document.getElementById("send_message").innerHTML='<i class="fa fa-circle-o-notch fa-spin mr-2"></i>Sending';
      document.getElementById("send_message").innerHTML='Sending message...';
      axios.post(process.env.VUE_APP_API_ENDPOINT+'create_contact',{name : this.model.name,mobile:this.model.mobile,email:this.model.email,query:this.model.query,query_type:this.model.query_type,status:this.model.status},this.authjson).then(response => {
        console.log(response);
        this.message=response.data.message; 
        document.getElementById("send_message").classList.remove('btn-primary');
        document.getElementById("send_message").classList.add('btn-success');
        document.getElementById("send_message").innerHTML='Message successfully sent';
        setTimeout(function(){
        document.getElementById("send_message").classList.remove('btn-success');
        document.getElementById("send_message").classList.add('btn-success');
        document.getElementById("send_message").innerHTML="Message successfully sent";
        window.location.reload()
        }, 3000);
      })
     }
    },
    email_validation(){
        if(this.model.email.length>0){
         var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
          if (regexEmail.test(this.model.email)) {
              this.model.eruser_email=false;
          } else {
              this.model.eruser_email=true;

          }
        }else{
          this.model.eruser_email=false;
        }
    },
    
  },
};
</script>
<style></style>
