<template>
  <div class="wrapper">
    <div
      class="main-slides h-70vh"
      style="background: url(img/contact_bg.jpg); background-size: cover;"
    >
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div
              class="col-lg-7 col-12"
              v-animate-onscroll="{
                down: 'animated fadeOutDown',
                up: 'animated fadeInDown',
              }"
            >
              <h1 class="display-2 mt-8 text-light">
                Departments make it easy<span class="text-light">
                  to focus on each subject.</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1 pb-md-5 pt-md-5 border-bottom" id="english">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine" v-if="departmentData[0]">
              {{ departmentData[0].heading }}
            </h3>
          </div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row text-left justify-content-center">
          <div
            class="col-lg-8 col-12 mb-3"
            v-if="departmentData[0]"
            v-html="departmentData[0].description"
          >
            <!-- <p class="pb-2 mt-0">
              Through Early Years, we have adopted <strong>Read, Write, Inc.</strong>, a synthetic phonics programme which is delivered throughout the school to enable our students to become able readers, spellers and writers. It teaches the essential skills of linking sounds and letters together to form words, to ensure child’s early reading and writing development.
            </p> 
            <p class="pb-2 mt-0">
              In next step <strong>the Read Write spelling programme</strong> is introduced to further develop knowledge of spelling patterns and etymology. This is supplemented with a daily grammar session and supporting weekly homework. Communication is important in all walks of life and we hope to send your children on as excellent communicators in both spoken and written English.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1 pb-md-5 pt-md-5 border-bottom" id="maths">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine">Mathematics</h3>
          </div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row text-left justify-content-center">
          <div
            class="col-lg-8 col-12 mb-3"
            v-if="departmentData[1]"
            v-html="departmentData[1].description"
          >
            <!-- <p class="pb-2 mt-0">
              The basis of our mathematics teaching is a practical approach in
              the first instance using concrete objects and representations of
              concepts with many variations. Emphasis is placed on developing a
              mathematical fluency alongside reasoning, arithmetic and the
              ability to use and apply mathematics in real life situations and
              to explore and investigate the subject itself.
            </p>
            <p class="pb-2 mt-0">
              Children need to be able to, use problem solving and
              investigational strategies, understand concepts and techniques,
              apply it to a range of contexts and develop a sense of what
              mathematics is about.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1 pb-md-5 pt-md-5 border-bottom" id="science">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine">Science</h3>
          </div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row text-left justify-content-center">
          <div
            class="col-lg-8 col-12 mb-3"
            v-if="departmentData[2]"
            v-html="departmentData[2].description"
          >
            <!-- <p class="pb-2 mt-0">
              We as a team aim to develop the child’s scientific knowledge and
              understanding and to assist in the acquisition and progression of
              analytical skills. We have worked with a Science Technology,
              Engineering and Mathematics consultant (STEM) to design a bespoke
              science curriculum which builds upon and progresses scientific
              skills through the years.
            </p>
            <p class="pb-2 mt-0">
              Children are encouraged to ask scientific questions to deepen
              their own understanding, design experiments and observe the awe
              and wonder of scientific phenomena.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1 pb-md-5 pt-md-5 border-bottom" id="computer">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine">Computing</h3>
          </div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row text-left justify-content-center">
          <div
            class="col-lg-8 col-12 mb-3"
            v-if="departmentData[3]"
            v-html="departmentData[3].description"
          >
            <!-- <p class="pb-2 mt-0">
              Our pupils are taught to use ICT equipment and software in order
              to communicate and handle information. This work supports their
              problem solving, recording and expressive work in a cross
              curricular approach of applying their ICT skills. Children have
              the opportunity to get a head start in a world where everyone is
              turning to digital devices and computers to grow.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="section features-1 pb-md-5 pt-md-5 border-bottom"
      id="socialscience"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine">
              Our World – History, Geography
            </h3>
          </div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row text-left justify-content-center">
          <div
            class="col-lg-8 col-12 mb-3"
            v-if="departmentData[4]"
            v-html="departmentData[4].description"
          >
            <!-- <p class="pb-2 mt-0">
              With-in the programme for Humanities Group (History, Political
              Science and Geography) we aim to help the child in Key Stage One
              to develop an understanding of the past and of the ways in which
              it has built up to the present. We discuss ways in which we can
              find out about the past and set the study of the past in a
              chronological framework. The areas of study include the family and
              the everyday life, famous people in Indian History, Politics and
              past events and local related topics.
            </p>
            <p class="pb-2 mt-0">
              Our work in geography is predominantly concerned with the study of
              places, the people who live in them and the human and physical
              processes which occur in them. Our main aim is to develop
              geographical knowledge and understanding within the children,
              whilst learning about the India and its relationships with other
              countries.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1 pb-md-5 pt-md-5 border-bottom" id="arts">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine">
              The Arts – Art, Music, Design & Technology and Drama
            </h3>
          </div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row text-left justify-content-center">
          <div
            class="col-lg-8 col-12 mb-3"
            v-if="departmentData[5]"
            v-html="departmentData[5].description"
          >
            <!-- <p class="pb-2 mt-0">
              This Departments provides the children with the opportunity to use
              resources and materials in Art and to use multiple techniques in
              practical work. Art enables us to experience different periods,
              cultures and traditions as well as gauging the depth of the work
              of particular individual and showcases their talent.
            </p>
            <p class="pb-2 mt-0">
              We seek to provide the opportunities to all children to play,
              sing, perform and compose music in school. This helps in
              developing the children's ability to listen and identify musical
              elements and structures.
            </p>
            <p class="pb-2 mt-0">
              Under the guidance of learned music teacher all children get
              chance to learn the instrument of their choice. Further
              extracurricular instrument tuition is available to children from
              Class 1 onwards.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1 pb-md-5 pt-md-5 border-bottom" id="health">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine">
              A Healthy Life – Physical Education. Personal, Social & Health
              Education
            </h3>
          </div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row text-left justify-content-center">
          <div
            class="col-lg-8 col-12 mb-3"
            v-if="departmentData[6]"
            v-html="departmentData[6].description"
          >
            <!-- <p class="pb-2 mt-0">
              Children in early years develop their gross motor and fine motor
              skills through everyday activities and sessions. They progress to
              multi-skill lessons where they develop skills such as balance,
              co-ordination, tactical team games and sprite of sport. At Alpine,
              athletics, dance and various inter school competitive sports are
              encouraged, students look-up these to showcase their skills and
              compete to best of their abilities.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="section features-1 pb-md-5 pt-md-5 border-bottom"
      id="sexeducation"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine">
              Sex and Relationships Education
            </h3>
          </div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row text-left justify-content-center">
          <div
            class="col-lg-8 col-12 mb-3"
            v-if="departmentData[7]"
            v-html="departmentData[7].description"
          >
            <!-- <p class="pb-2 mt-0">
              Sex education is taught directly and indirectly through the
              physical and science curriculum to all children at an age
              appropriate level. In upper section the qualified and experienced
              teachers takes the lessons centred around on how human bodies
              work, how body changes as they grow up.
            </p>
            <p class="pb-2 mt-0">
              Relationship education begins from the early years as we teach the
              children how to behave with one another and develop mutual
              respect.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="section features-1 pb-md-5 pt-md-5 border-bottom"
      id="extracurricular"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine">Extra-Curricular Activities</h3>
          </div>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row text-left justify-content-center">
          <div
            class="col-lg-8 col-12 mb-3"
            v-if="departmentData[8]"
            v-html="departmentData[8].description"
          >
            <!-- <p class="pb-2 mt-0">
              Extra-curricular activities take place each week and changes each
              half term to reflect children's interests. These include sports,
              street drama, computer coding (hackathon), art, choir, science and
              engineering, etc. Inter house competition take between houses
              which are
              <strong
                >Himgiri House, Sagar House, Shristi House and Vasundhara
                House</strong
              >.
            </p>
            <p class="pb-2 mt-0">
              Relationship education begins from the early years as we teach the
              children how to behave with one another and develop mutual
              respect.
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  bodyClass: "key-featured",
  components: {},
  data() {
    return {
      departmentData: [],
      auth: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
      authjson: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
    };
  },
  mounted() {
    // read pre-primary api call
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_department",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.departmentData = response.data.details;
        }
      });
  },
};
</script>
<style>
.key_features .info-horizontal {
  max-width: 100% !important;
}
</style>
