<template>
  <div class="section section-hero section-shaped">
    <div class="page-header">
      <img class="bg-image" src="img/ill/index_bg.svg" alt="" />
      <div class="container shape-container d-flex align-items-center py-lg">
        <div class="col px-0">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 text-center">
              <img
                src="img/brand/green.png"
                style="width: 200px;"
                class="img-fluid"
              />
              <badge type="warning">PRO</badge>
              <p class="lead">
                <b class="display-3">All components</b> <br />A beautiful
                Premium Design System for Bootstrap 4.
              </p>
              <div class="btn-wrapper mt-5">
                <base-button
                  tag="a"
                  type="success"
                  class="btn-icon"
                  href="https://www.creative-tim.com/product/vue-argon-design-system-pro"
                  icon="fa fa-shopping-cart"
                >
                  Purchase
                </base-button>
                <base-button
                  tag="a"
                  type="white"
                  class="btn-icon"
                  href="https://www.creative-tim.com/learning-lab/vue/overview/argon-design-system"
                  icon="fa fa-file"
                >
                  Docs
                </base-button>
              </div>
              <div class="mt-5">
                <small class="font-weight-bold mb-0 mr-2"
                  >*proudly coded by</small
                >
                <img
                  src="img/brand/creativetim-black-slim.png"
                  style="height: 28px;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
