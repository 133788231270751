<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/library_bg.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-7 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                An organization is as good<span class="text-light">
                as its leaders.</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <!-- <span class="badge badge-primary badge-pill mb-3">About Us</span> -->
            <h3 class="display-3 text-alpine">Board Of Governers</h3>
            <p class="lead mt-1 font-weight-500 text-dark">
              EDUCATION IS A PROGRESSIVE DISCOVERY OF OUR IGNORANCE
            </p>
          </div>
        </div>
      </div>
      <div class="container mt-4 mb-5" v-for="board_of_governors in board_of_governor" :key="board_of_governors._id" :id="board_of_governors.board_of_governor_id">
        <div class="row text-left justify-content-center">
          <div class="col-lg-3 col-12">
            <img :src="board_of_governors.image" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <div class="col-lg-9 col-12">
            <h4>{{board_of_governors.name}}</h4>
            <h6>{{board_of_governors.designation}}</h6>
            <p class="pb-2 mt-0">
              {{board_of_governors.long_message}}
            </p>
            <!-- <p class="pb-2 mt-2">
              The volume of books, periodicals, digital media material has made the reading range very attractive to the teachers and students. Keeping pace with technology and information the library content is periodically updated.
            </p> -->
          </div>
        </div>
      </div>
      <!-- <div class="container mt-4 mb-5">
        <div class="row text-left justify-content-center">
          <div class="col-lg-3 col-12">
            <img src="img/faces/team-1.jpg" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <div class="col-lg-9 col-12">
            <p class="pb-2 mt-0">
              Our school library offers unending options for learning. It opens up lines for independent thinking and provides genuine research material. The informative literature provides great opportunities for the social, cultural and educational growth of children. The librarian acts as the instructional partner and an information specialist for the students.
            </p>
            <p class="pb-2 mt-2">
              The volume of books, periodicals, digital media material has made the reading range very attractive to the teachers and students. Keeping pace with technology and information the library content is periodically updated.
            </p>
          </div>
        </div>
      </div>
      <div class="container mt-4 mb-5">
        <div class="row text-left justify-content-center">
          <div class="col-lg-3 col-12">
            <img src="img/faces/team-2.jpg" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <div class="col-lg-9 col-12">
            <p class="pb-2 mt-0">
              Our school library offers unending options for learning. It opens up lines for independent thinking and provides genuine research material. The informative literature provides great opportunities for the social, cultural and educational growth of children. The librarian acts as the instructional partner and an information specialist for the students.
            </p>
            <p class="pb-2 mt-2">
              The volume of books, periodicals, digital media material has made the reading range very attractive to the teachers and students. Keeping pace with technology and information the library content is periodically updated.
            </p>
          </div>
        </div>
      </div>
      <div class="container mt-4 mb-5">
        <div class="row text-left justify-content-center">
          <div class="col-lg-3 col-12">
            <img src="img/faces/team-3.jpg" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <div class="col-lg-9 col-12">
            <p class="pb-2 mt-0">
              Our school library offers unending options for learning. It opens up lines for independent thinking and provides genuine research material. The informative literature provides great opportunities for the social, cultural and educational growth of children. The librarian acts as the instructional partner and an information specialist for the students.
            </p>
            <p class="pb-2 mt-2">
              The volume of books, periodicals, digital media material has made the reading range very attractive to the teachers and students. Keeping pace with technology and information the library content is periodically updated.
            </p>
          </div>
        </div>
      </div>
      <div class="container mt-4 mb-5">
        <div class="row text-left justify-content-center">
          <div class="col-lg-3 col-12">
            <img src="img/faces/team-4.jpg" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <div class="col-lg-9 col-12">
            <p class="pb-2 mt-0">
              Our school library offers unending options for learning. It opens up lines for independent thinking and provides genuine research material. The informative literature provides great opportunities for the social, cultural and educational growth of children. The librarian acts as the instructional partner and an information specialist for the students.
            </p>
            <p class="pb-2 mt-2">
              The volume of books, periodicals, digital media material has made the reading range very attractive to the teachers and students. Keeping pace with technology and information the library content is periodically updated.
            </p>
          </div>
        </div>
      </div>
      <div class="container mt-4 mb-5">
        <div class="row text-left justify-content-center">
          <div class="col-lg-3 col-12">
            <img src="img/faces/team-5.jpg" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <div class="col-lg-9 col-12">
            <p class="pb-2 mt-0">
              Our school library offers unending options for learning. It opens up lines for independent thinking and provides genuine research material. The informative literature provides great opportunities for the social, cultural and educational growth of children. The librarian acts as the instructional partner and an information specialist for the students.
            </p>
            <p class="pb-2 mt-2">
              The volume of books, periodicals, digital media material has made the reading range very attractive to the teachers and students. Keeping pace with technology and information the library content is periodically updated.
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  bodyClass: "board_of_governor",
  components: {
  },
  data() {
    return {
      board_of_governor:[],
      index: null,
      auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        authjson : { headers: { 'Content-Type':'application/json',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
    }
  },
  mounted () {

          axios
            .post(process.env.VUE_APP_API_ENDPOINT+'read_board_of_governor',{ "details" :{},"offset":"0"},this.authjson)
            .then(response => {
                console.log(response);
                if(response.data.details){
                this.board_of_governor=response.data.details;
                }
            })
  },
};
</script>
<style>
.key_features  .info-horizontal {
  max-width: 100% !important;
}
</style>
