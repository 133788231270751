<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/library_bg.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-6 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                Books are a uniquely<span class="text-light">
                portable magic.</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <!-- <span class="badge badge-primary badge-pill mb-3">About Us</span> -->
            <h3 class="display-3">LIBRARY</h3>
            <p class="lead mt-1 font-weight-500 text-dark">
              EDUCATION IS A PROGRESSIVE DISCOVERY OF OUR IGNORANCE
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-4">
          <div class="row align-items-center text-left justify-content-center">
            <div class="col-lg-10 col-12">
              <img src="img/library.jpeg" class="img-fluid float-left p-1 mb-2 mr-4 shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
              <p class="pb-2 mt-0">
                Our school library offers unending options for learning. It opens up lines for independent thinking and provides genuine research material. The informative literature provides great opportunities for the social, cultural and educational growth of children. The librarian acts as the instructional partner and an information specialist for the students.
              </p>
              <p class="pb-2 mt-2">
                The volume of books, periodicals, digital media material has made the reading range very attractive to the teachers and students. Keeping pace with technology and information the library content is periodically updated. The wisdom of years is contained in the books. Our mind becomes truly alive as we perceive the spirit of humanity through the written word. The great authors, our wordsmiths give us dreams and hope for the future.
              </p>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>


export default {
  bodyClass: "key_features",
  components: {
  }
};
</script>
<style>
.key_features  .info-horizontal {
  max-width: 100% !important;
}
</style>
