<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/mission_vision.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-6 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                <span class="text-light">GLIMPSES OF</span> OUR CAMPUS
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <!-- <span class="badge badge-primary badge-pill mb-3">About Us</span> -->
            <h3 class="display-3">OUR CAMPUS</h3>
            <p class="lead mt-1 text-dark font-weight-500">
              Our campus provides the shelter for a scholar’s soul to take refuge in.
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-4">
          <div class="row align-items-center text-left justify-content-center">
            <div class="col-md-3">
              <img src="img/school_bul.jpeg" class="img-fluid p-1 mb-2 shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
              <img src="img/school.jpeg" class="img-fluid p-1 mb-2 shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
            </div>
            <div class="col-lg-7 col-12">
              <p class="pb-2 mt-0">
                Our campus provides the shelter for a scholar's soul to take refuge in. It allows for broadening of theoretical knowledge as well as widening of the mental horizons. A human mind when broadened certainly does not go back to its original shape.
              </p>
              <p class="pb-2 mt-2">
                The campus construction is airy and spacious. The ambience is serene as the eye takes in details of cool pastel shades and the mind registers a sense of tranquillity. 
              </p>
              <p class="pb-2 mt-2">
                The sprawling campus has state of art facilities replete with a modern day library, big classrooms, well equipped laboratories, impressive staff rooms and offices. Our ground is engineered with high-quality material allowing players to deliver an outstanding performance. It is great fun watching the evening practice sessions of games. 
              </p>
              <p class="pb-2 mt-2">
                Our play ground is action packed where our children develop physical coordination, strength and flexibility. The campus is dotted with beautiful green open spaces that are soothing to the eyes and serve as a boon for the ecological system.
              </p>
            </div>
          </div>
        </div>
    </div>
    <div class="section features-1 border-top" id="classes">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <!-- <span class="badge badge-primary badge-pill mb-3">About Us</span> -->
            <h3 class="display-3">Class Rooms</h3>
            <!-- <p class="lead mt-1 text-dark font-weight-500">
              Our campus provides the shelter for a scholar’s soul to take refuge in.
            </p> -->
          </div>
        </div>
      </div>
      <div class="container-fluid mt-4">
          <div class="row align-items-center text-left justify-content-center">
            <div class="col-lg-7 col-12">
              <p class="pb-2 mt-0">
                The main task of any school is to provide education which involves a series of programmes and activities. The successful conduct of these programmes and activities depend mainly upon the availability of proper infrastructure in the school. 
              </p>
              <p class="pb-2 mt-2">
                The school building of Alpine is well planned – spaciously, functionally and with pleasing architectural features. School have an adequate number of well maintained & well-furnished classrooms and every classroom gives a pleasant look which further helps as an aid for making the learning much more enjoyable. 
              </p>
            </div>
            <div class="col-md-3">
              <img src="img/classes.png" class="img-fluid p-1 mb-2 shadow rounded" v-animate-onscroll="'animated fadeInRight'">
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { Glide, GlideSlide } from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";

export default {
  bodyClass: "mission_vision",
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  }
};
</script>
<style>
.glide__slides {
  margin-left: 50%;
}
</style>
