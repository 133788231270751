<template>
  <div class="index-page">
    <hero></hero>
    <basic-elements></basic-elements>
    <inputs></inputs>
    <elements></elements>
    <spinners></spinners>
    <custom-controls></custom-controls>
    <navigation></navigation>
    <tabs></tabs>
    <progress-pagination></progress-pagination>
    <labels></labels>
    <alerts></alerts>
    <footer-areas></footer-areas>
    <typography></typography>
    <content-areas></content-areas>
    <cards></cards>
    <javascript-components></javascript-components>
    <carousel></carousel>
    <nucleo-icons></nucleo-icons>
  </div>
</template>
<script>
import Hero from "./components/Hero";
import BasicElements from "./components/BasicElements";
import Inputs from "./components/Inputs";
import Elements from "./components/Elements";
import Spinners from "./components/Spinners";
import CustomControls from "./components/CustomControls";
import Navigation from "./components/Navigation";
import Tabs from "./components/Tabs";
import ProgressPagination from "./components/ProgressPagination";
import Labels from "./components/Labels";
import Alerts from "./components/Alerts";
import FooterAreas from "./components/FooterAreas";
import Typography from "./components/Typography";
import Cards from "./components/Cards";
import JavascriptComponents from "./components/JavascriptComponents";
import Carousel from "./components/Carousel";
import NucleoIcons from "./components/NucleoIcons";
import ContentAreas from "./components/ContentAreas";

export default {
  name: "components",
  components: {
    Hero,
    BasicElements,
    Inputs,
    Elements,
    Spinners,
    CustomControls,
    Navigation,
    Tabs,
    ProgressPagination,
    Labels,
    Alerts,
    FooterAreas,
    Typography,
    Cards,
    JavascriptComponents,
    Carousel,
    NucleoIcons,
    ContentAreas
  }
};
</script>
