<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/mission_vision.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-6 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                Education is the key<span class="text-light">
                  to unlock the golden door of freedom.</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <!-- <span class="badge badge-primary badge-pill mb-3">About Us</span> -->
            <h3 class="display-3">OUR MISSION</h3>
            <p class="lead mt-1">
              EDUCATION IS A PROGRESSIVE DISCOVERY OF OUR IGNORANCE
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-4">
          <div class="row align-items-center text-left justify-content-center">
            <div class="col-lg-10 col-12">
              <img src="img/school_bul.jpeg" class="img-fluid float-left p-1 mb-2 mr-4 shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
              <p class="lead pb-2 mt-0">
                Education is a continuous process goes on throughout life. We believe in inculcating learning passion in each component of school whether they are students, parents or teachers. Learning attains accomplishment only if it becomes a joy, a passion, a life style and a collective venture. Our resolution is to create lifelong learners who may illuminate society nation and whole humanity.
              </p>
              <p class="lead pb-2 mt-2">
                Education is a continuous process goes on throughout life. We believe in inculcating learning passion in each component of school whether they are students, parents or teachers. Our resolution is to create lifelong learners who may illuminate society nation and whole humanity. Learning attains accomplishment only if it becomes a joy, a passion, a life style and a collective venture. </p>
            </div>
          </div>
        </div>
    </div>
    <div class="section features-1 bg-secondary border-top border-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <!-- <span class="badge badge-primary badge-pill mb-3">About Us</span> -->
            <h3 class="display-3">VISION & PHILOSOPHY</h3>
            <p class="lead mt-1">
              ENABLING EVERY INDIVIDUAL TO BE VALUED AND VALUABLE IN THEIR OWN RIGHTS.
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-4">
          <div class="row align-items-center text-left justify-content-center">
            <div class="col-lg-10 col-12">
              <img src="img/school_bul.jpeg" class="img-fluid float-right p-1 mb-2 ml-4 shadow rounded" v-animate-onscroll="'animated fadeInRight'">
              <p class="lead pb-2 mt-0">
                We, at Alpine Public School Khurja aim to nurture our student into physically fit mentally strong and emotionally balanced individuals who will excel as global citizens of tomorrow. For this purpose, a healthy and congenial environment is created to exploit the talent, lying latent. All endeavours are done to unlock the creative potential of young inquisitive minds. <p>
              <p class="lead pb-2 mt-0">We wish to infuse in each child courage and capacity to fabricate their own dreams the determination to turn them into reality and the confidence to face all challenges that life may put before them.
              </p>
              <p class="lead pb-2 mt-2">
                Much has changed in past few years as we have progressed and bounds. Our Students are making a mark in all fields. Still great accolades are to be won in the years to come, not only in academics but in all fields. We are emphatic that to attain this we have to maintain very high standard of ethical, moral and spiritual values for 'expedients are for the hour, best principles are for the ages'. </p>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>


export default {
  bodyClass: "mission_vision",
  components: {
  }
};
</script>
<style></style>
