<template>
  <div class="wrapper">
    <div class="main-slides h-100vh" style="background: url(img/about_bg.jpg); background-size: cover;">
      <div class="page-header">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-6 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 text-light">
                Alpine Public School,<span class="text-light">
                  Eliminating Limits.</span
                >
              </h1>
              <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child. We are aware of the many challenges children face today and therefore we try to impart a 'value based' education that will help our children to face life with confidence.
              </p>
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <Feature></Feature>
    <Blog></Blog>
    <Pricing></Pricing>
    <Team></Team>
  </div>
</template>
<script>
import Feature from "./sections/Features/Feature1.vue";
import Blog from "./sections/Blogs/Blog6.vue";
import Pricing from "./sections/Pricing/Pricing5.vue";
import Team from "./sections/Teams/Team1.vue";

export default {
  bodyClass: "about-us",
  components: {
    Feature,
    Blog,
    Pricing,
    Team
  }
};
</script>
<style></style>
