<template>
  <div class="wrapper">
    <div
      class="main-slides h-70vh background-cover"
      style="background: url(img/beyond_academics.jpg); background-size: cover;"
    >
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div
              class="col-lg-8 col-12"
              v-animate-onscroll="{
                down: 'animated fadeOutDown',
                up: 'animated fadeInDown',
              }"
            >
              <h1 class="display-2 mt-8 text-light">
                <span class="text-light">We give equal emphasis to</span>
                beyond academic activities.
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sports -->
    <div class="section features-4">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3 text-alpine">🎾 ⚽ Sports 🏏 🏀</h3>
            <p class="lead font-weight-500 text-dark">
              {{ beyond_academic.heading }}
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-5">
            <div class="row">
              <div class="col-md-12" v-animate-onscroll="'animated zoomIn'">
                {{ beyond_academic.left_description }}
                <!-- <p class="">
                  The school fully believes in this maxim. Hence ample resources
                  have been made available for the physical fitness of the
                  students. Apart from a sprawling campus and facilities for
                  various sports and games are available in the school. Apart
                  from outdoor games like field events, cricket, football,
                  volleyball, kho-kho, badminton and Hand-ball, there are
                  facilities of indoor games like chess, table tennis, carrom
                  and many more games and activities, so that the growing up
                  process is not only comprehensive but also enjoyable
                </p>
                <p class="">
                  .We at APS firmly believe that it is through sports that a
                  young, impressionable child can learn values like discipline,
                  responsibility, self-confidence, sacrifice, and
                  accountability. We have sports activities so that students
                  from all age groups can enjoy, relax and exercise all at the
                  same time.
                </p>
                <p class="">
                  The students are made to play games of their choice under the
                  constant monitoring and guidance of the best coaches in the
                  region.
                </p> -->
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="row">
              <div class="col-md-12">
                <img
                  src="img/cricket.png"
                  class="img-fluid p-md-4 col-4 col-lg-12 col-sm-4"
                  v-animate-onscroll="'animated fadeInDown'"
                />
                <img
                  src="img/shuttlecock.png"
                  class="img-fluid p-md-4 col-4 col-lg-12 col-sm-4"
                  v-animate-onscroll="'animated fadeInDown'"
                />
                <img
                  src="img/football.png"
                  class="img-fluid p-md-4 col-4 col-lg-12 col-sm-4"
                  v-animate-onscroll="'animated fadeInDown'"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="row">
              <div class="col-md-12" v-animate-onscroll="'animated zoomIn'">
                {{ beyond_academic.right_description }}
                <!-- <p class="">
                  The students have represented the school in Interschool, CBSE
                  and State level events and tournaments and brought laurels to
                  the school.
                </p>
                <p class="">
                  The coaches take due care of the safety and security of the
                  students at all times. They help the students to hone their
                  skills in the game(s) of their choice. Moreover, they equip
                  the students with effective techniques and moves that help
                  them stand apart.
                </p>
                <p class="">
                  The school makes undaunted efforts to provide sports
                  facilities exclusively for thrill and excitement of Sports
                  Competitions, thus enabling the students to grow socially,
                  emotionally and physically.
                </p>
                <p class="">
                  The accomplishment of this idea is reflected in our young
                  sports stars who have excelled at the State level competitions
                  year after year.
                </p>
                <p class="">
                  There are multiple sport facilities to keep our students
                  engaged and physically fit.
                </p> -->
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-3">
            <!-- <div class="row">
              <div class="col-md-3">
                <card class="card-blog card-background" data-animation="zooming">
                  <div
                    class="full-background"
                    style="background-image: url('img/sports1.jpg')"
                  ></div>
                  <template slot="body">
                    <div class="content-center my-5">
                      <div class="h3 card-category text-white opacity-8">
                        Cricket
                      </div>
                    </div>
                  </template>
                </card>
              </div>
              <div class="col-md-3">
                <card class="card-blog card-background" data-animation="zooming">
                  <div
                    class="full-background"
                    style="background-image: url('img/sports2.jpg')"
                  ></div>
                  <template slot="body">
                    <div class="content-center my-5">
                      <div class="h3 card-category text-white opacity-8">
                        Football
                      </div>
                    </div>
                  </template>
                </card>
              </div>
              <div class="col-md-3">
                <card class="card-blog card-background" data-animation="zooming">
                  <div
                    class="full-background"
                    style="background-image: url('img/sports3.jpg')"
                  ></div>
                  <template slot="body">
                    <div class="content-center my-5">
                      <div class="h3 card-category text-white opacity-8">
                        Athletics
                      </div>
                    </div>
                  </template>
                </card>
              </div>
              <div class="col-md-3">
                <card class="card-blog card-background" data-animation="zooming">
                  <div
                    class="full-background"
                    style="background-image: url('img/sports4.jpg')"
                  ></div>
                  <template slot="body">
                    <div class="content-center my-5">
                      <div class="h3 card-category text-white opacity-8">
                        Tennis
                      </div>
                    </div>
                  </template>
                </card>
              </div>
            </div> -->
            <div class="row align-items-center">
              <div class="col-lg-12 mt-md-4">
                <div class="row">
                  <div
                    class="col-md-2 col-4"
                    v-for="sports in beyond_academic.sports"
                    :key="sports._id"
                  >
                    <card
                      class="card-blog card-background card-circle"
                      data-animation="zooming"
                      bodyClasses="px-2 px-md-3"
                    >
                      <div
                        class="full-background"
                        :style="'background-image: url(' + sports.image + ')'"
                      ></div>
                      <template slot="body">
                        <div class="content-center my-md-4 my-3 pt-1">
                          <div
                            class="h5 card-category text-white opacity-8 mt-0 mt-md-2"
                          >
                            {{ sports.name }}
                          </div>
                        </div>
                      </template>
                    </card>
                  </div>
                  <!-- <div class="col-md-2 col-4">
                    <card
                      class="card-blog card-background card-circle"
                      data-animation="zooming"
                      bodyClasses="px-2 px-md-3"
                    >
                      <div
                        class="full-background"
                        style="background-image: url('img/sports2.jpg')"
                      ></div>
                      <template slot="body">
                        <div class="content-center my-md-4 my-3 pt-1">
                          <div
                            class="h5 card-category text-white opacity-8 mt-0 mt-md-2"
                          >
                            Football
                          </div>
                        </div>
                      </template>
                    </card>
                  </div>
                  <div class="col-md-2 col-4">
                    <card
                      class="card-blog card-background card-circle"
                      data-animation="zooming"
                      bodyClasses="px-2 px-md-3"
                    >
                      <div
                        class="full-background"
                        style="background-image: url('img/sports3.jpg')"
                      ></div>
                      <template slot="body">
                        <div class="content-center my-md-4 my-3 pt-1">
                          <div
                            class="h5 card-category text-white opacity-8 mt-0 mt-md-2"
                          >
                            Athletics
                          </div>
                        </div>
                      </template>
                    </card>
                  </div>
                  <div class="col-md-2 col-4">
                    <card
                      class="card-blog card-background card-circle"
                      data-animation="zooming"
                      bodyClasses="px-2 px-md-3"
                    >
                      <div
                        class="full-background"
                        style="background-image: url('img/sports4.jpg')"
                      ></div>
                      <template slot="body">
                        <div class="content-center my-md-4 my-3 pt-1">
                          <div
                            class="h5 card-category text-white opacity-8 mt-0 mt-md-2"
                          >
                            Tennis
                          </div>
                        </div>
                      </template>
                    </card>
                  </div>
                  <div class="col-md-2 col-4">
                    <card
                      class="card-blog card-background card-circle"
                      data-animation="zooming"
                      bodyClasses="px-2 px-md-3"
                    >
                      <div
                        class="full-background"
                        style="background-image: url('img/badminton_1.webp')"
                      ></div>
                      <template slot="body">
                        <div class="content-center my-md-4 my-3 pt-1">
                          <div
                            class="h5 card-category text-white opacity-8 mt-0 mt-md-2"
                          >
                            Badminton
                          </div>
                        </div>
                      </template>
                    </card>
                  </div>
                  <div class="col-md-2 col-4">
                    <card
                      class="card-blog card-background card-circle"
                      data-animation="zooming"
                      bodyClasses="px-2 px-md-3"
                    >
                      <div
                        class="full-background"
                        style="background-image: url('img/tt.webp')"
                      ></div>
                      <template slot="body">
                        <div class="content-center my-md-4 my-3 pt-1">
                          <div
                            class="h5 card-category text-white opacity-8 mt-0 mt-md-2"
                          >
                            Table Tennis
                          </div>
                        </div>
                      </template>
                    </card>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sport details -->
    <div
      class="section content-slider testimonials-2 text-dark bg-secondary border-top border-bottom"
      id="sports-details"
    >
      <div class="container">
        <div class="row">
          <b-carousel
            id="sports-carousel"
            height="80vh"
            class="carousel-testimonials"
            ref="carouselsports"
            controls
          >
            <b-carousel-slide
              class="carousel-inner"
              v-for="sports_slider in beyond_academic.sports_slider"
              :key="sports_slider._id"
            >
              <div class="row text-dark text-justify">
                <div class="col-md-8 col-12 col-sm-8 mx-auto px-3">
                  <h3 class="display-3 text-alpine">
                    {{ sports_slider.name }}
                  </h3>
                  {{ sports_slider.description }}
                  <!-- <p class="">
                    A religion to most people in India than a sport and for the
                    students of Alpine Public School is no different.
                  </p>
                  <p class="">
                    The infrastructure related to the game includes a cricket
                    ground with natural turf pitches and synthetic practice
                    pitches.
                  </p>
                  <p class="">
                    As sports are paramount for students, we provide our
                    students with state of the art facilities with ample space
                    and abundant equipment.
                  </p>
                  <p class="">
                    We have one cricket ground including a batting cage. Our
                    School has a long tradition of cricket excellence and has
                    seen a number of its students progress to play for the state
                    level.
                  </p> -->
                </div>
                <div class="col-lg-4 col-12 col-sm-4 ml-auto text-left mt-4">
                  <img
                    :src="sports_slider.image"
                    class="img-fluid shadow-sm mt-md-4 p-2 rounded border bg-white"
                  />
                </div>
              </div>
            </b-carousel-slide>
            <!-- <b-carousel-slide class="carousel-inner">
              <div class="row text-dark text-justify">
                <div class="col-md-8 col-12 col-sm-8 mx-auto px-3">
                  <h3 class="display-3 text-alpine">Table Tennis</h3>
                  <p class="">
                    Being one of the most popular sports in school, table tennis
                    is played by a large number of students during the school
                    hours. We have a dedicated table tennis coach and a
                    well-equipped sports department to help the budding players
                    in every way they can.
                  </p>
                  <p class="">
                    Tennis offers students the chance to learn valuable life
                    skills in a fun and encouraging environment and our school
                    caters to their needs by providing the necessary
                    infrastructure to enable more participation from all.
                  </p>
                </div>
                <div class="col-lg-4 col-12 col-sm-4 ml-auto text-left mt-4">
                  <img
                    src="img/tt.webp"
                    class="img-fluid shadow-sm mt-md-2 p-2 rounded border bg-white"
                  />
                </div>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carousel-inner">
              <div class="row text-dark text-justify">
                <div class="col-md-8 col-12 col-sm-8 mx-auto px-3">
                  <h3 class="display-3 text-alpine">Yoga and Aerobics</h3>
                  <p class="">
                    Walk through the lanes of the school premises and you may
                    encounter some students stretching their bodies and
                    relaxing. And this can come only through a regularized
                    routine for Yoga and Aerobics. Not only has it developed
                    their physical ability and vitality but has also helped them
                    channelize their energy and temper them down.
                  </p>
                  <p class="">
                    At Alpine, we understand the importance of yoga, thus host
                    classes of Yoga for the students. A team of qualified yoga
                    trainers imparts basic yoga knowledge to our students.
                    Having a dedicated yoga room, an allotted time in the time
                    table, yoga curriculum and experienced faculty, we take yoga
                    training seriously.
                  </p>
                </div>
                <div class="col-lg-4 col-12 col-sm-4 ml-auto text-left mt-4">
                  <img
                    src="img/yoga.webp"
                    class="img-fluid shadow-sm p-2 rounded border bg-white"
                  />
                </div>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carousel-inner">
              <div class="row text-dark text-justify">
                <div class="col-md-8 col-12 col-sm-8 mx-auto px-3">
                  <h3 class="display-3 text-alpine">Badminton</h3>
                  <p class="">
                    Badminton is more fun, engaging, and fulfilling rather than
                    computer games and so we nurture playing badminton at
                    Alpine. We emphasize physical training elements which
                    include – badminton drills, running activities, skipping,
                    badminton theory lessons, and sports psychology.
                  </p>
                  <p class="">
                    Our coaches assess the current level of the students, their
                    ability and their goals and train them to achieve their
                    attainable levels so that tomorrow they make it to the
                    internationals.
                  </p>
                </div>
                <div class="col-lg-4 col-12 col-sm-4 ml-auto text-left mt-4">
                  <img
                    src="img/badminton_2.webp"
                    class="img-fluid shadow-sm p-2 rounded border bg-white"
                  />
                </div>
              </div>
            </b-carousel-slide> -->
            <!-- <a
              class="carousel-control-prev text-primary"
              v-on:click="prevsports"
              role="button"
              data-slide="prevsports"
            >
              <i class="ni ni-bold-left"></i>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next text-primary"
              v-on:click="nextsports"
              role="button"
              data-slide="nextsports"
            >
              <i class="ni ni-bold-right"></i>
              <span class="sr-only">Next</span>
            </a> -->
          </b-carousel>
        </div>
      </div>
    </div>
    <!-- Creativity -->
    <div class="section team-4" id="creativity">
      <div class="container">
        <div class="row mb-2">
          <div class="col-md-10 mx-auto text-center">
            <h3 class="display-3 text-alpine">
              Creativity (Art & Craft, Music and Dance)
            </h3>
            <p class="lead font-weight-500 text-dark mt-1">
              {{ beyond_academic.creativity_short_description }}
              <!-- Alpine School has carefully designed its infrastructure to meet
              the needs of learners and educators. -->
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="row py-4">
              <div class="col-lg-9" v-if="beyond_academic.creativities">
                <h5 class="">{{ beyond_academic.creativities[0].name }}</h5>
                {{ beyond_academic.creativities[0].description }}
                <!-- <p class="text-justify mt-0">
                  At Alpine we consider ART and MUSIC as fundamental to growth.
                  We give students Canvas and they always leave us spellbound
                  their beautiful imagination. Child’s culture is multifarious
                  and pluralistic, displaying layers of subcultures. We have
                  recognized that the arts contribute significantly to promoting
                  child’s wellbeing. Symbolic self-expression through the arts
                  allows them to express complex feelings.
                </p>
                <p class="text-justify">
                  Experience in art and craft activities enables the child to
                  reflect critically on her own work. She learns to behave like
                  an artist and build their reflection.
                </p>
                <p class="text-justify mt-0">
                  A wide variety of activities are planned and executed by
                  Alpine on frequent basis like ”Best out of waste, Sketching,
                  Painting, Cartooning, etc”. It enhances their dexterity and
                  agility. Apart from the classroom activities these types of
                  activities are also cherished by the students.
                </p> -->
              </div>
              <div class="col-lg-3">
                <img
                  :src="beyond_academic.creativities[0].image"
                  alt="SAGAR HOUSE"
                  class="img-fluid shadow rounded-xl p-2 bg-white"
                />
                <img
                  src="img/art2.jpg"
                  alt="SAGAR HOUSE"
                  class="img-fluid shadow rounded-xl p-2 bg-white mt-2"
                />
              </div>
            </div>
            <div class="row py-4 border-top">
              <div class="col-lg-9">
                <h5 class="">{{ beyond_academic.creativities[1].name }}</h5>
                <p class="text-justify mt-0">
                  {{ beyond_academic.creativities[1].description }}
                  <!-- Developing skills in music analysis, basic composition,
                  performing instrument, contemporary music notation are few for
                  starters. knowledge and understanding of the history of music,
                  cultivating deeper understanding and appreciation for a wide
                  range of music. -->
                </p>
              </div>
              <div class="col-lg-3">
                <img
                  :src="beyond_academic.creativities[1].image"
                  alt="SAGAR HOUSE"
                  class="img-fluid shadow rounded-xl p-2 bg-white"
                />
              </div>
            </div>
            <div class="row py-4 border-top">
              <div class="col-lg-9">
                <h5 class="">{{ beyond_academic.creativities[2].name }}</h5>
                <p class="text-justify mt-0">
                  {{ beyond_academic.creativities[2].description }}
                  <!-- Dance is the hidden language of the soul. Dancers don't need
                  wings to fly. Fitness and fun go hand in hand when it's about
                  dance. Alpine frequently organizes dance activities to give
                  the students a creative outlet for expressing emotions. Dance
                  activities help in increasing self-esteem and self- confidence
                  in a child. Students love to participate and give their power
                  pack performances. Dance helps them to release stress and
                  channelize their energy positively. -->
                </p>
              </div>
              <div class="col-lg-3">
                <img
                  :src="beyond_academic.creativities[2].image"
                  alt="SAGAR HOUSE"
                  class="img-fluid shadow rounded-xl p-2 bg-white"
                />
              </div>
            </div>
            <div class="row py-4 border-top">
              <div class="col-lg-9">
                <h5 class="">{{ beyond_academic.creativities[3].name }}</h5>
                <p class="text-justify mt-0">
                  {{ beyond_academic.creativities[3].description }}
                  <!-- To introduce our students to the world of performing arts we
                  have regular workshops to involve and engage the child.
                  Theatre thrives at Alpine. Through dramatics, Alpine forges a
                  shared sense of purpose and become an indissoluble team as
                  they work together to mount a production. Theatre is about
                  much more than just story telling; and, it is also about much
                  more than a celebration of the self and one's own abilities.
                  To paraphrase Sophocles dramatics reflects a spirit moving
                  through a community, both in the audience and onstage. -->
                </p>
              </div>
              <div class="col-lg-3">
                <img
                  :src="beyond_academic.creativities[3].image"
                  alt="SAGAR HOUSE"
                  class="img-fluid shadow rounded-xl p-2 bg-white"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- houses -->
    <div class="section project-1 pt-3 bg-image" id="houses">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mx-auto text-center my-5">
            <h3 class="display-3 text-white">Houses @ Alpine</h3>

            <p class="text-justify font-weight-500 text-white">
              {{ beyond_academic.houses_short_decription }}
            </p>
            <!-- <p class="text-justify font-weight-500 text-white">
              Thus, providing a focus for group royalty. The House go by the
              name Himgiri, Sagar, Srishti and Vasundhara . A school house
              consist of students from different grades, and age groups. The
              Interhouse events provide an environment where the student learns
              to respect their peers, start observing the advantage of a group
              and develop a sense of togetherness while working together with
              different people to achieve a common goal.
            </p> -->
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-6 col-md-6"
            v-for="houses in beyond_academic.houses"
            :key="houses._id"
          >
            <card headerClasses="mt-2" bodyClasses="text-center p-4">
              <template slot="body">
                <a href="javascript:;">
                  <img
                    :src="houses.image"
                    alt="SAGAR HOUSE"
                    class="img-fluid shadow rounded-xl"
                  />
                </a>
                <h4 class="card-title mt-3 mb-0">{{ houses.name }}</h4>
                <p class="card-description">
                  {{ houses.description }}
                  <!-- 'Unity and Strength' Sagar house always proceeds towards
                  progress. The students of Sagar House have always strived for
                  excellence with great determination, will, respect, compassion
                  and most importantly modesty. -->
                </p>
              </template>
            </card>
          </div>

          <!-- <div class="col-lg-6 col-md-6">
            <card headerClasses="mt-2" bodyClasses="text-center p-4">
              <template slot="body">
                <a href="javascript:;">
                  <img
                    src="img/himgiri.jpg"
                    alt="Circle image"
                    class="img-fluid shadow rounded-xl"
                  />
                </a>
                <h4 class="card-title mt-3 mb-0">HIMGIRI HOUSE</h4>
                <p class="card-description">
                  “Sky is the limit”, the members of the house always endeavour
                  to excel in every pursuit associated with the character
                  building, stamina, zeal and enthusiasm to attain heights in
                  each field of learning.
                </p>
              </template>
            </card>
          </div>

          <div class="col-lg-6 col-md-6">
            <card headerClasses="mt-2" bodyClasses="text-center p-4">
              <template slot="body">
                <a href="javascript:;">
                  <img
                    src="img/vasundhara.jpg"
                    alt="Circle image"
                    class="img-fluid shadow rounded-xl"
                  />
                </a>
                <h4 class="card-title mt-3 mb-0">VASUNDHARA HOUSE</h4>
                <p class="card-description">
                  “Nothing is Impossible”. Discipline, diligence and cooperation
                  are the hallmarks of Vasundhara house, which beckons us to
                  excel in every competition.
                </p>
              </template>
            </card>
          </div>

          <div class="col-lg-6 col-md-6">
            <card headerClasses="mt-2" bodyClasses="text-center p-4">
              <template slot="body">
                <a href="javascript:;">
                  <img
                    src="img/srishti.jpg"
                    alt="Circle image"
                    class="img-fluid shadow rounded-xl"
                  />
                </a>
                <h4 class="card-title mt-3 mb-0">SRISHTI HOUSE</h4>
                <p class="card-description">
                  “One Team One Dream”, The Srishti House takes great pride in
                  working for their ‘Kingdom’ and have emerged the winners of
                  the much-coveted cup for ‘Best House’, many a time.
                </p>
              </template>
            </card>
          </div> -->
        </div>
      </div>
    </div>
    <!-- trips -->
    <div class="section projects-3 bg-secondary pb-1 border-bottom" id="trips">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mr-auto ml-auto text-center">
            <h3 class="display-3 text-alpine">Trips 🏝️</h3>
            <p class="mt-1 mb-5 font-weight-500 text-dark text-justify">
              {{ beyond_academic.trips_short_description }}
              <!-- It’s better to see something once than to hear about it 1000
              times. A field trip or excursion is a journey by a group of
              students to a place away from their normal environment. The
              purpose of the trip is varying from education, non-experimental
              research to provide students with the experiences outside their
              everyday environment such as going camping. Field trips are most
              often done in two steps: preparation activities and follow-up
              activities. Various task is performed so that every child gets to
              take part. The end product if any is taken back by the child as a
              memento. Kids are provided with delicious lunch which they savor
              and enjoy. It is an enriching and fun experience for all the
              students. -->
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6" v-for="trip in trips" :key="trip._id">
            <card headerClasses="mt-2" bodyClasses="text-center p-4">
              <template slot="body">
                <div class="">
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                    v-for="(image, index) in trip.images"
                    :key="'g' + index"
                  >
                    <img
                      alt="Image placeholder"
                      class="rounded centered-crop"
                      :src="image"
                    />
                  </a>
                  <!--  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="/img/video_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="/img/results_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="/img/sports4.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="/img/council.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="/img/sports1.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="/img/sports3.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="/img//sports2.jpg" />
                  </a> -->
                </div>
                <h5 class="card-title mb-0 mt-3">{{ trip.year }}</h5>
                <h6 class="card-title mb-0">
                  <router-link :to="'/gallery/' + trip.year">View</router-link>
                </h6>
              </template>
            </card>
          </div>
          <!-- <div class="col-lg-4 col-md-6">
            <card headerClasses="mt-2" bodyClasses="text-center p-4">
              <template slot="body">
                <div class="">
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/gallery_2.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/video_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/results_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports4.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/council.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports1.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports3.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img//sports2.jpg" />
                  </a>
                </div>
                <h5 class="card-title mb-0 mt-3">2015</h5>
                <h6 class="card-title mb-0"><router-link to="/gallery">View</router-link></h6>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card headerClasses="mt-2" bodyClasses="text-center p-4">
              <template slot="body">
                <div class="">
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/gallery_2.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/video_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/results_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports4.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/council.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports1.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports3.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img//sports2.jpg" />
                  </a>
                </div>
                <h5 class="card-title mb-0 mt-3">2016</h5>
                <h6 class="card-title mb-0"><router-link to="/gallery">View</router-link></h6>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card headerClasses="mt-2" bodyClasses="text-center p-4">
              <template slot="body">
                <div class="">
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/gallery_2.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/video_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/results_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports4.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/council.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports1.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports3.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img//sports2.jpg" />
                  </a>
                </div>
                <h5 class="card-title mb-0 mt-3">2017</h5>
                <h6 class="card-title mb-0"><router-link to="/gallery">View</router-link></h6>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card headerClasses="mt-2" bodyClasses="text-center p-4">
              <template slot="body">
                <div class="">
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/gallery_2.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/video_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/results_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports4.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/council.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports1.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports3.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img//sports2.jpg" />
                  </a>
                </div>
                <h5 class="card-title mb-0 mt-3">2018</h5>
                <h6 class="card-title mb-0"><router-link to="/gallery">View</router-link></h6>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card headerClasses="mt-2" bodyClasses="text-center p-4">
              <template slot="body">
                <div class="">
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/gallery_2.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/video_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/results_bg.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports4.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Ryan Tompson"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/council.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Romina Hadid"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports1.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Alexander Smith"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img/sports3.jpg" />
                  </a>
                  <a
                    href="javascript:;"
                    class="avatar avatar-lg shadow-lg mx-1"
                    title="Jessica Doe"
                  >
                    <img alt="Image placeholder" class="rounded centered-crop" src="img//sports2.jpg" />
                  </a>
                </div>
                <h5 class="card-title mb-0 mt-3">2019</h5>
                <h6 class="card-title mb-0"><router-link to="/gallery">View</router-link></h6>
              </template>
            </card>
          </div> -->
        </div>
      </div>
      <div class="train mt-3">
        <img src="/img/train.png" class="img-fluid" />
      </div>
    </div>
    <!-- covid safety -->
    <div class="section py-7 bg-image">
      <div class="container text-white">
        <div class="row mb-2">
          <div class="col-md-10 mx-auto text-center">
            <h3 class="display-3 text-white">COVID Safety</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            {{ beyond_academic.COVID_Safety_description }}
            <!-- <p class="text-justify mt-0">
              Covid-19 pandemic has touched everyone’s life one way or another.
              These unprecedented times have taught us and bought us together.
              As situation is starting to look up, wait would be finally over,
              we are preparing to open the doors for our students to come and
              experience learning first-hand.
            </p>
            <p class="text-justify">
              Following all the safety guidelines and protocols advised by the
              Government of India and the Ministry of Education, we have put in
              an extensive system in safeguarding and sanitizing the school
              campus.
            </p>
            <p class="text-justify mt-0">
              Furthermore, we have made sure that all of the staff at the school
              are regularly tested and maintain a high standard of hygiene. We
              are dedicated to make learning in schools the new normal again.
            </p> -->
          </div>
          <div class="col-lg-4">
            <img
              :src="beyond_academic.COVID_Safety_image"
              class="p-2 rounded-xl img-fluid bg-white"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- events -->
    <div class="section team-4" id="event">
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-10 mx-auto text-center">
            <h3 class="display-3 ">Events</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6" v-for="event in events" :key="event._id">
            <h5 class="text-center">{{ event.event_name }}</h5>
            <p
              class="text-justify mt-0"
              v-html="event.event_long_description"
            ></p>
            <div class="row">
              <div class="col-md-12 mb-3">
                <template>
                  <div id="app">
                    <CoolLightBox
                      :items="event.images"
                      :index="index"
                      @close="index = null"
                    >
                    </CoolLightBox>

                    <div class="images-wrapper row">
                      <div
                        class="image-wrapper"
                        v-for="(image, imageIndex) in event.images"
                        :key="imageIndex"
                      >
                        <div
                          class="gallery_image"
                          @click="index = imageIndex"
                          :style="{ backgroundImage: 'url(' + image + ')' }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="col-md-12 mb-3">
                <template>
                  <div id="app">
                    <div class="row">
                      <div
                        class="image-wrapper col-md-6"
                        v-for="(video, imageIndex) in event.videos"
                        :key="imageIndex"
                      >
                        <video
                          width="320"
                          height="240"
                          controls
                          class="img-fluid"
                        >
                          <source :src="video" type="video/mp4" />
                          <!-- <source src="movie.ogg" type="video/ogg"> -->
                        </video>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-6">
            <h5 class="text-center">Annual Function</h5>
            <p class="text-justify mt-0">
              Society has put up so many boundaries, so many limitations on what’s
              right and wrong that it’s almost impossible to get a pure thought
              out. It’s like a little kid, a little boy, looking at colors.
            </p>
            <p class="text-justify">
              Society has put up so many boundaries, so many limitations on what’s right and wrong that it’s almost impossible to get a pure thought out. It’s like a little kid, a little boy, looking at colors. Society has put up so many boundaries, so many limitations on what’s right and wrong that it’s almost impossible to get a pure thought out. It’s like a little kid, a little boy, looking at colors.
            </p>
            <p class="text-justify mt-0">
              Society has put up so many boundaries, so many limitations on what’s
              right and wrong that it’s almost impossible to get a pure thought
              out. It’s like a little kid, a little boy, looking at colors.
            </p>
            <div class="row">
              <div class="col-md-12 mb-3">
                <template>
                  <div id="app">
                    <CoolLightBox 
                      :items="eventitems" 
                      :index="index"
                      @close="index = null">
                    </CoolLightBox>

                    <div class="images-wrapper row">
                      <div class="image-wrapper" v-for="(image, imageIndex) in eventitems" :key="imageIndex">
                        <div
                          class="gallery_image"
                          @click="index = imageIndex"
                          :style="{ backgroundImage: 'url(' + image + ')' }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div> -->
          <div class="col-lg-12 text-center mt-3">
            <router-link to="/events" class="btn btn-alpine"
              >All Events <i class="fa fa-chevron-right ml-3"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- traditional touch -->
    <div class="section testimonials-2" id="traditional">
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h3 class="display-3 text-alpine">Traditional Touch</h3>
          </div>
        </div>
        <div class="row">
          <b-carousel
            id="carousel-testimonials2"
            height="100vh"
            class="slide carousel-fade carousel-testimonials pointer-event"
            ref="carouselTestimonials2"
          >
            <b-carousel-slide
              class="carousel-inner"
              v-for="traditional in traditional_touch"
              :key="traditional._id"
            >
              <div class="info text-left bg-danger shadow-lg">
                <div
                  class="icon icon-shape bg-white text-danger shadow rounded-circle mb-4"
                >
                  <i class="fa fa-quote-right"></i>
                </div>
                <span class="ml-2 h4 text-white">{{
                  traditional.traditional_name
                }}</span>
                <p
                  class="description text-white"
                  v-html="traditional.traditional_description"
                ></p>
                <!-- <div class="author">
                  <img
                    src="img/faces/team-5.jpg"
                    alt="..."
                    class="avatar img-raised"
                  />
                  <span>Mike John</span>
                </div> -->
              </div>
              <card class="card-blog card-background" data-animation="true">
                <div
                  class="full-background"
                  :style="
                    'background-image: url(' +
                      traditional.traditional_image +
                      ')'
                  "
                ></div>
                <template slot="body">
                  <div class="content-bottom ml-auto">
                    <!-- <a
                      href="javascript:void(0)"
                      class="btn btn-round btn-simple btn-neutral"
                      ><i class="ni ni-book-bookmark"></i> Read more</a
                    > -->
                  </div>
                </template>
              </card>
            </b-carousel-slide>
            <!-- <b-carousel-slide class="carousel-inner">
              <div class="info text-left bg-info shadow-lg pb-2">
                <div
                  class="icon icon-shape bg-white text-info shadow rounded-circle mb-4"
                >
                  <i class="fa fa-quote-right"></i>
                </div>
                <span class="ml-2 h4 text-white">Basant Panchmi</span>
                <p class="small text-white">
                  Basant Panchmi is an occasion which marks the advent of the spring season and also celebrated to worship Goddess Saraswati- "Goddess of knowledge and Wisdom". Yellow color, representative of spiritual knowledge, is given importance. The students of the pre-primarye wing com in yellow clothes, offer yellow colored flowers and also bring yellow colored food. The dress code of the teachers on that day is also yellow. 
                </p>
                <p class="small text-white">
                  Keeping the tradition of inculcating values in today's modern time the Alpine Public School organised a Havan to venerate Goddess Saraswati and invoke her blessings. The children devotionally rendered the Saraswati Vandana as they implored the Goddess to bestow the gift of knowledge and wisdom on them and all those associated with the institution. 
                </p>
              </div>
              <card class="card-blog card-background" data-animation="true">
                <div
                  class="full-background"
                  style="background-image: url('img/hawan.jpg')"
                ></div>
                <template slot="body">
                  <div class="content-bottom ml-auto">
                  </div>
                </template>
              </card>
            </b-carousel-slide> -->
            <a
              class="carousel-control-prev text-primary"
              v-on:click="prev"
              role="button"
              data-slide="prev"
            >
              <i class="ni ni-bold-left"></i>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next text-primary"
              v-on:click="next"
              role="button"
              data-slide="next"
            >
              <i class="ni ni-bold-right"></i>
              <span class="sr-only">Next</span>
            </a>
          </b-carousel>
        </div>
      </div>
    </div>
    <!-- community service -->
    <div class="section team-2 border-top border-bottom" id="community">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center mb-2 mb-md-5">
            <h3 class="display-3 text-alpine">🤝 Community Service 🤝</h3>
            <!-- <h4 class="lead">
              This is the paragraph where you can write more details about your
              team. Keep you user engaged by providing meaningful information.
            </h4> -->
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row text-left justify-content-center">
          <div class="col-lg-12 col-12">
            <div class="float-right community_pictures pl-4">
              <div class="row">
                <div
                  class="col-md-6 mb-3"
                  v-for="(image,
                  index) in beyond_academic.community_service_images"
                  :key="index"
                >
                  <img :src="image" class="img-fluid shadow rounded" />
                </div>
                <!-- <div class="col-md-6 mb-3">
                  <img
                    src="/img/school_bul.jpeg"
                    class="img-fluid shadow rounded"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <img
                    src="/img/school_bul.jpeg"
                    class="img-fluid shadow rounded"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <img
                    src="/img/school_bul.jpeg"
                    class="img-fluid shadow rounded"
                  />
                </div> -->
              </div>
            </div>
            {{ beyond_academic.community_service_description }}

            <!-- <p class="pb-2 mt-0 text-justify">
              The community service aims to equip students with the value and
              understanding of individual in society and motivate them to play
              an active role as leaders in their communities.
            </p>
            <p class="pb-2 mt-0 text-justify">
              We believe in community service and making the world a better
              place. Students from early age are sensitized to care for the
              underprivileged, the needy, and the old. Students are frequently
              taken to orphanages and old age homes. Habit of donating is
              inculcated through donation drive of books, clothes, toys, and
              food items. Alpine encourages it’s students to interact and engage
              the underprivilege by performing dance and singing songs, through
              which the students bring a sense of joy.
            </p>
            <p class="pb-2 mt-0 text-justify">
              Alpine is proud to say that we as an educational institute have
              been active members of our society and we constantly put our best
              foot forward. One such initiative is Gyan Jyoti, an initiative
              that supports and aids the inflicted children. Alpine Public
              School started 'Gyan Jyoti' program in 2010. Under this, 125
              children are being provided free education, books, uniforms,
              transport facilities.
            </p>
            <p class="pb-2 mt-0 text-justify">
              The 'Gyan Jyoti' programs purpose is to awaken the light of
              knowledge in the lives of the underprivileged children through our
              school. The purpose includes aim to provide good education and an
              opportunity to the children of the backward and sensitive sections
              of the city.
            </p>
            <p class="pb-2 mt-0 text-justify">
              We have reached out to many rich and affluent sections of the
              society who have come forward and are cooperating to increase the
              reach of the program. The children enrolled have made remarkable
              progress under the program.
            </p>
            <p class="pb-2 mt-0 text-justify">
              This program is being run for classes I to V and after which there
              is a test conducted once in a year. The students who clears this
              test get an opportunity to study till class 12th through our
              scholarship program.
            </p> -->
          </div>
        </div>
        <div class="row mt-4">
          <div
            class="col-lg-6"
            v-for="services in beyond_academic.services"
            :key="services._id"
          >
            <h5 class="text-center">{{ services.name }}</h5>
            <p class="text-justify mt-0">
              {{ services.description }}
              <!-- We all do our utmost best to keep our home clean which encourages
              hygiene and cleanliness. When our surroundings are not as clean as
              our homes, we tend to be the best critics of our society, and
              neighbors .Unattended garbage or dirty roads, is simply no one can
              ignore. To inculcate awareness about "Swachh Bharat Abhiyan"
              regular activities are conducted in school premises and on the
              occasion like "Gandhi Jayanti" and "Republic Day" we as a school
              move to clean the School surroundings and various parts of city.
              We as a school also take out rallies to spread the message and
              inspire others to do the same. -->
            </p>
          </div>
          <!-- <div class="col-lg-6">
            <h5 class="text-center">Cycling Club “Rangers”</h5>
            <p class="text-justify mt-0">
              Every Year, students convey a message to the society via a theme
              chosen for the cycle rally. Students on cycle carry various
              slogans, dress up for the theme to create social awareness.
            </p>
            <p class="text-justify mt-0">
              Few of the themes taken so far are: Water Conservation, Fight the
              Bite (Awareness on Dengue), Tribute to the Martyrs, Swaach Bharat
              and Abolish child labor.
            </p>
          </div> -->
        </div>
      </div>
    </div>
    <!-- gallery -->
    <div class="section project-2 pt-5" id="gallery">
      <h3 class="display-3 text-alpine text-center mb-4">Gallery</h3>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb-3">
            <template>
              <div id="app">
                <CoolLightBox
                  :items="beyond_academic.gallary"
                  :index="index"
                  @close="index = null"
                >
                </CoolLightBox>

                <div class="images-wrapper row">
                  <div
                    class="image-wrapper"
                    v-for="(image, imageIndex) in beyond_academic.gallary"
                    :key="imageIndex"
                  >
                    <div
                      class="gallery_image"
                      @click="index = imageIndex"
                      :style="{ backgroundImage: 'url(' + image + ')' }"
                    ></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mb-3">
            <template>
              <div id="app">
                <div class="row">
                  <div
                    class="image-wrapper col-md-4"
                    v-for="(video, imageIndex) in beyond_academic.videogallary"
                    :key="imageIndex"
                  >
                    <video width="320" height="200" controls class="img-fluid">
                      <source :src="video" type="video/mp4" />
                      <!-- <source src="movie.ogg" type="video/ogg"> -->
                    </video>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BCarousel } from "bootstrap-vue/esm/components/carousel/carousel";
import { BCarouselSlide } from "bootstrap-vue/esm/components/carousel/carousel-slide";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import axios from "axios";

export default {
  bodyClass: "beyondacademics",
  components: {
    BCarousel,
    BCarouselSlide,
    CoolLightBox,
  },
  data() {
    return {
      metaData: [],
      trips: [],
      events: [],
      traditional_touch: [],
      beyond_academic: {},
      items: [
        "img/results_bg.jpg",
        "img/physics_lab.jpg",
        "img/sports4.jpg",
        "img/chem_lab.jpg",
        "img/council.jpg",
        "img/results_bg.jpg",
        "img/physics_lab.jpg",
        "img/sports4.jpg",
        "img/chem_lab.jpg",
        "img/council.jpg",
      ],
      eventitems: [
        "img/results_bg.jpg",
        "img/physics_lab.jpg",
        "img/sports4.jpg",
        "img/chem_lab.jpg",
        "img/council.jpg",
      ],
      index: null,
      auth: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
      authjson: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
    };
  },
  mounted() {
    // axios
    //   .post(process.env.VUE_APP_API_ENDPOINT+'read_trip',{ "details" :{},"offset":"0"},this.authjson)
    //   .then(response => {
    //     console.log(response);
    //     if(response.data.details){
    //       this.trips=response.data.details;
    //     }else{
    //       this.trips=[];
    //     }
    //   })

    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_event",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.events = response.data.details;
        } else {
          this.events = [];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_traditional_touch",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.traditional_touch = response.data.details;
        } else {
          this.traditional_touch = [];
        }
      });

    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_beyond_academic",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.beyond_academic = response.data.details[0];
        } else {
          this.beyond_academic = [];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "get_trip_years",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.trips = response.data.details;
        } else {
          this.trips = [];
        }
      });
    // read meta api call
    // axios
    // .post(process.env.VUE_APP_API_ENDPOINT+'read_metadata',{ "details" :{"page_url":'/'},"offset":"0"},this.authjson)
    // .then(response => {
    //   console.log(response);
    //   if(response.data.details){
    //   this.metaData=response.data.details[0];

    //   }else{
    //     this.metaData={};
    //   }
    // })
  },
  methods: {
    prev() {
      this.$refs.carouselTestimonials2.prev();
    },
    next() {
      this.$refs.carouselTestimonials2.next();
    },
    prevsports() {
      this.$refs.carouselsports.prevsports();
    },
    nextsports() {
      this.$refs.carouselsports.nextsports();
    },
  },
};
</script>
<style>
.beyondacademics .card-background .card-body {
  padding-top: 20px;
  padding-bottom: 10px;
}
.beyondacademics .projects-3 .card-background {
  padding: 30px 25px;
}
.blur {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.beyondacademics .card-background .card-body {
  min-height: 130px;
}
.beyondacademics .card-background.card-blog {
  overflow: hidden;
  height: 180px;
}
.beyondacademics .card-background.card-blog.card-circle {
  height: 144px;
}
.beyondacademics .bg-image {
  background-image: url(/img/council.jpg);
}
.beyondacademics .card.bg-alpine-dark {
  background-color: #361313;
}
.beyondacademics .fill-default {
  fill: #371515;
}
.beyondacademics .project-1 .card {
  min-height: 300px;
}
.beyondacademics .testimonials-2 p.description {
  font-size: 0.75rem;
}
.beyondacademics .testimonials-2 .card-background.card-blog {
  margin-top: -360px;
}
.beyondacademics .testimonials-2 .info {
  padding: 20px 10px 20px;
}
.train img {
  max-height: 150px;
}
.train img {
  position: relative;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 10s;
}
@-webkit-keyframes run {
  0% {
    left: 0;
  }
  48% {
    -webkit-transform: rotateY(0deg);
  }
  50% {
    left: calc(100% - 100px);
    -webkit-transform: rotateY(180deg);
  }
  98% {
    -webkit-transform: rotateY(180deg);
  }
  100% {
    left: 0;
    -webkit-transform: rotateY(0deg);
  }
}
.avatar img.br-0 {
  border-radius: 0%;
}
.carousel-testimonials .carousel-caption {
  width: 100%;
  left: 0;
}
.content-slider .carousel-indicators .active {
  opacity: 1;
  background-color: #513730;
}
.content-slider .carousel-indicators li {
  background-color: #513333b0;
}
.content-slider.testimonials-2 .carousel-testimonials .carousel-control-prev {
  left: 85%;
  height: 15px;
  width: 15px;
}
.content-slider.testimonials-2 .carousel-testimonials .carousel-control-next {
  left: 93%;
  height: 15px;
  width: 15px;
}
.content-slider .carousel-control-prev-icon {
  background-image: url(/img/prev.svg);
}
.content-slider .carousel-control-next-icon {
  background-image: url(/img/next.svg);
}
.content-slider .carousel-testimonials {
  margin-top: 0px !important;
}
#sports-details .carousel-team .carousel-inner,
#sports-details .carousel-testimonials .carousel-inner {
  height: 380px;
}
#carousel-testimonials2 .info {
  max-width: 420px;
}
#carousel-testimonials2 .info p {
  padding: 0px 25px 0px;
}
.community_pictures {
  max-width: 380px;
}
@media (max-width: 560px) {
  .beyondacademics .page-header .display-2 {
    font-size: 1.8rem;
  }
  .beyondacademics .card-background.card-blog.card-circle {
    height: 105px;
  }
  .train img {
    max-height: 50px;
  }
}
</style>
