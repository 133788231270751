<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/key_features.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-6 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                Events at <span class="text-light">
                Alpine.</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- event 1 -->
    <div class="section features-4 py-5" v-for="event in events" :key="event._id">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3 text-alpine">{{event.event_name}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" v-animate-onscroll="'animated zoomIn'">
            <p class="mt-3" v-html="event.event_long_description">
            </p>
          </div>
          <div class="col-md-12 my-3">
            <template>
              <div id="app">
                <CoolLightBox 
                  :items="event.images" 
                  :index="index"
                  @close="index = null">
                </CoolLightBox>

                <div class="images-wrapper row">
                  <div class="image-wrapper" v-for="(image, imageIndex) in event.images" :key="imageIndex">
                    <div
                      class="gallery_image"
                      @click="index = imageIndex"
                      :style="{ backgroundImage: 'url(' + image + ')' }"
                    ></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="col-md-12 mb-3">
            <template>
              <div id="app">
                <CoolLightBox 
                  :items="event.videos" 
                  :index="index"
                  @close="index = null">
                </CoolLightBox>

                <div class="images-wrapper row">
                  <div class="image-wrapper" v-for="(video, imageIndex) in event.videos" :key="imageIndex">
                    <video width="320" height="240" controls>
                      <source :src="video" type="video/mp4">
                    <!-- <source src="movie.ogg" type="video/ogg"> -->
                    </video>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- event 2 -->
    <!-- <div class="section features-4 py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3 text-alpine">Event 2</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" v-animate-onscroll="'animated zoomIn'">
            <p class="mt-3">
              We see a child as a beautiful little seed who if cared, loved, nurtured and guided carefully has a great potential of growing into a tall and strong tree with intensely deep roots based on moral foundations and with rich dense leaves that portrait the potential of consuming knowledge and turning those into opportunities.
            </p>
            <p class="mt-1">
              <span class="d-block mb-2">At Alpine, We provide a warm and encouraging atmosphere that enhances self-concept, instils basic moral values, and enriches awareness of the natural and cultural world. We believe in inspiring imagination, creativity, and play for every learner and in imparting value-based education to the kids so that they turn out to be global citizens.</span>
            </p>
            <p class="mt-1">
              Our mission is to provide a stimulating learning environment that activates a love for learning, builds foundational skills, that will help unfold the potential of each child so that he or she grows into an emotionally secure, socially aware global citizen who actively shapes the future of the world.
            </p>
            <p>
              Our goal is to provide a structured curriculum and a positive environment which is designed to meet social, emotional, language and cognitive development of children and help them accept the challenge to succeed throughout their school years and beyond.
            </p>
          </div>
          <div class="col-md-12 my-3">
            <template>
              <div id="app">
                <CoolLightBox 
                  :items="items" 
                  :index="index"
                  @close="index = null">
                </CoolLightBox>

                <div class="images-wrapper row">
                  <div class="image-wrapper" v-for="(image, imageIndex) in items" :key="imageIndex">
                    <div
                      class="gallery_image"
                      @click="index = imageIndex"
                      :style="{ backgroundImage: 'url(' + image + ')' }"
                    ></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import CoolLightBox from 'vue-cool-lightbox';
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import axios from "axios";

export default {
  bodyClass: "events",
  components: {
    CoolLightBox
  },
  data() {
    return {
      events :[],
      items: [
        'img/results_bg.jpg',
        'img/physics_lab.jpg',
        'img/sports4.jpg',
        'img/chem_lab.jpg',
        'img/council.jpg',
        'img/results_bg.jpg',
        'img/physics_lab.jpg',
        'img/sports4.jpg',
        'img/chem_lab.jpg',
        'img/council.jpg',
      ],
      eventitems: [
        'img/results_bg.jpg',
        'img/physics_lab.jpg',
        'img/sports4.jpg',
        'img/chem_lab.jpg',
        'img/council.jpg',
      ],
      index: null,
       auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        authjson : { headers: { 'Content-Type':'application/json',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
    }
  },
  mounted () {          
        
        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_event',{ "details" :{},"offset":"0"},this.authjson)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.events=response.data.details; 
            }else{
              this.events=[];
            }
          })
        // read meta api call
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_metadata',{ "details" :{"page_url":'/'},"offset":"0"},this.authjson)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.metaData=response.data.details[0];
          
        }else{
          this.metaData={};
        }
      })
  },
};
</script>
<style>
.events .card-background .card-body {
    padding-top: 20px;
    padding-bottom: 10px;
}
.events .projects-3 .card-background {
    padding: 30px 25px;
}
.blur {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.events .card-background .card-body {
  min-height: 130px;
}
.events .card-background.card-blog {
    overflow: hidden;
    height: 180px;
}
.events .bg-image {
  background-image: url(/img/contact_bg.jpeg);
}
.programs .card-project .card-body {
  padding-top: 0px !important;
}
.programs .card-project:hover .card-body {
    padding: 20px 40px 60px !important;
}
.programs .card-project {
  transition: transform 5s;
}
.programs .card-project:hover img {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}
@media (min-width: 768px) { 
  .images-wrapper {
    margin-right: -20px;
  }
  .events .images-wrapper .image-wrapper {
    width: calc(19.66667% - 30px);
    margin-right: 30px;
  }
}
</style>
