<template>
  <div class="wrapper">
    <div class="main-slides">
      <parallax fixed>
        <video
          v-if="homepagedata.hero_video"
          width="100%"
          height="100%"
          loop
          class="h-100vh main-video"
          controlslist="nodownload"
          autoplay="autoplay"
          muted="muted"
        >
          <source :src="homepagedata.hero_video" type="video/mp4" />
          <source :src="homepagedata.hero_video" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
        <!-- <b-carousel id="carousel1" controls indicators>
          <b-carousel-slide
            img-src="img/slide1.png"
          ></b-carousel-slide>
          <b-carousel-slide
            img-src="img/slide2.png"
          ></b-carousel-slide>
          <b-carousel-slide
            img-src="img/slide3.png"
          ></b-carousel-slide>
          <b-carousel-slide
            img-src="img/slide4.png"
          ></b-carousel-slide>
        </b-carousel> -->
      </parallax>
    </div>
    <!-- stylish gallery -->
    <div class="section features-1 bg-white">
      <div class="container-fluid">
        <div class="row justify-content-center mt-0 mt-md-3">
          <div class="col-md-8 mx-auto text-center mb-3">
            <h1 class="display-2 font-weight-700">
              Welcome to
              <span class="text-alpine d-inline font-weight-700">Alpine</span>
            </h1>
            <p class="lead mt-0 font-weight-500 text-dark">
              {{ homepagedata.about_description }}
            </p>
          </div>
          <div class="col-md-9 mt-1 mt-md-5 px-3 px-md-3">
            <div class="row">
              <div class="col-md-2 pl-3 pr-2 d-none d-md-block">
                <img
                  src="img/dot1.png"
                  class="w-100 img-fluid"
                  v-animate-onscroll="'animated zoomIn'"
                />
                <img
                  :src="homepagedata.about_img1"
                  class="w-100 img-fluid rounded-xl shadow-sm"
                  v-animate-onscroll="'animated fadeInLeft'"
                />
              </div>
              <div class="col-md-3 pl-3 pr-2 d-none d-md-block">
                <img
                  :src="homepagedata.about_img2"
                  class="w-100 img-fluid rounded-xl shadow-sm"
                  v-animate-onscroll="'animated fadeInDown'"
                />
                <img
                  :src="homepagedata.about_img3"
                  class="img-fluid rounded-xl shadow-sm mt-5 w-70 float-left"
                  v-animate-onscroll="'animated rotateInUpLeft'"
                />
                <img
                  src="img/dot2.png"
                  class="img-fluid w-30 mt-5 float-right mr--2"
                  v-animate-onscroll="'animated zoomIn'"
                />
              </div>
              <div class="col-md-2 pl-3 pr-2 d-none d-md-block">
                <img
                  :src="homepagedata.about_img4"
                  class="w-100 img-fluid rounded-xl shadow-sm mt-4"
                  v-animate-onscroll="'animated fadeInDown'"
                />
                <img
                  :src="homepagedata.about_img5"
                  class="img-fluid rounded-xl shadow-sm mt-5"
                  v-animate-onscroll="'animated zoomIn'"
                />
                <img src="img/dot3.png" class="img-fluid mt-5" />
              </div>
              <div class="col-md-2 pl-3 pr-2 d-none d-md-block">
                <img
                  src="https://shelly.elipso.site/wp-content/uploads/sites/5/elementor/thumbs/little-school-girl-in-stationery-store-osld9aaumw63lm4hudomckvaztb5elm5indxip3614.jpg"
                  class="img-fluid rounded-xl shadow-sm mb-5 mt-5 invisible"
                  v-animate-onscroll="'animated zoomIn'"
                />
                <img
                  :src="homepagedata.about_img6"
                  class="w-100 img-fluid rounded-xl shadow-sm position-relative"
                  v-animate-onscroll="'animated zoomIn'"
                />
              </div>
              <div class="col-md-3 pl-3 pr-3 pr-md-2 text-center text-md-left">
                <!-- <img src="https://shelly.elipso.site/wp-content/uploads/sites/5/elementor/thumbs/kids-in-modern-school-osld92s71id2bf5lp3bbq76to914aezwbd8zi3mn6g.jpg" class="w-100 img-fluid rounded-xl shadow-sm mb-5 mt--3" v-animate-onscroll="'animated fadeInRight'"> -->
                <video
                  v-if="homepagedata.hero_video"
                  width="320"
                  height="180"
                  controls
                  style="margin-top: -20px; margin-bottom: 10px; border-radius: 10px;"
                >
                  <source :src="homepagedata.about_video" type="video/mp4" />
                  <!-- <source src="movie.ogg" type="video/ogg"> -->
                </video>
                <!-- <video class="video-tile mb-md-5 mb-0 ml-md--6 d-block mt-md--5 mt-0 rounded-xl" controls v-animate-onscroll="'animated fadeInRight'" controlslist="nodownload" autoplay="autoplay" muted="muted">
                  <source :src="homepagedata.about_video" type="video/mp4">
                </video> -->
                <img
                  :src="homepagedata.about_img7"
                  class="img-fluid rounded-xl shadow-sm d-none d-md-inline"
                  v-animate-onscroll="'animated fadeInRight'"
                />
                <img
                  src="img/dot4.png"
                  class="img-fluid w-25 float-right ml--4 d-none d-md-block"
                  v-animate-onscroll="'animated zoomIn'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CTA -->
    <div class="section features-1 bg-white border-top py-4 py-md-5">
      <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-10">
            <div class="row justify-content-around align-items-center">
              <div class="col-md-8 text-md-left text-center mb-3 mb-md-0">
                <p class="my-0" v-animate-onscroll="'animated slideInLeft'">
                  {{ homepagedata.cta_text }}
                </p>
                <!-- <p class="my-0" v-animate-onscroll="'animated slideInLeft'">
                  The school shall endeavor to maximize the potential of every
                  child through an active partnership between children,
                  teachers, parents and management. The school aims to achieve
                  this by providing a positive, secure and caring environment.
                  The powers of thought, feelings and action in the child, when
                  this integration of head, heart and hand has been brought
                  about, the basis has also been laid for mental adaptability,
                  initiative and moral strength in adult’s life.
                </p> -->
              </div>
              <div class="col-md-3 text-md-right text-center">
                <router-link
                  to="/about"
                  class="btn btn-danger btn-round"
                  v-animate-onscroll="'animated zoomIn'"
                  >Read more <i class="fa fa-arrow-right ml-2"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- director's message -->
    <div
      class="section bg-dark header-filter py-3 py-md-8 principal px-3 px-md-0"
    >
      <div class="py-5">
        <div class="content-center">
          <div class="row">
            <div class="col-lg-5 col-md-8 col-12 mx-auto positioned order-2">
              <!-- <h4 class="title text-white text-uppercase ml-0">
                Build stunning websites and apps
              </h4> -->
              <div class="ml-0">
                <div class="description">
                  <img
                    src="img/quill.png"
                    class="img-fluid mb-2"
                    style="max-width: 60px;"
                    v-animate-onscroll.repeat="'animated rollIn'"
                  />
                  <h6 class="info-title text-uppercase text-white pl-0 d-block">
                    DIRECTOR'S MESSAGE
                  </h6>
                  <p class="text-white opacity-8">
                    {{ homepagedata.director_message }}
                  </p>
                  <h6 class="text-white font-italic">
                    {{ homepagedata.director_name }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-8 col-12 mx-auto order-1 mb-3 mb-mt-0">
              <img
                :src="homepagedata.director_image"
                class="img-fluid rounded-xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- why us -->
    <div class="section features-4 bg-white">
      <div class="container">
        <div class="row">
          <div class="col-md-9 text-center mx-auto">
            <h3 class="display-3 text-alpine">Why Us?</h3>
            <p class="lead mt-1 font-weight-500">
              {{ homepagedata.whyUs_description }}
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div
            class="col-lg-4 mr-auto text-left mt-4"
            v-animate-onscroll="'animated fadeInLeft'"
          >
            <card class="card-blog bg-default" bodyClasses="p-0">
              <template slot="body">
                <img
                  :src="homepagedata.whyUs_body_left.image"
                  v-if="homepagedata.whyUs_body_left"
                  class="card-img-top"
                />
                <blockquote class="card-blockquote">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="svg-bg"
                  >
                    <polygon
                      points="0,52 583,95 0,95"
                      class="fill-default"
                    ></polygon>
                    <polygon
                      points="0,42 583,95 683,0 0,95"
                      opacity=".2"
                      class="fill-default"
                    ></polygon>
                  </svg>
                  <h4
                    class="display-4 text-white"
                    v-if="homepagedata.whyUs_body_left"
                  >
                    {{ homepagedata.whyUs_body_left.title }}
                  </h4>
                  <p
                    class="lead text-italic text-white"
                    v-if="homepagedata.whyUs_body_left"
                  >
                    {{ homepagedata.whyUs_body_left.description }}
                    <!-- The alpine public school campus is strategically designed to
                    provide an ideal and secure environment for its students. -->
                  </p>
                </blockquote>
              </template>
            </card>
          </div>
          <div class="col-lg-7 p-sm-0">
            <div class="row">
              <div class="col-md-6" v-animate-onscroll="'animated zoomIn'">
                <div class="info bg-warning text-white">
                  <div
                    class="icon icon-shape bg-warning shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-satisfied text-white"></i>
                  </div>
                  <h5
                    class="info-title text-white"
                    v-if="homepagedata.whyUs_box"
                  >
                    {{ homepagedata.whyUs_box[0].title }}
                    <!-- Enabling constructivist approach. -->
                  </h5>
                  <p
                    class="description opacity-8"
                    v-if="homepagedata.whyUs_box"
                  >
                    {{ homepagedata.whyUs_box[0].description }}
                    <!-- We strive to – Provide high quality teaching facilities so
                    as to “Create human beings who are able to develop in
                    themselves purpose and direction to their lives.” -->
                  </p>
                </div>
              </div>
              <div class="col-md-6" v-animate-onscroll="'animated zoomIn'">
                <div class="info bg-info text-white">
                  <div
                    class="icon icon-shape bg-info shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-palette text-white"></i>
                  </div>
                  <h5
                    class="info-title text-white"
                    v-if="homepagedata.whyUs_box"
                  >
                    {{ homepagedata.whyUs_box[1].title }}
                  </h5>
                  <p
                    class="description opacity-8"
                    v-if="homepagedata.whyUs_box"
                  >
                    {{ homepagedata.whyUs_box[1].description }}
                    <!-- We develop your child with moral values and spirit of
                    responsibility. We help children build a good vision. We
                    educate beyond the books. -->
                  </p>
                </div>
              </div>
            </div>
            <div class="row mt-lg-4">
              <div class="col-md-6" v-animate-onscroll="'animated zoomIn'">
                <div class="info bg-danger text-white">
                  <div
                    class="icon icon-shape bg-danger shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-user-run text-white"></i>
                  </div>
                  <h5
                    class="info-title text-white"
                    v-if="homepagedata.whyUs_box"
                  >
                    {{ homepagedata.whyUs_box[2].title }}
                    <!-- Encouraging experiential learning. -->
                  </h5>
                  <p
                    class="description opacity-8"
                    v-if="homepagedata.whyUs_box"
                  >
                    {{ homepagedata.whyUs_box[2].description }}
                    <!-- We guide and inspire your child to utmost. Your child can
                    explore and find his/her purpose on their own. -->
                  </p>
                </div>
              </div>
              <div class="col-md-6" v-animate-onscroll="'animated zoomIn'">
                <div class="info bg-success text-white">
                  <div
                    class="icon icon-shape bg-success shadow rounded-circle text-primary"
                  >
                    <i class="ni ni-glasses-2 text-white"></i>
                  </div>
                  <h5
                    class="info-title text-white"
                    v-if="homepagedata.whyUs_box"
                  >
                    {{ homepagedata.whyUs_box[3].title }}
                    <!-- Providing individual attention. -->
                  </h5>
                  <p
                    class="description opacity-8"
                    v-if="homepagedata.whyUs_box"
                  >
                    {{ homepagedata.whyUs_box[3].description }}
                    <!-- The school shall endeavor to maximize the potential of every
                    child through an active partnership all stakeholders. -->
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest events/blog -->
    <div
      class="section blogs-5 bg-alpine-dark border-top border-bottom text-white"
      style="background-image: url('img/ill/p31.svg');background-size: cover;"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto text-center">
            <h3 class="display-3 font-weight-700 text-white">Recent News</h3>
            <p class="lead mt-0 font-weight-500">
              {{ homepagedata.recentNews }}
            </p>
          </div>
          <div class="col-md-10 mx-auto">
            <div class="row">
              <div
                class="col-lg-4 col-md-6"
                v-for="news in newsData"
                :key="news._id"
              >
                <card class="card-blog card-plain">
                  <div class="card-image rounded-xl">
                    <a :href="'blog-post/' + news.blog_id">
                      <img class="img-fluid rounded-xl" :src="news.blog_img" />
                    </a>
                  </div>
                  <div class="card-body px-4">
                    <h5 class="card-title">
                      <a
                        :href="'blog-post/' + news.blog_id"
                        class="text-white"
                        >{{ news.blog_title }}</a
                      >
                    </h5>
                    <!-- <p class="card-description" v-html="news.blog_body"></p> -->
                    <div class="card-footer">
                      <div class="author">
                        <!-- <img
                          src="img/faces/team-3.jpg"
                          alt="..."
                          class="avatar img-raised"
                        /> -->
                        <a
                          class="text-alpine"
                          :href="'blog-post/' + news.blog_id"
                          >Read <i class="fa fa-chevron-right fa-05x"></i
                        ></a>
                      </div>
                      <div class="stats stats-right opacity-8 text-white">
                        <i class="ni ni-watch-time"></i>
                        {{ dateformat(news.created_at) }}
                      </div>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- quick links -->
    <!-- <div class="section bg-secondary border-top border-bottom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-8 text-left">
            <div class="pr-md-2">
              <h3 class="font-weight-700">School Values & Excellence</h3>
              <p>
                Nam mattis felis id sodales rutrum. Nulla ornare tristique mauris, a laoreet erat ornare sit amet. Nulla sagittis faucibus lacus. 
              </p>
              <ul class="list-unstyled mt-2">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="badge badge-circle badge-primary mr-3">
                        <i class="ni ni-settings-gear-65"></i>
                      </div>
                    </div>
                    <div>
                      <h6 class="mb-0">Carefully crafted components</h6>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="badge badge-circle badge-primary mr-3">
                        <i class="ni ni-html5"></i>
                      </div>
                    </div>
                    <div>
                      <h6 class="mb-0">Amazing page examples</h6>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="badge badge-circle badge-primary mr-3">
                        <i class="ni ni-satisfied"></i>
                      </div>
                    </div>
                    <div>
                      <h6 class="mb-0">Super friendly support team</h6>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="badge badge-circle badge-primary mr-3">
                        <i class="ni ni-books"></i>
                      </div>
                    </div>
                    <div>
                      <h6 class="mb-0">Amazing page examples</h6>
                    </div>
                  </div>
                </li>
              </ul>
              <router-link to="/" class="btn btn-danger">FIND OUT MORE <i class="fa fa-chevron-right fa-05x ml-4"></i></router-link>
            </div>
          </div>
          <div class="col-lg-4 quick-video" v-animate-onscroll="'animated zoomIn'">
            <card
              class="card-background mb-0"
              style="background-image: url('img/video_bg.jpg')"
            >
              <template slot="body">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle mb-3"
                >
                  <i class="ni ni-button-play text-alpine"></i>
                </div>
                <a href="javascript:;">
                  <h3 class="card-title">Propelling Future</h3>
                </a>
                <h6 class="desc text-white opacity-8">This is the story of us</h6>
                <base-button tag="a" class="text-white" type="danger" size="sm">
                  Watch Video
                </base-button>
              </template>
            </card>
          </div>
          <div class="col-lg-4">
            <div class="row info info-horizontal px-1 mx-2 py-3 border-bottom">
              <div class="icon icon-shape icon-shape-danger rounded-circle text-white icon-sm mr-2">
                <i class="ni ni-hat-3 text-alpine"></i>
              </div>
              <div class="description">
                <p class="mb-1">Admission documents</p>
              </div>
              <i class="fa fa-chevron-right text-grey fa-05x mt-2 position-absolute right-3"></i>
            </div>
            <div class="row info info-horizontal px-1 mx-2 py-3 border-bottom">
              <div class="icon icon-shape icon-shape-danger rounded-circle text-white icon-sm mr-2">
                <i class="ni ni-hat-3 text-alpine"></i>
              </div>
              <div class="description">
                <p class="mb-1">Admission documents</p>
              </div>
              <i class="fa fa-chevron-right text-grey fa-05x mt-2 position-absolute right-3"></i>
            </div>
            <div class="row info info-horizontal px-1 mx-2 py-3 border-bottom">
              <div class="icon icon-shape icon-shape-danger rounded-circle text-white icon-sm mr-2">
                <i class="ni ni-hat-3 text-alpine"></i>
              </div>
              <div class="description">
                <p class="mb-1">Admission documents</p>
              </div>
              <i class="fa fa-chevron-right text-grey fa-05x mt-2 position-absolute right-3"></i>
            </div>
            <div class="row info info-horizontal px-1 mx-2 py-3 border-bottom">
              <div class="icon icon-shape icon-shape-danger rounded-circle text-white icon-sm mr-2">
                <i class="ni ni-hat-3 text-alpine"></i>
              </div>
              <div class="description">
                <p class="mb-1">Admission documents</p>
              </div>
              <i class="fa fa-chevron-right text-grey fa-05x mt-2 position-absolute right-3"></i>
            </div>
            <div class="row info info-horizontal px-1 mx-2 py-3 border-bottom">
              <div class="icon icon-shape icon-shape-danger rounded-circle text-white icon-sm mr-2">
                <i class="ni ni-hat-3 text-alpine"></i>
              </div>
              <div class="description">
                <p class="mb-1">Admission documents</p>
              </div>
              <i class="fa fa-chevron-right text-grey fa-05x mt-2 position-absolute right-3"></i>
            </div>
            <div class="row info info-horizontal px-1 mx-2 py-3 border-bottom">
              <div class="icon icon-shape icon-shape-danger rounded-circle text-white icon-sm mr-2">
                <i class="ni ni-hat-3 text-alpine"></i>
              </div>
              <div class="description">
                <p class="mb-1">Admission documents</p>
              </div>
              <i class="fa fa-chevron-right text-grey fa-05x mt-2 position-absolute right-3"></i>
            </div>
            <div class="row info info-horizontal px-1 mx-2 py-3 border-bottom">
              <div class="icon icon-shape icon-shape-danger rounded-circle text-white icon-sm mr-2">
                <i class="ni ni-hat-3 text-alpine"></i>
              </div>
              <div class="description">
                <p class="mb-1">Admission documents</p>
              </div>
              <i class="fa fa-chevron-right text-grey fa-05x mt-2 position-absolute right-3"></i>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- testimonials -->
    <div class="section testimonials-3 bg-white pb-md-8 pb-7">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 mx-auto text-center">
            <h3 class="display-3 text-alpine">Join the Alpine world</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 mx-auto">
            <div class="row">
              <div
                class="col-lg-3 col-md-3 col-12 text-center text-md-left mt-6"
              >
                <h3 class="display-3">What parents think about us</h3>
                <p class="lead mt-0 mt-md-3">
                  Here are some of the feedbacks shared by parents to express
                  their feelings towards APS Khurja.
                </p>
                <router-link
                  to="/contact-us"
                  class="btn btn-danger mt-1 mt-md-3"
                  >Contact us</router-link
                >
              </div>
              <div class="col-md-9 col-md-6" v-if="testimonialData.length > 0">
                <b-carousel
                  id="carousel1"
                  height=""
                  class="carousel-team"
                  ref="myCarousel"
                >
                  <b-carousel-slide
                    class="carousel-inner"
                    v-for="testimonial in testimonialData"
                    :key="testimonial._id"
                  >
                    <div class="container">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="wrapper p-md-0">
                            <!-- <h3 class="card-title display-3 text-left">Charlie Watson</h3> -->
                            <div
                              class="lead text-dark text-left"
                              v-html="testimonial.body"
                            ></div>
                            <ul class="list-unstyled mb-0 text-left">
                              <div>
                                <p class="mb-1 text-dark">
                                  {{ testimonial.auther }}
                                </p>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-carousel-slide>

                  <a
                    class="carousel-control-prev text-primary"
                    v-on:click="prev"
                    role="button"
                    data-slide="prev"
                  >
                    <i class="ni ni-bold-left"></i>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a
                    class="carousel-control-next text-primary"
                    v-on:click="next"
                    role="button"
                    data-slide="next"
                  >
                    <i class="ni ni-bold-right"></i>
                    <span class="sr-only">Next</span>
                  </a>
                </b-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- select store -->
      <modal
        :show.sync="openPopup"
        modal-classes="modal-dialog-centered selectstore modal-lg"
        data-keyboard="false"
        data-backdrop="static"
        header-classes="d-none"
        footer-classes="border-top-0 pb-3 pt-0"
      >
        <h6 slot="header" class="modal-title" id="modal-title-notification">
          <!-- Select Store -->
        </h6>

        <div class="pb-0 text-center">
          <div>
            <h4 class="mb-4 text-warning font-weight-bold"></h4>
            <button
              type="secondary"
              @click="openPopup = false"
              class="close float-right position-absolute right-3 top-3"
            >
              <span>×</span>
            </button>
          </div>
          <img :src="popupdata.image" class="img-fluid mb-3 w-100" />
          <span
            class="d-block float-none m-auto mb-0 mt-0 text-center font-weight-600 text-primary"
            v-html="popupdata.text"
          ></span>
          <!-- <i class="fa fa-map-marker fa-3x"></i> -->
          <!-- <p>
          A small river named Duden flows by their place and supplies it
          with the necessary regelialia.
        </p> -->
        </div>

        <template slot="footer" class="bg-secondary">
          <!-- <base-button type="white" @click="selectstore = false">Ok, Got it</base-button> -->

          <!-- <base-button
          type="link"
          class="ml-auto"
          @click="selectstore = false"
        >
          Close
        </base-button> -->
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import { BCarousel } from "bootstrap-vue/esm/components/carousel/carousel";
import { BCarouselSlide } from "bootstrap-vue/esm/components/carousel/carousel-slide";
import Parallax from "vue-parallaxy";
// import { Glide, GlideSlide } from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";
import axios from "axios";
import moment from "moment";
import Modal from "@/components/Modal.vue";

export default {
  bodyClass: "homepage",
  components: {
    Parallax,
    // [Glide.name]: Glide,
    // [GlideSlide.name]: GlideSlide,
    BCarousel,
    BCarouselSlide,
    Modal,
  },
  data() {
    return {
      animationObject: {
        classes: "fadeInLeft",
        delay: 500,
        duration: 1000,
      },
      metaData: [],
      openPopup: false,
      popupdata: {},
      newsData: [],
      testimonialData: [],
      headermenu: [],
      homepagedata: {},
      datanotfound: false,
      auth: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
      authjson: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
    };
  },
  mounted() {
    if (
      localStorage.getItem("selectstore") == null ||
      localStorage.getItem("selectstore") == "undefined" ||
      localStorage.getItem("selectstore") == ""
    ) {
      this.selectstore = true;
      //localStorage.setItem('selectstore',false);
    }
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_homepopup",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.popupdata = response.data.details[0];
          if (this.popupdata.status === "approved") {
            this.openPopup = true;
          }
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_homepage",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.homepagedata = response.data.details[0];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_header_menu",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.headermenu = response.data.details;
        }
      });

    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_blog_all",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.newsData = response.data.details;
        } else {
          this.newsData = [];
        }
      });

    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_testimonial",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.testimonialData = response.data.details;
        } else {
          this.testimonialData = [];
        }
      });
    // read meta api call
    // axios
    // .post(process.env.VUE_APP_API_ENDPOINT+'read_metadata',{ "details" :{"page_url":'/'},"offset":"0"},this.authjson)
    // .then(response => {
    //   console.log(response);
    //   if(response.data.details){
    //   this.metaData=response.data.details[0];
    //   }else{
    //     this.metaData={};
    //   }
    // })
  },
  metaInfo() {
    return {
      title: this.metaData.meta_title,
      titleTemplate: "%s | Bakeneto",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      meta: [
        {
          name: "description",
          content: this.metaData.meta_description,
        },
        {
          property: "og:title",
          content: this.metaData.og_title,
          // template: '%s - My page',
          template: (chunk) => `${chunk} - Bakeneto`,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.metaData.og_description,
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: this.metaData.og_image,
          vmid: "og:image",
        },
        {
          property: "og:type",
          content: this.metaData.og_type,
          vmid: "og:type",
        },
        {
          property: "og:url",
          content: "https://bakeneto.com" + window.location.pathname,
          vmid: "og:url",
        },
      ],
    };
  },
  methods: {
    dateformat(val) {
      return moment.utc(new Date(val)).format("Do MMM YYYY");
      //console.log(this.blogData.date);
    },
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
  },
};

// export default {
//   bodyClass: "homepage",
//   components: {
//     // BCarousel,
//     // BCarouselSlide,
//     Parallax,
//     [Glide.name]: Glide,
//     [GlideSlide.name]: GlideSlide
//   },
//   data() {
//     return {
//       animationObject:{
//         classes: 'fadeInLeft',
//         delay: 500,
//         duration: 1000
//       }
//     };
//   },
// };
</script>
<style>
.homepage .carousel {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.testimonials-3 .carousel {
  height: auto;
  margin-top: 0px;
}
.testimonials-3 .carousel-caption {
  right: 0%;
  top: 20px;
  left: 5%;
}
.testimonials-3 .carousel-control-prev,
.testimonials-3 .carousel-control-next {
  z-index: 9999;
}
.homepage {
  background-color: white;
}
.homepage .Masthead {
  z-index: 0;
}
.main-slides:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 250px;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(35, 31, 32, 0.8)),
    to(rgba(35, 31, 32, 0))
  );
  background: linear-gradient(
    180deg,
    rgba(35, 31, 32, 0.8) 0%,
    rgba(35, 31, 32, 0) 100%
  );
  display: block;
}
.quick-video .card-background {
  padding: 50px 35px;
}
.principal {
  background-image: url(/img/school_kids.jpg);
  background-size: cover;
}
.main-video {
  object-fit: fill;
}
.video-tile {
  width: 340px;
  height: 200px;
}
.homepage .testimonials-3 .info .description {
  padding: 10px 0px;
}
.homepage .glide__slides .glide__slide img {
  margin: 0px auto;
}
.homepage .testimonials-3 .info .author img {
  margin-left: 0px;
  width: 68px;
}
.homepage .testimonials-3 .author {
  display: flex;
}
.testimonials-3 .positioned {
  z-index: 999;
}
@media (max-width: 768px) {
  .video-tile {
    width: 100%;
    height: auto;
  }
}
</style>
