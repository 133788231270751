<template>
  <div class="wrapper">
    <div class="main-slides h-50vh" style="background: url(img/key_features.jpg); background-size: cover;">
      <div class="page-header h-50vh">
        <div class="container">
          <div class="row align-items-center text-center justify-content-center text-light">
            <div class="col-lg-9 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-6 text-light">
                Download Transfer Certificate<span class="text-light"></span>
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-4">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <!-- <h3 class="display-3 text-alpine"></h3> -->
          </div>
        </div>
        <div class="mt-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-9 col-md-10 mx-auto">
                <div class="text-center">
                </div>
                <div class="card card-raised card-form-horizontal">
                  <div class="card-body">
                    <form method="" action="">
                      <div class="row">
                        <div class="col-sm-9">
                          <base-input
                            v-model="search"
                            icon="ni ni-email-83"
                            placeholder="Admission no"
                          ></base-input>
                        </div>
                        <div class="col-sm-3">
                          <base-button type="alpine" id="viewtcbtn" @click="search_tc()" block>View TC</base-button>
                        </div>
                      </div>
                    </form>
                    <div class="table-responsive">
                      <el-table
                        class="table table-responsive table-flush align-items-center"
                        header-row-class-name="thead-light"
                        :data="tces"
                        >
                        <el-table-column
                          label="NAME OF THE STUDENT"
                          prop="name"
                          sortable
                          scope="row"
                        >
                          <template v-slot="{ row }">
                            <!-- {{row}} -->
                            <div class="align-items-center">
                              <div class="media-body">
                                <!-- <img alt="Image placeholder" class="avatar-sm avatar mr-3" src="img/faces/team-1.jpg" /> -->
                                <span class="name mb-0 text-sm" v-if="row.student_oid">{{ row.student_oid.user_name }}</span>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column label="Student Adm no." prop="student_oid.admission_no" sortable>
                        </el-table-column>
                        <el-table-column label="Class" prop="class_oid.class_name" sortable>
                        </el-table-column>
                        <el-table-column
                          label="Download TC"
                          prop="tc_link"
                          sortable
                          header-align="right"
                          headerClasses="text-right"
                          >
                          <template v-slot="{ row }">
                            <div class="align-items-center justify-content-end">
                              <a :href="row.tc_link" type="success" download="true"><i class="fa fa-download mr-3"></i>Download TC</a>
                              <span class="description d-block mt-3" style="white-space: normal;">Please clear your dues to download your transfer certificate.</span>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  // DropdownMenu,
} from "element-ui";
  import axios from "axios";

export default {
  bodyClass: "academics",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      search:'',
      pricing5Tab: "10th",
      student_oid:'',
      projects: [
        {
          title: "Rakesh Kumar Trivedi",
          budget: "Sec A",
          admission_no: "6575832",
          statusType: "warning",
          percentage: 60
        },
      ],
      tces:[],
      currentPage: 1
    };
  },
  methods: {
      search_tc(){
        const querystring = require('querystring');
          axios.post(process.env.VUE_APP_API_ENDPOINT+'read_student',{"details":{"admission_no":this.search,"user_phone":this.user_phone,"user_dob":this.user_dob}},this.auth1)
          .then(response1 => {
            if(response1.data.details){
              let userdetails=response1.data.details[0];
              this.student_oid=userdetails._id;
           axios.post(process.env.VUE_APP_API_ENDPOINT+'read_tc',{"details":{"student_oid":this.student_oid}},this.auth)
          .then(response => {
            console.log(response); 
            if(response.data.details){
              this.tces=response.data.details;
            }else{
              this.tces=[];
            }
            this.message=response.data.message;
            document.getElementById("viewtcbtn").classList.remove('btn-primary');
            document.getElementById("viewtcbtn").classList.add('btn-success');
            document.getElementById("viewtcbtn").innerHTML='Searched';
            setTimeout(function(){
            document.getElementById("viewtcbtn").classList.remove('btn-success');
            document.getElementById("viewtcbtn").classList.add('btn-primary');
            document.getElementById("viewtcbtn").innerHTML="Search";
          }, 2000);

          })
         }
        })
        
      },
    },
};
</script>
<style>
.academics .card-background .card-body {
    padding-top: 20px;
    padding-bottom: 10px;
}
.academics .projects-3 .card-background {
    padding: 30px 25px;
}
.blur {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.academics .card-background .card-body {
  min-height: 130px;
}
.academics .card-background.card-blog {
    overflow: hidden;
    height: 180px;
}
.academics .bg-image {
  background-image: url(/img/contact_bg.jpeg);
}
</style>
