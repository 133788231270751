<template>
  <div class="wrapper">
    <div
      class="main-slides h-70vh"
      style="background: url(img/pre_primary.jpg); background-size: cover;background-position-y:bottom;"
    >
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div
              class="col-lg-6 col-12"
              v-animate-onscroll="{
                down: 'animated fadeOutDown',
                up: 'animated fadeInDown',
              }"
            >
              <h1 class="display-2 mt-8 text-light">
                Alpine's <span class="text-light"> Pre-Primary Wing.</span>
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- overview -->
    <div class="section features-4">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3 text-alpine">Overview</h3>
            <p class="lead font-weight-500 mt-1 text-dark">
              {{ preprimaryData.overview_heading }}
              <!-- We have been in the field of Education for over 2 decades now and we have invested our heart, and soul to convert this dream into reality. This isn’t just a preschool, Ours is an educational centre which not only provides the most premium facilities but also provides world-class education. -->
            </p>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-4 mr-auto text-left mt-4"
            v-animate-onscroll="'animated fadeInLeft'"
          >
            <img
              :src="preprimaryData.overview_image"
              class="img-fluid rounded-xl shadow-sm p-2 border mb-2"
            />
            <img
              :src="preprimaryData.overview_image2"
              class="img-fluid rounded-xl shadow-sm p-2 border mb-2"
            />
          </div>
          <div class="col-lg-8">
            <div class="row">
              <div class="col-md-12" v-animate-onscroll="'animated zoomIn'">
                <p class="mt-3">
                  {{ preprimaryData.overview_description }}
                </p>
                <!-- <p class="mt-3">
                  We see a child as a beautiful little seed who if cared, loved, nurtured and guided carefully has a great potential of growing into a tall and strong tree with intensely deep roots based on moral foundations and with rich dense leaves that portrait the potential of consuming knowledge and turning those into opportunities.
                </p>
                <p class="mt-1">
                  <span class="d-block mb-2">At Alpine, We provide a warm and encouraging atmosphere that enhances self-concept, instils basic moral values, and enriches awareness of the natural and cultural world. We believe in inspiring imagination, creativity, and play for every learner and in imparting value-based education to the kids so that they turn out to be global citizens.</span>
                </p>
                <p class="mt-1">
                  Our mission is to provide a stimulating learning environment that activates a love for learning, builds foundational skills, that will help unfold the potential of each child so that he or she grows into an emotionally secure, socially aware global citizen who actively shapes the future of the world.
                </p>
                <p>
                  Our goal is to provide a structured curriculum and a positive environment which is designed to meet social, emotional, language and cognitive development of children and help them accept the challenge to succeed throughout their school years and beyond.
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- departments -->
    <div class="section project-2 programs pt-3 bg-image" id="programs">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center my-5">
            <h3 class="display-3 text-white">Programs</h3>
            <p class="lead font-weight-500 text-white">
              {{ preprimaryData.programs_heading }}
              <!-- The time is now for it to be okay to be great. People in this
              world shun people for being great. -->
            </p>
          </div>
        </div>
        <div class="row mt-4">
          <div
            class="col-lg-4 col-md-6"
            v-for="program in programData"
            :key="program._id"
          >
            <card class="card-project" headerClasses="border-0 pb-2">
              <template slot="header">
                <img
                  :src="program.image1"
                  class="img-fluid rounded-xl mx-auto mt--6 p-2 shadow-sm bg-white"
                />
              </template>
              <template slot="body">
                <router-link :to="'/' + program.linkId">
                  <h4 class="card-title mt-3">{{ program.name }}</h4>
                </router-link>
                <p class="card-description">
                  {{ program.short_description }}
                </p>
                <div class="card-footer">
                  <router-link
                    :to="'/' + program.linkId"
                    class="btn btn-link text-primary"
                  >
                    View Details <i class="ni ni-user-run ml-1"></i>
                  </router-link>
                </div>
              </template>
            </card>
          </div>
          <!-- <div class="col-lg-4 col-md-6">
            <card class="card-project" headerClasses="border-0 pb-2">
              <template slot="header">
                <img
                  src="img/sports1.jpg"
                  class="img-fluid rounded-xl mx-auto mt--6 p-2 shadow-sm bg-white"
                />
              </template>
              <template slot="body">
                <router-link to="/nursery">
                  <h4 class="card-title mt-3">Nursery</h4>
                </router-link>
                <p class="card-description">
                  If everything I did failed - which it doesn't, it actually
                  succeeds - just the fact that I'm willing to fail is an
                  inspiration. People are so scared to lose that they don't even
                  try.
                </p>
                <div class="card-footer">
                  <router-link to="/nursery" class="btn btn-link text-primary">
                    View Details <i class="ni ni-user-run ml-1"></i>
                  </router-link>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card class="card-project" headerClasses="border-0 pb-2">
              <template slot="header">
                <img
                  src="img/sports4.jpg"
                  class="img-fluid rounded-xl mx-auto mt--6 p-2 shadow-sm bg-white"
                />
              </template>
              <template slot="body">
                <router-link to="/kg">
                  <h4 class="card-title mt-3">Kindergarten</h4>
                </router-link>
                <p class="card-description">
                  If everything I did failed - which it doesn't, it actually
                  succeeds - just the fact that I'm willing to fail is an
                  inspiration. People are so scared to lose that they don't even
                  try.
                </p>
                <div class="card-footer">
                  <router-link to="/kg" class="btn btn-link text-primary">
                    View Details <i class="ni ni-user-run ml-1"></i>
                  </router-link>
                </div>
              </template>
            </card>
          </div> -->
        </div>
      </div>
    </div>
    <!-- Activities -->
    <div class="section project-2 programs pt-2 pb-5" id="activities">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center my-5">
            <h3 class="display-3 text-alpine">Activities</h3>
            <p class="lead font-weight-500 text-dark">
              {{ preprimaryData.activities_heading }}
              <!-- The time is now for it to be okay to be great. People in this
              world shun people for being great. -->
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div
            class="col-lg-2 col-md-4"
            v-for="activities in preprimaryData.activities"
            :key="activities._id"
          >
            <img
              :src="activities.image"
              class="img-fluid mx-auto d-block p-3"
            />
            <p class="font-weight-bold text-center">{{ activities.name }}</p>
          </div>
          <!-- <div class="col-lg-2 col-md-4">
            <img src="img/festival.png" class="img-fluid mx-auto d-block p-3" />
            <p class="font-weight-bold text-center">Festival Celebrations</p>
          </div>
          <div class="col-lg-2 col-md-4">
            <img
              src="img/kindergarten.png"
              class="img-fluid mx-auto d-block p-3"
            />
            <p class="font-weight-bold text-center">
              Free play learning stations
            </p>
          </div>
          <div class="col-lg-2 col-md-4">
            <img src="img/sandpit.png" class="img-fluid mx-auto d-block p-3" />
            <p class="font-weight-bold text-center">Sandpit play</p>
          </div>
          <div class="col-lg-2 col-md-4">
            <img
              src="img/sensorial.png"
              class="img-fluid mx-auto d-block p-3"
            />
            <p class="font-weight-bold text-center">Sensorial activities</p>
          </div>
          <div class="col-lg-2 col-md-4">
            <img src="img/sing.png" class="img-fluid mx-auto d-block p-3" />
            <p class="font-weight-bold text-center">Sing along - Rhyme time</p>
          </div> 
          <div class="col-lg-2 col-md-4">
            <img
              src="img/special_day.png"
              class="img-fluid mx-auto d-block p-3"
            />
            <p class="font-weight-bold text-center">Special theme days</p>
          </div>
          <div class="col-lg-2 col-md-4">
            <img src="img/splash.png" class="img-fluid mx-auto d-block p-3" />
            <p class="font-weight-bold text-center">Splash pool</p>
          </div>
          <div class="col-lg-2 col-md-4">
            <img src="img/story.png" class="img-fluid mx-auto d-block p-3" />
            <p class="font-weight-bold text-center">Story-time</p>
          </div>
          -->
        </div>
      </div>
    </div>
    <!-- admissions -->
    <div class="section project-2 pt-6 border-top bg-secondary" id="admissions">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3 text-alpine">Admissions</h3>
            <p class="lead font-weight-500 mt-0 text-dark">
              Currently, the school has classes from Play Group, Pre Nursery,
              Nursery, and I to class XII.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <img
              :src="preprimaryData.admission_image"
              class="img-fluid my-3 shadow-sm"
            />
          </div>
          <div class="col-md-6 col-lg-6">
            <p class="lead font-weight-700 mb-0">
              {{ preprimaryData.admission_title }}
            </p>
            <p class="mt-1">
              {{ preprimaryData.admission_description }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- gallery -->
    <div class="section project-2 pt-5" id="gallery">
      <h3 class="display-3 text-alpine text-center mb-4">Gallery</h3>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-12 mb-3">
            <template>
              <div id="app">
                <CoolLightBox
                  :items="preprimaryData.gallary"
                  :index="index"
                  @close="index = null"
                >
                </CoolLightBox>

                <div class="images-wrapper row">
                  <div
                    class="image-wrapper"
                    v-for="(image, imageIndex) in preprimaryData.gallary"
                    :key="imageIndex"
                  >
                    <div
                      class="gallery_image"
                      @click="index = imageIndex"
                      :style="{ backgroundImage: 'url(' + image + ')' }"
                    ></div>
                  </div>
                </div>
                <div class="images-wrapper row">
                  <div
                    class="image-wrapper"
                    v-for="(video, imageIndex) in preprimaryData.videogallary"
                    :key="imageIndex"
                  >
                    <video width="320" height="200" controls class="img-fluid">
                      <source :src="video" type="video/mp4" />
                      <!-- <source src="movie.ogg" type="video/ogg"> -->
                    </video>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import axios from "axios";
export default {
  bodyClass: "preprimary",
  components: {
    CoolLightBox,
  },
  data() {
    return {
      items: [
        "img/results_bg.jpg",
        "img/physics_lab.jpg",
        "img/sports4.jpg",
        "img/chem_lab.jpg",
        "img/council.jpg",
        "img/results_bg.jpg",
        "img/physics_lab.jpg",
        "img/sports4.jpg",
        "img/chem_lab.jpg",
        "img/council.jpg",
      ],
      index: null,
      preprimaryData: [],
      programData: [],
      auth: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
      authjson: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "alpine#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
    };
  },
  mounted() {
    // read pre-primary api call
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_pre_primary",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.preprimaryData = response.data.details[0];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_program",
        { details: {}, offset: "0" },
        this.authjson
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.programData = response.data.details;
        }
      });
  },
  methods: {
    // format_datetime(value){
    //   if(value) {
    //     return moment.utc(new Date(value)).format("LLL")
    //   }
    // },
  },
};
</script>
<style>
.preprimary .card-background .card-body {
  padding-top: 20px;
  padding-bottom: 10px;
}
.preprimary .projects-3 .card-background {
  padding: 30px 25px;
}
.blur {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.preprimary .card-background .card-body {
  min-height: 130px;
}
.preprimary .card-background.card-blog {
  overflow: hidden;
  height: 180px;
}
.preprimary .bg-image {
  background-image: url(/img/contact_bg.jpeg);
}
.programs .card-project .card-body {
  padding-top: 0px !important;
}
.programs .card-project:hover .card-body {
  padding: 20px 40px 60px !important;
}
.programs .card-project {
  transition: transform 5s;
}
.programs .card-project:hover img {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}
</style>
