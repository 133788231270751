<template>
  <div class="wrapper">
    <div class="main-slides h-70vh mb-5" style="background: url(img/mission_vision.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-7 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                Captured moments of<span class="text-light">
                trips from {{year}}.</span
                >
              </h1>
              <!-- <p class="pb-4 text-center">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1 py-2" v-for="trip in trips" :key="trip._id">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <!-- <span class="badge badge-primary badge-pill mb-3">About Us</span> -->
            <h3 class="display-3 text-alpine">{{trip.trip_name}}</h3>
          </div>
        </div>
      </div>
      <div class="container mt-1 mb-5">
        <div class="row text-left justify-content-center">
          <div class="col-lg-12 col-12 mb-3">
            <p class="pb-2 mt-0 text-center" v-html="trip.trip_description">
            </p>
          </div>
          <div class="col-lg-12 col-12 mb-3">
            <template>
              <div id="app">
                <CoolLightBox 
                  :items="trip.trip_images" 
                  :index="index"
                  @close="index = null">
                </CoolLightBox>

                <div class="images-wrapper row">
                  <div class="image-wrapper" v-for="(image, imageIndex) in trip.trip_images" :key="imageIndex">
                    <div
                      class="gallery_image"
                      @click="index = imageIndex"
                      :style="{ backgroundImage: 'url(' + image + ')' }"
                    ></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="col-lg-12 col-12 mb-3 video_gallery">
            <template>
              <div id="app">
                <div class="row">
                  <div class="image-wrapper col-md-4" v-for="(video, imageIndex) in trip.trip_videos" :key="imageIndex">
                    <video width="320" height="200" controls class="img-fluid">
                      <source :src="video" type="video/mp4">
                    <!-- <source src="movie.ogg" type="video/ogg"> -->
                    </video>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section features-1 py-2">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine">Jaipur trip</h3>
          </div>
        </div>
      </div>
      <div class="container mt-1 mb-5">
        <div class="row text-left justify-content-center">
          <div class="col-lg-12 col-12 mb-3">
            <p class="pb-2 mt-0 text-center">
              Our school library offers unending options for learning. It opens up lines for independent thinking and provides genuine research material. The informative literature provides great opportunities for the social, cultural and educational growth of children. The librarian acts as the instructional partner and an information specialist for the students.
            </p>
          </div>
          <div class="col-lg-12 col-12 mb-3">
            <template>
              <div id="app">
                <CoolLightBox 
                  :items="items" 
                  :index="index"
                  @close="index = null">
                </CoolLightBox>

                <div class="images-wrapper row">
                  <div class="image-wrapper" v-for="(image, imageIndex) in items" :key="imageIndex">
                    <div
                      class="gallery_image"
                      @click="index = imageIndex"
                      :style="{ backgroundImage: 'url(' + image + ')' }"
                    ></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1 py-2">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <h3 class="display-3 text-alpine">Mumbai trip</h3>
          </div>
        </div>
      </div>
      <div class="container mt-1 mb-5">
        <div class="row text-left justify-content-center">
          <div class="col-lg-12 col-12 mb-3">
            <p class="pb-2 mt-0 text-center">
              Our school library offers unending options for learning. It opens up lines for independent thinking and provides genuine research material. The informative literature provides great opportunities for the social, cultural and educational growth of children. The librarian acts as the instructional partner and an information specialist for the students.
            </p>
          </div>
          <div class="col-lg-12 col-12 mb-3">
            <template>
              <div id="app">
                <CoolLightBox 
                  :items="items" 
                  :index="index"
                  @close="index = null">
                </CoolLightBox>

                <div class="images-wrapper row">
                  <div class="image-wrapper" v-for="(image, imageIndex) in items" :key="imageIndex">
                    <div
                      class="gallery_image"
                      @click="index = imageIndex"
                      :style="{ backgroundImage: 'url(' + image + ')' }"
                    ></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import CoolLightBox from 'vue-cool-lightbox';
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import axios from "axios";
export default {
  bodyClass: "gallery",
  components: {
    CoolLightBox
  },
  data() {
    return {
      metaData:[],
      trips :[],
      year : this.$route.params.id,
      items: [
        'img/results_bg.jpg',
        'img/physics_lab.jpg',
        'img/sports4.jpg',
        'img/chem_lab.jpg',
        'img/council.jpg',
        'img/results_bg.jpg',
        'img/physics_lab.jpg',
        'img/sports4.jpg',
        'img/chem_lab.jpg',
        'img/council.jpg',
      ],
      eventitems: [
        'img/results_bg.jpg',
        'img/physics_lab.jpg',
        'img/sports4.jpg',
        'img/chem_lab.jpg',
        'img/council.jpg',
      ],
      index: null,
       auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        authjson : { headers: { 'Content-Type':'application/json',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
    }
  },
  mounted () {         
        //this.$route.params.id 
        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_trip',{ "details" :{"year" :this.$route.params.id}},this.authjson)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.trips=response.data.details; 
            }else{
              this.trips=[];
            }
          })

        
        // read meta api call
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_metadata',{ "details" :{"page_url":'/'},"offset":"0"},this.authjson)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.metaData=response.data.details[0];
          
        }else{
          this.metaData={};
        }
      })
  },
};
</script>
<style>
.key_features  .info-horizontal {
  max-width: 100% !important;
}
</style>
