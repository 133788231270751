<template>
  <ul class="navbar-nav ml-lg-auto">
    <li class="nav-item">
      <a class="nav-link nav-link-icon" href="javascript:void(0)">
        <i class="ni ni-favourite-28"></i>
        <span class="nav-link-inner--text d-lg-none">Discover</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link nav-link-icon" href="javascript:void(0)">
        <i class="ni ni-notification-70"></i>
        <span class="nav-link-inner--text d-lg-none">Profile</span>
      </a>
    </li>
    <li class="nav-item dropdown">
      <a
        class="nav-link nav-link-icon"
        href="javascript:void(0)"
        id="navbar-default_dropdown_1"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="ni ni-settings-gear-65"></i>
        <span class="nav-link-inner--text d-lg-none">Settings</span>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="navbar-default_dropdown_1"
      >
        <a class="dropdown-item" href="javascript:void(0)">Action</a>
        <a class="dropdown-item" href="javascript:void(0)">Another action</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="javascript:void(0)"
          >Something else here</a
        >
      </div>
    </li>
  </ul>
</template>
<script>
export default {};
</script>
<style></style>
