<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/key_features.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-6 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                Keeping learning at<span class="text-light">
                the core of education.</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-4">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3 text-alpine">Overview</h3>
            <p class="lead font-weight-500 text-dark">
              {{academic.overview_short_description}}
              <!-- A child spends maximum of her childhood time in school as a student. The school infrastructure plays an important role in providing a safe space and environment to children. Schools, being the second home plays important role’s like boosting their confidence to making them learn the importance of team work and socialisation, a school does it all. -->
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-4 mr-auto text-left mt-4" v-animate-onscroll="'animated fadeInLeft'">
            <card class="card-blog bg-default" bodyClasses="p-0">
              <template slot="body">
                <img :src="academic.overview_image" class="card-img-top" />
                <blockquote class="card-blockquote">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="svg-bg"
                  >
                    <polygon
                      points="0,52 583,95 0,95"
                      class="fill-default"
                    ></polygon>
                    <polygon
                      points="0,42 583,95 683,0 0,95"
                      opacity=".2"
                      class="fill-default"
                    ></polygon>
                  </svg>
                  <h4 class="display-4 text-white">{{academic.overview_image_title}}</h4>
                  <!-- <p class="text-italic text-white">
                    That’s my skill. I’m not really specifically talented at
                    anything except for the ability to learn.
                  </p> -->
                </blockquote>
              </template>
            </card>
          </div>
          <div class="col-lg-8">
            <div class="row">
              <div class="col-md-12" v-animate-onscroll="'animated zoomIn'">
                <p class="">
                  {{academic.overview_description}}
                  <!-- At Alpine Public School we carefully design, plan and implement a curriculum which provides breadth and depth for every pupil. Careful analysis and discussion about child’s background and what this means in relation to learning needs and local context takes place. Life experiences and closed knit culture has helped us to design a curriculum with every subject area. We believe that by focusing on the following key priorities our children will be ready to successfully meet the challenges of the next stage of their education and their lives. -->
                </p>
                <!-- <p class="">
                  We have designed and planned our curriculum to offer a range of experiences which contributes to every child receiving a full and rich experience. This support ensures that our children benefit from a full range of academic, spiritual, moral, social and cultural activities. These activities enrich their lives and that of our whole school community which instils a proud feeling towards our Indian value system and diverse society. 
                </p>
                <p class="">
                  We take pride in our curriculum which gives our children the knowledge and skills, confidence and self-belief to lead a happy life by encouraging them to be aspirational and work towards their goals and dreams in life. 
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- curriculum -->
    <div class="section features features-5 bg-secondary border-top border-bottom" id="curriculum">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <h3 class="display-3 text-alpine">Curriculum</h3>
            <p class="" v-html="academic.curriculum_long_description">
            </p>
            <p class="lead font-weight-500">Salient Features:</p>
            <p class="text-italic">
              <ul class="list-unstyled">
                <li v-for="features in academic.curriculum_features" :key="features">
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="mr-3">
                        <i class="ni ni-check-bold"></i>
                      </div>
                    </div>
                    <div>
                      <p class="mb-0">{{features}}</p>
                    </div>
                  </div>
                </li>
                <!-- <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="mr-3">
                        <i class="ni ni-check-bold"></i>
                      </div>
                    </div>
                    <div>
                      <p class="mb-0">Equal stress on proficiency in both languages - Hindi and English.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="mr-3">
                        <i class="ni ni-check-bold"></i>
                      </div>
                    </div>
                    <div>
                      <p class="mb-0">Hobbies and Co-curricular Activities such as Painting, Gardening, Clay Modelling, Sports and Athletics, Yoga, Aerobics, Public Speaking, Drama, Debates, Magazine, Educational Tours and Social Service Activities etc. for  the all-round development of the child.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="mr-3">
                        <i class="ni ni-check-bold"></i>
                      </div>
                    </div>
                    <div>
                      <p class="mb-0">Close liaison between parents and teachers.</p>
                    </div>
                  </div>
                </li> -->
              </ul>
            </p>
            <!-- <p class="">
              The school seeks to inculcate in young minds the fundamental values of truthfulness, fearlessness and tolerance through a variety of activities.  Moral Science is taught to encourage value education as our constant endeavour is to make our students better individuals. In a nutshell, conscious and concrete efforts are made to mould the students to face the challenges of the 21st century with competence, confidence and compassion.
            </p> -->
          </div>
          <div class="col-lg-4 ml-auto text-left mt-4" v-animate-onscroll="'animated fadeInLeft'">
            <card class="card-blog bg-default" bodyClasses="p-0">
              <template slot="body">
                <img :src="academic.curriculum_image" class="card-img-top" />
                <blockquote class="card-blockquote">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="svg-bg"
                  >
                    <polygon
                      points="0,52 583,95 0,95"
                      class="fill-default"
                    ></polygon>
                    <polygon
                      points="0,42 583,95 683,0 0,95"
                      opacity=".2"
                      class="fill-default"
                    ></polygon>
                  </svg>
                  <h5 class="display-5 text-white">{{academic.curriculum_short_description}}</h5>
                </blockquote>
              </template>
            </card>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-8 mt-md-5">
            <div class="row">
              <div class="col-md-8">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- departments -->
    <div class="section project-2 pt-3 bg-image" id="departments">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center my-5">
            <h3 class="display-3 text-white">Departments</h3>
            <p class="lead font-weight-500 text-white">
              {{academic.department_description}}
              <!-- The various departments at Alpine are the testimony of our multi-dimensional learning approach. -->
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6" v-for="department in departments" :key="department._id">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  :class="'icon icon-lg icon-shape shadow rounded-circle mx-auto '+department.icon_class"
                 v-animate-onscroll="'animated zoomIn'">
                  <i :class="department.icon"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">{{department.heading}}</h4>
                <p class="card-description">
                  {{department.short_description}}
                  <!-- Through Early Years, we have adopted Read, Write, Inc., a synthetic phonics programme which is delivered throughout the school to enable our students to become able readers, spellers and writers. -->
                </p>
                <div class="card-footer">
                  <a :href="'/departments#'+department.linkId" class="btn btn-link text-primary">
                    Check more <i class="ni ni-user-run ml-1"></i>
                  </a>
                </div>
              </template>
            </card>
          </div>
          <!-- <div class="col-lg-4 col-md-6">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape shadow rounded-circle mx-auto bg-gradient-orange" v-animate-onscroll="'animated zoomIn'">
                  <i class="fa fa-plus text-white"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Mathematics</h4>
                <p class="card-description">
                  The basis of our mathematics teaching is a practical approach in the first instance using concrete objects and representations of concepts with many variations. 
                </p>
                <div class="card-footer">
                  <a href="/departments#maths" class="btn btn-link text-primary">
                    Check more <i class="ni ni-user-run ml-1"></i>
                  </a>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape shadow rounded-circle mx-auto bg-gradient-info" v-animate-onscroll="'animated zoomIn'">
                  <i class="fa fa-flask text-white"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Science</h4>
                <p class="card-description">
                  We as a team aim to develop the child’s scientific knowledge and understanding and to assist in the acquisition and progression of analytical skills.
                </p>
                <div class="card-footer">
                  <a href="/departments#science" class="btn btn-link text-primary">
                    Check more <i class="ni ni-user-run ml-1"></i>
                  </a>
                </div>
              </template>
            </card>
          </div> -->
          <!-- <div class="col-lg-4 col-md-6">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape shadow rounded-circle mx-auto bg-gradient-success"
                 v-animate-onscroll="'animated zoomIn'">
                  <span class="fa-2x font-weight-700 text-white">अ</span>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Hindi</h4>
                <p class="card-description">
                  You have the opportunity to play this game of life you need to
                  appreciate every moment. A lot of people don’t appreciate the
                  moment until it’s motivating the doers.
                </p>
                <div class="card-footer">
                  <a href="/departments#hindi" class="btn btn-link text-primary">
                    Check more <i class="ni ni-user-run ml-1"></i>
                  </a>
                </div>
              </template>
            </card>
          </div> -->
        </div>
        <br />
        <!-- <div class="row">
          <div class="col-lg-4 col-md-6">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape shadow rounded-circle mx-auto bg-gradient-danger" v-animate-onscroll="'animated zoomIn'">
                  <i class="fa fa-laptop text-white"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Computing</h4>
                <p class="card-description">
                  Our pupils are taught to use ICT equipment and software in order to communicate and handle information.  This work supports their problem solving, recording and expressive work in a cross curricular approach of applying their ICT skills.
                </p>
                <div class="card-footer">
                  <a href="/departments#computer" class="btn btn-link text-primary">
                    Check more <i class="ni ni-user-run ml-1"></i>
                  </a>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape shadow rounded-circle mx-auto bg-gradient-success" v-animate-onscroll="'animated zoomIn'">
                  <i class="ni ni-world text-white"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Our World – History, Geography</h4>
                <p class="card-description">
                  With-in the programme for Humanities Group (History, Political Science and Geography) we aim to help the child in Key Stage One to develop an understanding of the past and of the ways in which it has built up to the present.
                </p>
                <div class="card-footer">
                  <a href="/departments#socialscience" class="btn btn-link text-primary">
                    Check more <i class="ni ni-user-run ml-1"></i>
                  </a>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape shadow rounded-circle mx-auto bg-gradient-pink" v-animate-onscroll="'animated zoomIn'">
                  <i class="ni ni-palette text-white"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">The Arts – Art, Music, Design & Technology and Drama</h4>
                <p class="card-description">
                  This Departments provides the children with the opportunity to  use resources and materials in Art and to use multiple techniques in practical work. 
                </p>
                <div class="card-footer">
                  <a href="/departments#arts" class="btn btn-link text-primary">
                    Check more <i class="ni ni-user-run ml-1"></i>
                  </a>
                </div>
              </template>
            </card>
          </div>
        </div>
        <br /> -->
        <!-- <div class="row">
          <div class="col-lg-4 col-md-6">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape shadow rounded-circle mx-auto bg-gradient-warning" v-animate-onscroll="'animated zoomIn'">
                  <i class="ni ni-user-run text-white"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">A Healthy Life – Physical Education. Personal, Social & Health Education</h4>
                <p class="card-description">
                  Children in early years develop their gross motor and fine motor skills through everyday activities and sessions.
                </p>
                <div class="card-footer">
                  <a href="/departments#health" class="btn btn-link text-primary">
                    Check more <i class="ni ni-user-run ml-1"></i>
                  </a>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape shadow rounded-circle mx-auto bg-gradient-primary" v-animate-onscroll="'animated zoomIn'">
                  <i class="fa fa-venus-mars text-white"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Sex and Relationships Education</h4>
                <p class="card-description">
                  Sex education is taught directly and indirectly through the physical and science curriculum to all children at an age appropriate level.
                </p>
                <div class="card-footer">
                  <a href="/departments#sexeducation" class="btn btn-link text-primary">
                    Check more <i class="ni ni-user-run ml-1"></i>
                  </a>
                </div>
              </template>
            </card>
          </div>
          <div class="col-lg-4 col-md-6">
            <card class="card-project">
              <a href="javascript:;">
                <div
                  class="icon icon-lg icon-shape shadow rounded-circle mx-auto bg-gradient-info" v-animate-onscroll="'animated zoomIn'">
                  <i class="ni ni-trophy text-white"></i>
                </div>
              </a>
              <template slot="body">
                <h4 class="card-title mt-3">Extra-Curricular Activities</h4>
                <p class="card-description">
                  Extra-curricular activities take place each week and changes each half term to reflect children's interests. These include sports, street drama, computer coding (hackathon), art, choir, science and engineering, etc. 
                </p>
                <div class="card-footer">
                  <a href="/departments#extracurricular" class="btn btn-link text-primary">
                    Check more <i class="ni ni-user-run ml-1"></i>
                  </a>
                </div>
              </template>
            </card>
          </div>
        </div> -->
      </div>
    </div>
    <!-- infra -->
    <div class="section projects-3 bg-secondary" id="infra">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mr-auto ml-auto text-center">
            <h3 class="display-3 text-alpine">Facilities at Alpine</h3>
            <p class="lead mt-1 mb-5 font-weight-500 text-dark"> {{academic.facilities_description}} </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 mb-4 overflow-hidden" v-for="library in libraries" :key="library._id">
            <div class="blur"></div>
            <card
              class="card-background h-100"
              bodyClasses="px-0"
              :style="'background-image: url('+library.image+')'"
            >
              <template slot="body">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle mb-3"
                >
                  <i :class="library.icon"></i>
                </div>
                <a href="javascript:;">
                  <h3 class="card-title">{{library.name}}</h3>
                </a>
                <!-- <h6 class="desc text-white opacity-8">Our school library offers unending options for learning. It opens up lines for independent thinking and provides genuine research material. </h6> -->
                <router-link :to="'/'+library.linkId" class="btn btn-sm btn-secondary">
                  Read More
                </router-link>
              </template>
            </card>
          </div>
          <div class="col-lg-4 mb-4 overflow-hidden" v-for="lab in labs" :key="lab._id">
            <div class="blur"></div>
            <card
              class="card-background h-100"
              bodyClasses="px-0"
              :style="'background-image: url('+lab.image+')'"
            >
              <template slot="body">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle mb-3"
                >
                  <i :class="lab.icon"></i>
                </div>
                <a href="javascript:;">
                  <h3 class="card-title">{{lab.name}}</h3>
                </a>
                <!-- <h6 class="desc text-white opacity-8">The school have three well - equipped Senior, Junior and Primary computer Labs. This set up is completely managed by highly qualify faculty.</h6> -->
                <a :href="'/labs#'+lab.linkId" class="btn btn-sm btn-secondary">
                  Read More
                </a>
              </template>
            </card>
          </div>
          <div class="col-lg-4 mb-4 overflow-hidden" v-for="ourcampus in ourcampuses" :key="ourcampus._id">
            <div class="blur"></div>
            <card
              class="card-background h-100"
              bodyClasses="px-0"
              :style="'background-image: url('+ourcampus.image1+')'"
            >
              <template slot="body">
                <div
                  class="icon icon-shape bg-gradient-white shadow rounded-circle mb-3"
                >
                  <i :class="ourcampus.icon"></i>
                </div>
                <a href="javascript:;">
                  <h3 class="card-title">{{ourcampus.name}}</h3>
                </a>
                <!-- <h6 class="desc text-white opacity-8">Besides these, the school has a mathematics lab to familiarize the children to the concept of mathematics. With the help of models and charts, the fear for the subjects gets removed.</h6> -->
                <a :href="'/'+ourcampus.linkId" class="btn btn-sm btn-secondary">
                  Read More
                </a>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!-- results and TCs -->
    <div class="section project-2 pt-6" id="results">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-6 pr-5">
            <card class="card-blog card-background" data-animation="zooming">
              <div
                class="full-background"
                style="background-image: url('img/results_bg.jpg')"
              ></div>
              <template slot="body">
                <div class="content-center my-5">
                  <router-link to="/studentresults" class="h3 card-category text-white opacity-8">
                    Student Results
                  </router-link>
                </div>
              </template>
            </card>
          </div>
          <div class="col-md-6 col-lg-6 pl-5">
            <card class="card-blog card-background" data-animation="zooming">
              <div
                class="full-background"
                style="background-image: url('img/tc_bg.jpg')"
              ></div>
              <template slot="body">
                <div class="content-center my-5">
                  <router-link to="/tc" class="h3 card-category text-white opacity-8">
                    Transfer Certificates
                  </router-link>
                </div>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";
export default {
  bodyClass: "academics",
  components: {
  },
  data() {
    return {
      metaData:[],
      academic:[],
      departments:[],
      libraries:[],
      labs:[],
      ourcampuses:[],
      items: [
        'img/results_bg.jpg',
        'img/physics_lab.jpg',
        'img/sports4.jpg',
        'img/school_bul.jpeg',
      ],
      index: null,
      auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        authjson : { headers: { 'Content-Type':'application/json',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
    }
  },
  mounted () {          
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_academic',{ "details" :{},"offset":"0"},this.authjson)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.academic=response.data.details[0]; 
            }else{
              this.academic=[];
            }
          })
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_department',{ "details" :{},"offset":"0"},this.authjson)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.departments=response.data.details; 
            }else{
              this.departments=[];
            }
          })
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_library',{ "details" :{},"offset":"0"},this.authjson)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.libraries=response.data.details; 
            }else{
              this.libraries=[];
            }
          })
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_lab',{ "details" :{},"offset":"0"},this.authjson)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.labs=response.data.details; 
            }else{
              this.labs=[];
            }
          })
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_ourcampus',{ "details" :{},"offset":"0"},this.authjson)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.ourcampuses=response.data.details; 
            }else{
              this.ourcampuses=[];
            }
          })
        // read meta api call
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_metadata',{ "details" :{"page_url":'/'},"offset":"0"},this.authjson)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.metaData=response.data.details[0];
          
        }else{
          this.metaData={};
        }
      })
  },
};
</script>
<style>
.academics .card-background .card-body {
    padding-top: 20px;
    padding-bottom: 10px;
}
.academics .projects-3 .card-background {
    padding: 30px 25px;
}
.blur {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.academics .card-background .card-body {
  min-height: 130px;
}
.academics .card-background.card-blog {
    overflow: hidden;
    height: 180px;
}
.academics .bg-image {
  background-image: url(/img/contact_bg.jpeg);
}
</style>
