<template>
  <header class="header-global">
    <!--sub menu -->
    <nav class="navbar navbar-secondary headroom position-relative d-lg-block d-none navbar-expand-lg navbar-dark w-100 py-0" id="navbar-secondary" style="z-index: 10;">
      <div class="container-fluid">
        <a href="javascript:void()" class="navbar-brand">  </a>
        <button type="button" data-toggle="collapse" aria-label="Toggle navigation" class="navbar-toggler">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse">
          <div class="navbar-collapse-header">
            <div class="row">
              <div class="col-6 collapse-brand">
                <a href="javascript:void(0)"><img src="/img/brand/red.png"></a>
              </div>
              <div class="col-6 collapse-close">
                <button type="button" data-toggle="collapse" data-target="#undefined" aria-label="Toggle navigation" class="navbar-toggler">
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
          <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
            <li aria-haspopup="true" class="nav-item pt-2" v-for="menu in headermenu" :key="menu._id">
               <router-link role="button" v-if="!menu.highlights" :to="menu.link" data-toggle="dropdown" class="nav-link">
                  <!-- <i class="ni ni-app d-lg-none"></i> -->
                  <span class="nav-link-inner--text">{{menu.menu_name}}</span>
               </router-link>

               <router-link role="button" v-if="menu.highlights" :to="menu.link" data-toggle="dropdown" class="btn btn-round btn-danger btn-sm nav-link py-1 top-2">
                {{menu.menu_name}}
              </router-link>
            </li>
            <!-- <li aria-haspopup="true" class="nav-item pt-2">
              <router-link role="button" to="/about" data-toggle="dropdown" class="nav-link">
                <i class="ni ni-app d-lg-none"></i>
                <span class="nav-link-inner--text">About Us</span>
              </router-link>
            </li>
            <li aria-haspopup="true" class="nav-item pt-2">
              <router-link role="button" to="/contact-us" data-toggle="dropdown" class="nav-link">
                <i class="ni ni-app d-lg-none"></i>
                <span class="nav-link-inner--text">Contact Us</span>
              </router-link>
            </li>
            <li aria-haspopup="true" class="mt-2 mb--3">
              <router-link role="button" to="/contact-us" data-toggle="dropdown" class="btn btn-round btn-danger btn-sm nav-link py-1">
                Pay Fee Online
              </router-link>
            </li> -->
          </ul>
        </div>
      </div>
    </nav>
    <!--main menu -->
    <base-nav
      class="navbar-main headroom"
      id="navbar-main"
      :type="navbarType ? navbarType : ''"
      effect="dark"
      expand
      >
      <router-link slot="brand" class="navbar-brand mr-lg-5 mt-0 mt-lg--5 pt-1" to="/">
        <img src="/img/brand/bluenew.png" alt="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="javascript:void(0)">
            <img src="/img/brand/bluenew.png" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <!-- About us menu -->
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-left about-menu dropdown-menu-xl dropdown-submenu">
          <a
            role="button"
            slot="title"
            href="/about"
            class="nav-link"
            data-toggle="dropdown"
          >
            <!-- <i class="ni ni-app d-lg-none"></i> -->
            <span class="nav-link-inner--text">About Us</span>
          </a>
          <div class="dropdown-menu-inner row py-1 px-1 px-md-4">
            <div class="col pr-1">
              <a href="/about" class="d-block py-1 py-md-2">
                Know Alpine
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/about#whyus" class="d-block py-1 py-md-2">
                Why Us?
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/about#history" class="d-block py-1 py-md-2">
                History
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/about#missionvision" class="d-block py-1 py-md-2">
                Mission & Vision
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/about#message" class="d-block py-1 py-md-2">
                Director's Message
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
            </div>
            <div class="col pr-1">
              <a href="/about#message" class="d-block py-1 py-md-2">
                Principal's Message
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/about#board" class="d-block py-1 py-md-2">
                Board of Directors
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/about#affiliations" class="d-block py-1 py-md-2">
                Affiliations
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/about#socialresponsibility" class="d-block py-1 py-md-2">
                Social Responsibility
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="https://drive.google.com/drive/folders/1JCbNnMlVyYV1S4oantHhSSz40T-8_tiu" target="_blank" class="d-block py-1 py-md-2">
                Mandatory Public Disclosure
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
            </div>
          </div>
        </base-dropdown>
        <!-- acaemics menu item -->
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-left about-menu dropdown-menu-xl dropdown-submenu">
          <a
            role="button"
            slot="title"
            href="/academics"
            class="nav-link"
            data-toggle="dropdown"
          >
            <!-- <i class="ni ni-app d-lg-none"></i> -->
            <span class="nav-link-inner--text">Academics</span>
          </a>
          <div class="dropdown-menu-inner row py-1 px-1 px-md-4">
            <div class="col pr-1">
              <a href="/academics" class="d-block py-1 py-md-2">
                Overview
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/academics#curriculum" class="d-block py-1 py-md-2">
                Curriculum
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/academics#departments" class="d-block py-1 py-md-2">
                Departments
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
            </div>
            <div class="col pr-1">
              <a href="/academics#infra" class="d-block py-1 py-md-2">
                Infrastructure
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/academics#results" class="d-block py-1 py-md-2">
                Results
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/academics#results" class="d-block py-1 py-md-2">
                Transfer Certificates
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
            </div>
          </div>
        </base-dropdown>
        <!-- beyond acaemics menu item -->
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-left about-menu dropdown-menu-xl dropdown-submenu">
          <router-link
            role="button"
            slot="title"
            to="/beyondacademics"
            class="nav-link"
            data-toggle="dropdown"
          >
            <!-- <i class="ni ni-app d-lg-none"></i> -->
            <span class="nav-link-inner--text">Beyond Academics</span>
          </router-link>
          <div class="dropdown-menu-inner row py-1 px-1 px-md-4">
            <div class="col pr-1">
              <a href="/beyondacademics" class="d-block py-1 py-md-2">
                Sports
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/beyondacademics#creativity" class="d-block py-1 py-md-2">
                Creative
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/beyondacademics#houses" class="d-block py-1 py-md-2">
                Houses & Council
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/beyondacademics#trips" class="d-block py-1 py-md-2">
                Trips
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
            </div>
            <div class="col pr-1">
              <a href="/beyondacademics#event" class="d-block py-1 py-md-2">
                Events
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/beyondacademics#traditional" class="d-block py-1 py-md-2">
                Traditional Touch
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/beyondacademics#community" class="d-block py-1 py-md-2">
                Community Service
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/beyondacademics#gallery" class="d-block py-1 py-md-2">
                Gallery
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
            </div>
          </div>
        </base-dropdown>
        <!-- admissions menu item -->
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-right about-menu dropdown-menu-xl dropdown-submenu">
          <a
            role="button"
            slot="title"
            href="/admissions"
            class="nav-link"
            data-toggle="dropdown"
          >
            <!-- <i class="ni ni-app d-lg-none"></i> -->
            <span class="nav-link-inner--text">Admissions</span>
          </a>
          <div class="dropdown-menu-inner row py-1 px-1 px-md-4">
            <div class="col pr-1">
              <a href="/admissions" class="d-block py-1 py-md-2">
                Overview
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/admissions#procedure" class="d-block py-1 py-md-2">
                Procedure
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/admissions#forms" class="d-block py-1 py-md-2">
                Forms
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/admissions#feestructure" class="d-block py-1 py-md-2">
                Fee Structure
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
            </div>
            <div class="col pr-1">
              <a href="/admissions#feepayment" class="d-block py-1 py-md-2">
                Fee Payment
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/admissions#feepayment" class="d-block py-1 py-md-2">
                Transfer Certificates
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/admissions#faq" class="d-block py-1 py-md-2">
                FAQs
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
            </div>
          </div>
        </base-dropdown>
        <!-- pre-primary wing -->
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-right about-menu dropdown-menu-xl dropdown-submenu">
          <a
            role="button"
            slot="title"
            href="/preprimary"
            class="nav-link"
            data-toggle="dropdown"
          >
            <!-- <i class="ni ni-app d-lg-none"></i> -->
            <span class="nav-link-inner--text">Pre-Primary Wing</span>
          </a>
          <div class="dropdown-menu-inner row py-1 px-1 px-md-4">
            <div class="col pr-1">
              <a href="/preprimary" class="d-block py-1 py-md-2">
                Overview
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/preprimary#programs" class="d-block py-1 py-md-2">
                Programs
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/preprimary#activities" class="d-block py-1 py-md-2">
                Activities
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
            </div>
            <div class="col pr-1">
              <a href="/preprimary#admissions" class="d-block py-1 py-md-2">
                Admission
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
              <a href="/preprimary#gallery" class="d-block py-1 py-md-2">
                Gallery
                <i class="fa fa-chevron-right d-none d-md-inline"></i>
              </a>
            </div>
          </div>
        </base-dropdown>
        <li aria-haspopup="true" class="nav-item d-block d-lg-none" v-for="menu in headermenu" :key="menu._id">
           <router-link role="button" :to="menu.link" data-toggle="dropdown" class="nav-link">
              <!-- <i class="ni ni-app d-lg-none"></i> -->
              <span class="nav-link-inner--text">{{menu.menu_name}}</span>
           </router-link>
        </li>
        <!-- <base-dropdown class="nav-item" menu-classes="dropdown-menu-right">
          <a
            role="button"
            slot="title"
            href="#/admissions"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-app d-lg-none"></i>
            <span class="nav-link-inner--text">Admissions</span>
          </a>
          <router-link to="/sections#headers" class="dropdown-item">
            <i class="ni ni-album-2 text-info"></i>
            Headers
          </router-link>
          <router-link to="/sections#features" class="dropdown-item">
            <i class="ni ni-settings text-danger"></i>
            Features
          </router-link>
          <router-link to="/sections#blogs" class="dropdown-item">
            <i class="ni ni-align-left-2 text-primary"></i>
            Blogs
          </router-link>
          <router-link to="/sections#teams" class="dropdown-item">
            <i class="ni ni-user-run text-danger"></i>
            Teams
          </router-link>
          <router-link to="/sections#projects" class="dropdown-item">
            <i class="ni ni-paper-diploma text-purple"></i>
            Projects
          </router-link>
          <router-link to="/sections#pricing" class="dropdown-item">
            <i class="ni ni-money-coins text-pink"></i>
            Pricing
          </router-link>
          <router-link to="/sections#testimonials" class="dropdown-item">
            <i class="ni ni-chat-round text-muted"></i>
            Testimonials
          </router-link>
          <router-link to="/sections#contact-us" class="dropdown-item">
            <i class="ni ni-mobile-button text-success"></i>
            Contact Us
          </router-link>
          <router-link to="/sections#tables" class="dropdown-item">
            <i class="ni ni-chart-bar-32 text-default"></i>
            Tables
          </router-link>
          <router-link to="/sections#accordion" class="dropdown-item">
            <i class="ni ni-collection text-yellow"></i>
            Accordion
          </router-link>
        </base-dropdown> -->
        <!-- <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl dropdown-menu-right">
          <a
            role="button"
            slot="title"
            href="/elements"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Pre-Primary Wing</span>
          </a>
          <router-link to="/landing"></router-link>

          <div class="dropdown-menu-inner">
            <router-link to="/" class="media d-flex align-items-center">
              <div
                class="icon icon-shape bg-gradient-primary rounded-circle text-white"
              >
                <i class="ni ni-spaceship"></i>
              </div>
              <div class="media-body ml-3">
                <h6 class="heading text-primary mb-md-1">Elements</h6>
                <p class="description d-none d-md-inline-block mb-0">
                  Learn how to use compiling Scss, change brand colors and more.
                </p>
              </div>
            </router-link>
            <router-link
              to="/presentation"
              class="media d-flex align-items-center"
            >
              <div
                class="icon icon-shape bg-gradient-warning rounded-circle text-white"
              >
                <i class="ni ni-palette"></i>
              </div>
              <div class="media-body ml-3">
                <h6 class="heading text-primary mb-md-1">Presentation</h6>
                <p class="description d-none d-md-inline-block mb-0">
                  Learn more about colors, typography, icons and the grid system
                  we used for .
                </p>
              </div>
            </router-link>
          </div>
          <div class="dropdown-menu-footer">
            <a
              class="dropdown-item"
              href="https://www.creative-tim.com/learning-lab/vue/overview/argon-design-system"
            >
              <i class="ni ni-atom"></i>
              Getting Started
            </a>
            <a
              class="dropdown-item"
              href="https://www.creative-tim.com/learning-lab/vue/colors/argon-design-system"
            >
              <i class="ni ni-ui-04"></i>
              Foundation
            </a>
            <a
              class="dropdown-item"
              href="https://www.creative-tim.com/learning-lab/vue/alerts/argon-design-system"
            >
              <i class="ni ni-archive-2"></i>
              Components
            </a>
          </div>
        </base-dropdown> -->
        <!-- <base-dropdown class="nav-item" menu-classes="dropdown-menu-right">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-single-copy-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Examples</span>
          </a>
          <router-link to="/about" class="dropdown-item">
            <i class="ni ni-tie-bow text-warning"></i>
            About-us
          </router-link>
          <router-link to="/blog-post" class="dropdown-item">
            <i class="ni ni-align-center text-info"></i>
            Blog Post
          </router-link>
          <router-link to="/blog-posts" class="dropdown-item">
            <i class="ni ni-chart-bar-32 text-yellow"></i>
            Blog Posts
          </router-link>
          <router-link to="/contact-us" class="dropdown-item">
            <i class="ni ni-square-pin text-danger"></i>
            Contact Us
          </router-link>
          <router-link to="/landing-page" class="dropdown-item">
            <i class="ni ni-planet text-purple"></i>
            Landing Page
          </router-link>
          <router-link to="/pricing" class="dropdown-item">
            <i class="ni ni-money-coins text-success"></i>
            Pricing
          </router-link>
          <router-link to="/ecommerce" class="dropdown-item">
            <i class="ni ni-box-2 text-pink"></i>
            Ecommerce Page
          </router-link>
          <router-link to="/product-page" class="dropdown-item">
            <i class="ni ni-bag-17 text-primary"></i>
            Product Page
          </router-link>
          <router-link to="/profile-page" class="dropdown-item">
            <i class="ni ni-circle-08 text-info"></i>
            Profile Page
          </router-link>
          <router-link to="/error" class="dropdown-item">
            <i class="ni ni-button-power text-warning"></i>
            404 Error Page
          </router-link>
          <router-link to="/500-error" class="dropdown-item">
            <i class="ni ni-ungroup text-yellow"></i>
            500 Error Page
          </router-link>
        </base-dropdown>
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-right">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-tablet-button d-lg-none"></i>
            <span class="nav-link-inner--text">App Pages</span>
          </a>
          <router-link to="/account" class="dropdown-item">
            <i class="ni ni-lock-circle-open text-muted"></i>
            Account Settings
          </router-link>
          <router-link to="/login" class="dropdown-item">
            <i class="ni ni-tv-2 text-danger"></i>
            Login Page
          </router-link>
          <router-link to="/register" class="dropdown-item">
            <i class="ni ni-air-baloon text-pink"></i>
            Register Page
          </router-link>
          <router-link to="/reset" class="dropdown-item">
            <i class="ni ni-atom text-info"></i>
            Reset Page
          </router-link>
          <router-link to="/invoice" class="dropdown-item">
            <i class="ni ni-bullet-list-67 text-success"></i>
            Invoice Page
          </router-link>
          <router-link to="/checkout" class="dropdown-item">
            <i class="ni ni-basket text-orange"></i>
            Checkout Page
          </router-link>
          <router-link to="/chat-page" class="dropdown-item">
            <i class="ni ni-chat-round text-primary"></i>
            Chat Page
          </router-link>
        </base-dropdown> -->
      </ul>
    </base-nav>
  </header>
  
</template>
<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import BaseDropdown from "@/components/BaseDropdown";
import Headroom from "headroom.js";
import axios from "axios";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  },
  props: {
    navbarType: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      headermenu:[],
       auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        authjson : { headers: { 'Content-Type':'application/json',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
    };
  },
  mounted: function() {
    axios
        .post(process.env.VUE_APP_API_ENDPOINT+'read_header_menu',{ "details" :{},"offset":"0"},this.authjson)
        .then(response => {
            console.log(response);
            if(response.data.details){
            this.headermenu=response.data.details;
            }
        })
    let headroom = new Headroom(document.getElementById("navbar-main"), {
      offset: 300,
      tolerance: {
        up: 0,
        down: 0
      }
    });
    headroom.init();

    let headroom2 = new Headroom(document.getElementById("navbar-secondary"), {
      offset: 300,
      tolerance: {
        up: 0,
        down: 0
      }
    });
    headroom2.init();
  }
};
</script>
<style>
.headroom--unpinned {
  transform: translateY(0%) !important;
}
.navbar-main.headroom {
  z-index: 9999;
}
.navbar-main {
  border-bottom: 0.0625rem solid #e9ecef7a !important;
}
.navbar-main .navbar-nav .nav-link .nav-link-inner--text {
    text-transform: uppercase;
    font-weight: 600;
}
.navbar-secondary .navbar-nav .nav-link .nav-link-inner--text {
    font-weight: 300;
}
.rounded-xl {
  border-radius: 1rem;
}
.w-70 {
  width: 70% !important;
}
.w-30 {
    width: 30% !important;
}
.blogs-5 .card-blog .card-image {
  height: 230px;
  overflow: hidden;
}
.fa-05x {
  font-size: .8rem !important;
}
.glide__arrow--right {
    right: 46% !important;
}
.glide__arrow--left {
    left: 46% !important;
}
.navbar-main.headroom--pinned {
  top: 44px;
}
/*.navbar-main.headroom--pinned .navbar-brand {
  margin-top: 0px !important;
}
.navbar-main.headroom--pinned .navbar-brand img {
  height: 50px;
}*/
.navbar-main.headroom--pinned.headroom--top .navbar-brand {
  margin-top: -3rem !important;
}
header {
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
}
.h-70vh {
  height: 70vh !important;
}
.navbar-main .dropdown-item.active, .dropdown-item:active {
    color: #212529;
    background-color: #e9e9e9;
    border-radius: 5px;
}
.dropdown-submenu {
  border-bottom: 7px solid #b84528!important;
}
.dropdown-submenu a {
  font-size: .95rem;
  color: black;
}
.dropdown-submenu a:hover {
    color: #b84528;
}
.dropdown-submenu a:hover i {
    transform: translateX(5px);
    opacity: 1;
}
.dropdown-submenu a i {
    font-size: .6rem;
    opacity: 0;
    transition: transform .3s ease;
    transform: translateX(0);
}
.text-alpine {
  color: #cf3d3e;
}
.badge-alpine {
    color: #fff;
    background-color: #cf3d3e;
}
.bg-alpine-dark {
  background-color: #361313;
}
.bg-image {
  background-size: cover;
}
.bg-image:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #3e211a;
    opacity: .9;
}
.background-cover {
  background-size: cover;
  background-position: center;
}
.br-0 {
  border-radius: 0px;
}
.img-square {
  width: 100%;
  height: 100%;
}
.centered-crop {
  object-fit: cover;
  object-position: center;
  height: inherit;
  width: inherit;
}
.border-bottom-lg {
    border-bottom-width: 5px !important;
}
.border-bottom.border-bottom-lg {
    border-bottom-width: 5px !important;
}
/* gallery css here */
.images-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: -10px;
}
.images-wrapper .image-wrapper {
    margin-bottom: 10px;
    width: calc(50% - 10px);
    margin-right: 10px;
}
.images-wrapper .image-wrapper .gallery_image {
    cursor: pointer;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100%;
    border-radius: 5px;
}
.v-html p {
  margin-bottom: 0px;
}
@media (min-width: 992px) {
  .navbar-secondary .navbar-nav .nav-link {
    padding-bottom: 0rem;
  }
  .navbar-main.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.7rem;
  }
  .navbar-main .navbar-nav .nav-item.dropdown .nav-link {
    pointer-events: all !important;
  }
}
@media (max-width: 768px) {
  .page-header .display-2 {
    font-size: 2rem;
  }
  .page-header p.lead {
    font-size: 1rem;
  }
  .page-header .container {
    padding-top: 14vh;
  }
}
@media (min-width: 768px) { 
  .images-wrapper {
    margin-right: -20px;
  }
  .images-wrapper .image-wrapper {
    margin-bottom: 20px;
    width: calc(19.66667% - 20px);
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
