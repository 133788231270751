<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/mission_vision.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-7 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                Every kid has a right to<span class="text-light">
                quality education.</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-1">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto text-center">
            <!-- <span class="badge badge-primary badge-pill mb-3">About Us</span> -->
            <h3 class="display-3 text-alpine">{{social_responsibility.title}}</h3>
            <p class="lead mt-1 font-weight-500">
              {{social_responsibility.short_description}}
            </p>
          </div>
        </div>
      </div>
      <div class="container mt-4 mb-5">
        <div class="row text-left justify-content-center">
          <div class="col-lg-12 col-12 mb-3">
            <p class="pb-2 mt-0 text-justify" v-html="social_responsibility.long_description">
            </p>
          </div>
          <div class="col-lg-4 col-12 mb-3" v-for="image in social_responsibility.images">
            <img :src="image" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <!-- <div class="col-lg-4 col-12 mb-3">
            <img src="img/results_bg.jpg" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <img src="img/results_bg.jpg" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <img src="img/results_bg.jpg" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <img src="img/results_bg.jpg" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div>
          <div class="col-lg-4 col-12 mb-3">
            <img src="img/results_bg.jpg" class="img-fluid shadow rounded" v-animate-onscroll="'animated fadeInLeft'">
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";
export default {
  bodyClass: "key_features",
  components: {
  },
  data() {
    return {
      metaData:[],
      social_responsibility:[],
      items: [
        'img/results_bg.jpg',
        'img/physics_lab.jpg',
        'img/sports4.jpg',
        'img/school_bul.jpeg',
      ],
      index: null,
      auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        authjson : { headers: { 'Content-Type':'application/json',
          'Authorization':'alpine#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
    }
  },
  mounted () {          
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_social_responsibility',{ "details" :{},"offset":"0"},this.authjson)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.social_responsibility=response.data.details[0]; 
            }else{
              this.social_responsibility=[];
            }
          })
        // read meta api call
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_metadata',{ "details" :{"page_url":'/'},"offset":"0"},this.authjson)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.metaData=response.data.details[0];
          
        }else{
          this.metaData={};
        }
      })
  },
};
</script>
<style>
.key_features  .info-horizontal {
  max-width: 100% !important;
}
</style>
