import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Presentation from "./views/Presentation.vue";
import Components from "./views/Components.vue";
import Sections from "./views/Sections.vue";
import Home from "./views/Home.vue";
import About from "./views/About.vue";
import AboutOld from "./views/AboutOld.vue";
import MissionVision from "./views/MissionVision.vue";
import KeyFeatures from "./views/KeyFeatures.vue";
import DirectorMessage from "./views/DirectorMessage.vue";
import PrincipalMessage from "./views/PrincipalMessage.vue";
import Infrastructure from "./views/Infrastructure.vue";
import SmartClasses from "./views/SmartClasses.vue";
import Labs from "./views/Labs.vue";
import Library from "./views/Library.vue";
import Academics from "./views/Academics.vue";
import BoardMembers from "./views/BoardMembers.vue";
import GyaanJyoti from "./views/GyaanJyoti.vue";
import Departments from "./views/Departments.vue";
import StudentResults from "./views/StudentResults.vue";
import BeyondAcademics from "./views/BeyondAcademics.vue";
import Admissions from "./views/Admissions.vue";
import PrePrimary from "./views/PrePrimary.vue";
import Pre from "./views/Pre.vue";
import Nursery from "./views/Nursery.vue";
import Kindergarten from "./views/Kindergarten.vue";
import Events from "./views/Events.vue";
import Tc from "./views/Tc.vue";
import BlogPosts from "./views/BlogPosts.vue";
import BlogPost from "./views/BlogPost.vue";
import ContactUs from "./views/ContactUs.vue";
import LandingPage from "./views/LandingPage.vue";
import Pricing from "./views/Pricing.vue";
import Ecommerce from "./views/Ecommerce.vue";
import ProfilePage from "./views/ProfilePage.vue";
import Error from "./views/Error.vue";
import Error500 from "./views/Error500.vue";
import Register from "./views/Register.vue";
import Login from "./views/Login.vue";
import Reset from "./views/Reset.vue";
import Invoice from "./views/Invoice.vue";
import ChatPage from "./views/ChatPage.vue";
import ProductPage from "./views/ProductPage.vue";
import Account from "./views/Account.vue";
import Checkout from "./views/Checkout.vue";
import Gallery from "./views/Gallery.vue";
import Exam from "./views/Exam.vue";
import SubmitExam from "./views/SubmitExam.vue";
import Result from "./views/Downloadresult.vue";

Vue.use(Router);

export default new Router({
  mode: 'history',
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "home",
      components: {
        header: AppHeader,
        default: Home,
        footer: AppFooter
      }
    },
    {
      path: "/components",
      name: "components",
      components: {
        header: AppHeader,
        default: Components,
        footer: AppFooter
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/presentation",
      name: "presentation",
      components: {
        header: AppHeader,
        default: Presentation,
        footer: AppFooter
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/sections",
      name: "sections",
      components: {
        header: AppHeader,
        default: Sections,
        footer: AppFooter
      },
      props: {
        header: { navbarType: "default" }
      }
    },
    {
      path: "/about",
      name: "about",
      components: {
        header: AppHeader,
        default: About,
        footer: AppFooter
      }
    },
    {
      path: "/missionvision",
      name: "mission and vision",
      components: {
        header: AppHeader,
        default: MissionVision,
        footer: AppFooter
      }
    },
    {
      path: "/keyfeatures",
      name: "Key Features",
      components: {
        header: AppHeader,
        default: KeyFeatures,
        footer: AppFooter
      }
    },
    {
      path: "/directormessage",
      name: "Director's Message",
      components: {
        header: AppHeader,
        default: DirectorMessage,
        footer: AppFooter
      }
    },
    {
      path: "/principalmessage",
      name: "Principal's Message",
      components: {
        header: AppHeader,
        default: PrincipalMessage,
        footer: AppFooter
      }
    },
    {
      path: "/infrastructure",
      name: "Infrastructure",
      components: {
        header: AppHeader,
        default: Infrastructure,
        footer: AppFooter
      }
    },
    {
      path: "/smartclasses",
      name: "Smart Classes",
      components: {
        header: AppHeader,
        default: SmartClasses,
        footer: AppFooter
      }
    },
    {
      path: "/labs",
      name: "Laboratories",
      components: {
        header: AppHeader,
        default: Labs,
        footer: AppFooter
      }
    },
    {
      path: "/library",
      name: "Library",
      components: {
        header: AppHeader,
        default: Library,
        footer: AppFooter
      }
    },
    {
      path: "/academics",
      name: "Academics",
      components: {
        header: AppHeader,
        default: Academics,
        footer: AppFooter
      }
    },
    {
      path: "/boardmembers",
      name: "Board Members",
      components: {
        header: AppHeader,
        default: BoardMembers,
        footer: AppFooter
      }
    },
    {
      path: "/gyaanjyoti",
      name: "Gyaan Jyoti",
      components: {
        header: AppHeader,
        default: GyaanJyoti,
        footer: AppFooter
      }
    },
    {
      path: "/departments",
      name: "Departments",
      components: {
        header: AppHeader,
        default: Departments,
        footer: AppFooter
      }
    },
    {
      path: "/studentresults",
      name: "Student Results",
      components: {
        header: AppHeader,
        default: StudentResults,
        footer: AppFooter
      }
    },
    {
      path: "/beyondacademics",
      name: "BeyondAcademics",
      components: {
        header: AppHeader,
        default: BeyondAcademics,
        footer: AppFooter
      }
    },
    {
      path: "/admissions",
      name: "Admissions",
      components: {
        header: AppHeader,
        default: Admissions,
        footer: AppFooter
      }
    },
    {
      path: "/preprimary",
      name: "Pre Primary",
      components: {
        header: AppHeader,
        default: PrePrimary,
        footer: AppFooter
      }
    },
    {
      path: "/pre",
      name: "Pre",
      components: {
        header: AppHeader,
        default: Pre,
        footer: AppFooter
      }
    },
    {
      path: "/nursery",
      name: "Nursery",
      components: {
        header: AppHeader,
        default: Nursery,
        footer: AppFooter
      }
    },
    {
      path: "/kg",
      name: "Kindergarten",
      components: {
        header: AppHeader,
        default: Kindergarten,
        footer: AppFooter
      }
    },
    {
      path: "/events",
      name: "Events",
      components: {
        header: AppHeader,
        default: Events,
        footer: AppFooter
      }
    },
    {
      path: "/tc",
      name: "Transfer Certificate",
      components: {
        header: AppHeader,
        default: Tc,
        footer: AppFooter
      }
    },
    {
      path: "/aboutold",
      name: "about old",
      components: {
        header: AppHeader,
        default: AboutOld,
        footer: AppFooter
      }
    },
    {
      path: "/blog-posts",
      name: "blog-posts",
      components: {
        header: AppHeader,
        default: BlogPosts,
        footer: AppFooter
      }
    },
    {
      path: "/blog-post/:id",
      name: "blog-post",
      components: {
        header: AppHeader,
        default: BlogPost,
        footer: AppFooter
      }
    },
    {
      path: "/contact-us",
      name: "contact-us",
      components: {
        header: AppHeader,
        default: ContactUs,
        footer: AppFooter
      }
    },
    {
      path: "/landing-page",
      name: "landing-page",
      components: {
        header: AppHeader,
        default: LandingPage,
        footer: AppFooter
      }
    },
    {
      path: "/pricing",
      name: "pricing",
      components: {
        header: AppHeader,
        default: Pricing,
        footer: AppFooter
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/ecommerce",
      name: "ecommerce",
      components: {
        header: AppHeader,
        default: Ecommerce,
        footer: AppFooter
      }
    },
    {
      path: "/profile-page",
      name: "profile-page",
      components: {
        header: AppHeader,
        default: ProfilePage,
        footer: AppFooter
      }
    },
    {
      path: "/error",
      name: "error",
      components: {
        header: AppHeader,
        default: Error
      },
      props: {
        header: { navbarType: "default" }
      }
    },
    {
      path: "/500-error",
      name: "500-error",
      components: {
        header: AppHeader,
        default: Error500
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/register",
      name: "register",
      components: {
        header: AppHeader,
        default: Register,
        footer: AppFooter
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        header: AppHeader,
        default: Login,
        footer: AppFooter
      }
    },
    {
      path: "/reset",
      name: "reset",
      components: {
        header: AppHeader,
        default: Reset,
        footer: AppFooter
      }
    },
    {
      path: "/invoice",
      name: "invoice",
      components: {
        header: AppHeader,
        default: Invoice,
        footer: AppFooter
      }
    },
    {
      path: "/chat-page",
      name: "chat-page",
      components: {
        header: AppHeader,
        default: ChatPage,
        footer: AppFooter
      }
    },
    {
      path: "/product-page",
      name: "product-page",
      components: {
        header: AppHeader,
        default: ProductPage,
        footer: AppFooter
      }
    },
    {
      path: "/account",
      name: "account",
      components: {
        header: AppHeader,
        default: Account,
        footer: AppFooter
      }
    },
    {
      path: "/checkout",
      name: "checkout",
      components: {
        header: AppHeader,
        default: Checkout,
        footer: AppFooter
      }
    },
    {
      path: "/gallery/:id",
      name: "Gallery",
      components: {
        header: AppHeader,
        default: Gallery,
        footer: AppFooter
      }
    },
    {
      path: "/exam",
      name: "Download Question paper",
      components: {
        header: AppHeader,
        default: Exam,
        footer: AppFooter
      }
    },
    {
      path: "/submit-exam",
      name: "Submit answer sheet",
      components: {
        header: AppHeader,
        default: SubmitExam,
        footer: AppFooter
      }
    },
    {
      path: "/result",
      name: "Result",
      components: {
        header: AppHeader,
        default: Result,
        footer: AppFooter
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
