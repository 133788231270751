<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/mission_vision.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-7 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                A brief of our school's<span class="text-light">
                performance report.</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section pricing-5" id="pricing-6">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-6">
                <h3 class="display-3 mt-2 text-alpine">Class-wise results</h3>
              </div>
              <div class="col-md-6 text-right">
                <tabs
                  v-model="pricing5Tab"
                  pills
                  type="primary"
                  class="my-2"
                  no-content-space
                  tabNavClasses=""
                >
                  <select class="form-control d-inline w-auto mr-2" v-model="selected_class" @change="gettopperbyclass()">
                    <option value="">Select class</option>
                    <option v-for="classes in classdata" :key="classes._id" :value="classes._id">{{classes.class_name}}</option>
                    <!-- <option value="confirm">Confirmed</option>
                    <option value="preparing">Preparing</option>
                    <option value="ready">Ready</option>
                    <option value="dispatched">Dispatched</option>
                    <option value="delivered">Completed</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="returned">Returned</option> -->
                  </select>
                </tabs>
              </div>
            </div>
          </div>
          <div class="col-md-10 ml-auto mr-auto" v-if="class_oid">
            <div class="tab-content tab-space">
              <div
                v-if="pricing5Tab === '10th'"
                class="tab-pane active"
                id="personal"
                >
                <div class="row">
                  <p class="lead mt-0 px-3 text-dark font-weight-500" v-if="topperdata[0].class_oid.class_name">
                    CLASS {{topperdata[0].class_oid.class_name}} ({{topperdata[0].year}}) TOPPERS LIST
                  </p>
                  <div class="col-md-12">
                    <div class="card card-pricing">
                      <div>
                        <el-table
                          class="table table-responsive table-flush align-items-center"
                          header-row-class-name="thead-light"
                          :data="topperdata"
                          >
                          <el-table-column
                            label="NAME OF THE STUDENT"
                            min-width="310px"
                            prop="name"
                            sortable
                            scope="row"
                          >
                            <template v-slot="{ row }">
                              <div class="align-items-center">
                                <div class="media-body">
                                  <img alt="Image placeholder" class="avatar-sm avatar mr-3" src="img/faces/team-1.jpg" />
                                  <span class="name mb-0 text-sm">{{ row.student_oid.user_name }}</span>
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column label="Section" prop="section" min-width="140px" sortable>
                          </el-table-column>
                          <el-table-column
                            label="Percentage"
                            prop="percentage"
                            min-width="240px"
                            sortable
                            header-align="right"
                            headerClasses="text-right"
                            >
                            <template v-slot="{ row }">
                              <div class="d-flex align-items-center justify-content-end">
                                <span class="completion mr-2">{{ row.percentage }}%</span>
                                <div>
                                  <base-progress
                                    type="success"
                                    :value="row.percentage"
                                  ></base-progress>
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="pricing5Tab === '11th'" class="tab-pane active" id="11th">
                <div class="row">
                  <p class="lead mt-0 px-3 text-dark font-weight-500">
                    CLASS XI (2020) TOPPERS LIST
                  </p>
                  <div class="col-md-12">
                    <div class="card card-pricing">
                      <div>
                        <el-table
                          class="table table-responsive table-flush align-items-center"
                          header-row-class-name="thead-light"
                          :data="topper11data"
                          >
                          <el-table-column
                            label="NAME OF THE STUDENT"
                            min-width="310px"
                            prop="name"
                            sortable
                            scope="row"
                          >
                            <template v-slot="{ row }">
                              <div class="align-items-center">
                                <div class="media-body">
                                  <img alt="Image placeholder" class="avatar-sm avatar mr-3" src="img/faces/team-1.jpg" />
                                  <span class="name mb-0 text-sm">{{ row.student_oid.user_name }}</span>
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column label="Section" prop="section" min-width="140px" sortable>
                          </el-table-column>
                          <el-table-column
                            label="Percentage"
                            prop="percentage"
                            min-width="240px"
                            sortable
                            header-align="right"
                            headerClasses="text-right"
                            >
                            <template v-slot="{ row }">
                              <div class="d-flex align-items-center justify-content-end">
                                <span class="completion mr-2">{{ row.percentage }}%</span>
                                <div>
                                  <base-progress
                                    type="success"
                                    :value="row.percentage"
                                  ></base-progress>
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="pricing5Tab === '12th'" class="tab-pane active" id="12th">
                <div class="row">
                  <p class="lead mt-0 px-3 text-dark font-weight-500">
                    CLASS XII (2020) TOPPERS LIST
                  </p>
                  <div class="col-md-12">
                    <div class="card card-pricing">
                      <div>
                        <el-table
                          class="table table-responsive table-flush align-items-center"
                          header-row-class-name="thead-light"
                          :data="topper12data"
                          >
                          <el-table-column
                            label="NAME OF THE STUDENT"
                            min-width="310px"
                            prop="name"
                            sortable
                            scope="row"
                          >
                            <template v-slot="{ row }">
                              <div class="align-items-center">
                                <div class="media-body">
                                  <img alt="Image placeholder" class="avatar-sm avatar mr-3" src="img/faces/team-1.jpg" />
                                  <span class="name mb-0 text-sm">{{ row.student_oid.user_name }}</span>
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column label="Section" prop="section" min-width="140px" sortable>
                          </el-table-column>
                          <el-table-column
                            label="Percentage"
                            prop="percentage"
                            min-width="240px"
                            sortable
                            header-align="right"
                            headerClasses="text-right"
                            >
                            <template v-slot="{ row }">
                              <div class="d-flex align-items-center justify-content-end">
                                <span class="completion mr-2">{{ row.percentage }}%</span>
                                <div>
                                  <base-progress
                                    type="success"
                                    :value="row.percentage"
                                  ></base-progress>
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mt-5 text-center" v-if!="class_oid">
            <img src="img/empty.png" class="img-fluid">
            <p class="lead">No results found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import axios from "axios";
import {
  Table,
  TableColumn,
  // DropdownMenu,
} from "element-ui";

export default {
  bodyClass: "key_features",
  components: {
    Tabs,
    //TabPane,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      selected_class:'61814205bb203f4610f37260',
      pricing5Tab: "10th",
      projects: [
        {
          title: "Hema",
          budget: "Sec A",
          statusType: "warning",
          percentage: 60
        },
        {
          title: "Rekha",
          budget: "Sec A",
          statusType: "success",
          percentage: 100
        },
        {
          title: "Jaya",
          budget: "Sec A",
          statusType: "danger",
          percentage: 72
        },
        {
          title: "Sushma",
          budget: "Sec A",
          statusType: "info",
          percentage: 90
        },
        {
          title: "Amar",
          budget: "Sec A",
          statusType: "success",
          percentage: 100
        },
        {
          title: "Akbar",
          budget: "Sec A",
          statusType: "warning",
          percentage: 60
        },
        {
          title: "Anthony",
          budget: "Sec A",
          statusType: "success",
          percentage: 100
        },
        {
          title: "Steve",
          budget: "Sec A",
          statusType: "danger",
          percentage: 72
        },
        {
          title: "Roger",
          budget: "Sec A",
          statusType: "success",
          percentage: 100
        }
      ],
      auth : {
        headers: { 'Content-Type':'application/x-www-form-urlencoded',
        'Authorization':'caption#' + localStorage.getItem('accessToken'),
        '_id':localStorage.getItem('_id')}
      },
      auth1 : { headers: { 'Content-Type':'application/json',
        'Authorization':'caption#' + localStorage.getItem('accessToken'),
        '_id':localStorage.getItem('_id')}
      },
      topperdata:[],
      topper11data:[],
      topper12data:[],
      classdata:[],
      currentPage: 1
    };
  },
  mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_topper',{ "details" :{"class_oid":"61814205bb203f4610f37260"}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.topperdata=response.data.details;
        this.datanotfound=false;
        }else{
          this.topperdata=[];
        } this.datanotfound=true;
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.classdata=response.data.details;
        this.datanotfound=false;
        }else{
          this.classdata=[];
        } this.datanotfound=true;
            
      })
      // axios
      // .post(process.env.VUE_APP_API_ENDPOINT+'read_topper',{ "details" :{"class_oid":"61814236bb203f4610f37262"}},this.auth1)
      // .then(response => {
      //   console.log(response);
      //   if(response.data.details){
      //   this.topper12data=response.data.details;
      //   this.datanotfound=false;
      //   }else{
      //     this.topper12data=[];
      //   } this.datanotfound=true;
            
      // })
      
       
  },
  methods: {
      gettopperbyclass(){
        const querystring = require('querystring');
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'read_topper',{ "details" :{"class_oid":this.selected_class}},this.auth1)
            .then(response => {
              console.log(response);
              if(response.data.details){
              this.topperdata=response.data.details;
              this.datanotfound=false;
              }else{
                this.topperdata=[];
                this.datanotfound=true;
              }
            this.message=response.data.message;
            document.getElementById("viewtcbtn").classList.remove('btn-primary');
            document.getElementById("viewtcbtn").classList.add('btn-success');
            document.getElementById("viewtcbtn").innerHTML='Searched';
            setTimeout(function(){
            document.getElementById("viewtcbtn").classList.remove('btn-success');
            document.getElementById("viewtcbtn").classList.add('btn-primary');
            document.getElementById("viewtcbtn").innerHTML="Search";
          }, 2000);

          })
        
      },
    },
};
</script>
<style>
.key_features  .info-horizontal {
  max-width: 100% !important;
}
.table .thead-light th.is-right {
    text-align: right;
}
.cell .progress-wrapper {
    padding-top: 0rem;
}
#pricing-6 .tab-content.text-left {
    text-align: right !important;
}
</style>
