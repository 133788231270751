<template>
  <ul class="navbar-nav align-items-lg-center ml-lg-auto">
    <li class="nav-item">
      <a class="nav-link nav-link-icon" href="javascript:void(0)">
        <i class="fa fa-facebook-square"></i>
        <span class="nav-link-inner--text d-lg-none">Share</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link nav-link-icon" href="javascript:void(0)">
        <i class="fa fa-twitter"></i>
        <span class="nav-link-inner--text d-lg-none">Tweet</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link nav-link-icon" href="javascript:void(0)">
        <i class="fa fa-pinterest"></i>
        <span class="nav-link-inner--text d-lg-none">Pin</span>
      </a>
    </li>
  </ul>
</template>
<script>
export default {};
</script>
<style></style>
