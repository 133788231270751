<template>
  <div class="wrapper">
    <div class="main-slides h-70vh" style="background: url(img/key_features.jpg); background-size: cover;">
      <div class="page-header h-70vh">
        <div class="container">
          <div class="row align-items-center text-left text-light">
            <div class="col-lg-6 col-12" v-animate-onscroll="{down: 'animated fadeOutDown', up: 'animated fadeInDown' }">
              <h1 class="display-2 mt-8 text-light">
                Bring technology to<span class="text-light">
                everyday learning.</span
                >
              </h1>
              <!-- <p class="lead pb-4">
                Alpine Public School endeavours to impart an education that aims towards the all-round development of every child.
              </p> -->
              <!-- <div class="row row-input">
                <div class="col-sm-8 col-12">
                  <base-input placeholder="Your email"></base-input>
                </div>
                <div class="col-sm-4 col-12 pl-lg-0">
                  <base-button type="primary" class="btn-block">More</base-button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features-4">
      <div class="container">
        <div class="row">
          <div class="col-md-11 text-center mx-auto">
            <h3 class="display-3">SMART CLASSES</h3>
            <p class="lead font-weight-500 text-dark">
              In collaboration with Tata-edge Smart Class, we introduced Smart class technology in our school. Smart class is a comprehensive Solution designed to assist teachers in meeting day to day classroom challenges and enhancing student's academic performance with simple, practical and meaningful use of technology.
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-4 mr-auto text-left mt-4" v-animate-onscroll="'animated fadeInLeft'">
            <card class="card-blog bg-default" bodyClasses="p-0">
              <template slot="body">
                <img src="img/smartclass.png" class="card-img-top" />
                <blockquote class="card-blockquote">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="svg-bg"
                  >
                    <polygon
                      points="0,52 583,95 0,95"
                      class="fill-default"
                    ></polygon>
                    <polygon
                      points="0,42 583,95 683,0 0,95"
                      opacity=".2"
                      class="fill-default"
                    ></polygon>
                  </svg>
                  <h4 class="display-4 text-white">The future of learning.</h4>
                  <!-- <p class="lead text-italic text-white">
                    That’s my skill. I’m not really specifically talented at
                    anything except for the ability to learn.
                  </p> -->
                </blockquote>
              </template>
            </card>
          </div>
          <div class="col-lg-8">
            <div class="row">
              <div class="col-md-12" v-animate-onscroll="'animated zoomIn'">
                <p class="">
                  Smart Classes also enable teachers to instantly their students in class.Smart Class helps teachers to ensure that every child in the class is learning, given the wide diversity of learning styles in the classroom.
                </p>
                <p class="">
                  It is also highly efficient in maintaining student's interest and engagement in learning inside the classroom. Smart Class simplifies the problems of teaching abstract curriculum concepts that are difficult for students to visualize or relate to through the provision of three-dimensional, interactive multi-media modules. 
                </p>
                <p class="">Learning Hour tutors assign relevant Smart Class modules to students to view either before or after a tutoring session to prepare them or concretize the learning in the session. The well-designed modules allow a student to visualize the concepts much better than static images or oral instruction and hence improve learning greatly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section features features-5 bg-secondary border-top border-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <h3 class="display-3">Values</h3>
            <p class="">
              Smart class is powered by a vast repository of digital instruction materials exactly mapped to meet with the specific objectives laid out by different state learning standards. 
            </p>
            <p class="">The content repository consists of thousands of highly animated, lesson specific, 3D and 2D multimedia modules. </p>
            <p class="">Educational videos are also available for teachers to use in the classroom. By using ICT Smart Class Technology a teacher can teach a chosen lesson in class, frame by frame, with engaging and instructionally sound animated set of visuals.
            </p>
            <p class="font-weight-bold">
              Thus smart class is a digital initiative that—
            </p>
            <p>
              <ul>
                <li>Gives richer learning environment.</li>
                <li>Concepts are introduced in an exciting manner.</li>
                <li>Assessments come with instant result.</li>
                <li>Teacher is able to give lot more individual attention to each Leaner</li>
                <li>Improves teacher effectives and Productivity.</li>
                <li>Make learning an enjoyable experience for students.</li>
                <li>Improves academics performances of students.</li>
                <li>Enables instant formative assessment of learning outcomes in class.</li>
              </ul>
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-8 mt-md-5">
            <div class="row">
              <div class="col-md-8">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  bodyClass: "smartclasses",
  components: {
  }
};
</script>
<style>
.key_features  .info-horizontal {
  max-width: 100% !important;
}
</style>
